import { cleanObject } from "@/common/functions/cleanObject";
import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "lesson";

export const findAllLessonsByLanguages = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/languages/?${queryString.stringify(cleanObject(payload), {
      arrayFormat: "bracket",
    })}`
  );

  return { data: data.data };
};

export const findByLessonsCategory = async (payload) => {
  const data = await api.get(`/${PREFIX}/find-by-category/${payload._id}`);

  return { data: data.data };
};

export const updateLessons = async (payload) => {
  const data = await api.patch(`/${PREFIX}/many`, payload);
  return { data: data.data };
};

export const updateOneLesson = async (payload) => {
  const data = await api.patch(`/${PREFIX}/one`, payload);
  return { data: data.data };
};

export const createOneLesson = async (payload) => {
  const data = await api.post(`/${PREFIX}/one`, payload);
  return { data: data.data };
};

export const removeFlashCardFromLesson = async (payload) => {
  const data = await api.delete(`/${PREFIX}/flashcard/${payload.flashcard}`);
  return { data: data.data };
};

export const deleteLessons = async (payload) => {
  const data = await api.delete(`/${PREFIX}/`, payload);
  return { data: data.data };
};

export const deleteOneLesson = async (payload) => {
  const data = await api.delete(`/${PREFIX}/one/${payload._id}`);
  return { data: data.data };
};

export const findAllLessons = async () => {
  const data = await api.get(`/${PREFIX}/all`);
  return { data: data.data };
};

export const createLesson = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updateLesson = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const changeLessonPosition = async (payload) => {
  const data = await api.patch(`/${PREFIX}/change-number-of-lesssons`, payload);
  return { data: data.data };
};

export const deleteWordFromLesson = async (payload) => {
  const data = await api.patch(`/${PREFIX}/delete-word-from-lesson`, payload);
  return { data: data.data };
};

export const changeWordPositionInLesson = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/change-word-position-in-lesson`,
    payload
  );
  return { data: data.data };
};

export const deleteLesson = async (payload) => {
  const data = await api.delete(`/${PREFIX}/${payload._id}`);

  return { data: data.data };
};
