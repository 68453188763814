import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const Card = styled.div`
  width: 1000px;
  height: 700px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;

  width: 60px;
  height: 100vh;
  overflow: hidden;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;

  width: calc(100vw - 60px);
  height: 100vh;
  overflow: hidden;
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
`;

export const RestWrapper = styled.div`
  padding: 30px 30px 0px 30px;
  overflow-y: auto;
`;
