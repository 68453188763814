import { getTaskStats } from '@/api/query/integration'
import CustomComplexTable from '@/common/components/custom/CustomComplexTable';
import Loading from '@/common/components/Loading';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { generateTableData } from './helpers/generateTableData';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 auto;
  max-height: 90vh;
  overflow: scroll;
  width: 70%;
`;

const TaskStats = () => {
  const [headersArray, setHeadersArray] = useState([]);
  const [raws, setRaws] = useState([]);
  const getTaskStatsMutation = useMutation({
    mutationFn: () => getTaskStats(),
    onSuccess: ({ data }) => {
      
      const { headersData, rawsData } = generateTableData(data);

      setRaws(rawsData);
      setHeadersArray(headersData);
    }
  });


  useEffect(() => {
    getTaskStatsMutation.mutate();
  }, []);

  return (
   <Wrapper>
    {getTaskStatsMutation.isLoading && <Loading />}
    {(!!raws && !!headersArray && (<CustomComplexTable stickyHeader headersArray={headersArray} raws={raws} />))}
   </Wrapper>
  );
}

export default TaskStats
