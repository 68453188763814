import { updateWordLevel } from "@/api/query/word";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { WORD_LEVEL } from "@/common/constants/level.constants";
import { getToSelectData } from "@/common/functions/getToSelectData";
import { Title } from "@/pages/common/login/loginPage.styles";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin: 30px;
`;

const CustomButtonSubmitWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 20px 0 -20px 0;
`;

const EditLevel = ({ setClose, edit }) => {
  const [selectedLevel, setSelectedLevel] = useState(
    getToSelectData(WORD_LEVEL).find((a) => a.value === edit.level)
  );
  const texareaRef = useRef();
  const { id } = useParams();

  const updateWordLeveMutation = useMutation({
    mutationFn: (value) => updateWordLevel(value),
    onSuccess: () => {
      setClose(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = {};

    payload.level = selectedLevel.value;
    payload.wordId = id;

    updateWordLeveMutation.mutate(payload);
  };

  return (
    <>
      {updateWordLeveMutation.isLoading && <Loading />}
      <PopUp setClose={setClose}>
        <Wrapper onSubmit={handleSave}>
          <Title>Level Edit</Title>
          <SelectInput
            options={getToSelectData(WORD_LEVEL)}
            selected={selectedLevel}
            setSelected={setSelectedLevel}
            showLabel={false}
          />
          <CustomButtonSubmitWrapper>
            <CustomSaveButton />
          </CustomButtonSubmitWrapper>
        </Wrapper>
      </PopUp>
    </>
  );
};

export default EditLevel;
