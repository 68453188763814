import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { Title } from "@/pages/common/login/loginPage.styles";
import React, { useState } from "react";
import styled from "styled-components";

const OPTIONS = [
  {
    label: "Correct",
    value: "correct",
  },
  {
    label: "Question",
    value: "question",
  },
  {
    label: "Wrong",
    value: "wrong",
  },
];

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

const MarkEntityAsUserDecision = ({ setShow, data, handleSaveDecistion }) => {
  const [selectedStatus, setSelectedStatus] = useState(
    OPTIONS.find((o) => o.value === data.verificationStatus)
  );

  const handleSave = () => {
    if (!selectedStatus) return;

    handleSaveDecistion(selectedStatus.value);
  };

  return (
    <PopUp setClose={setShow}>
      <Title>Pick Translation status</Title>
      <SelectInput
        selectWidth={400}
        showLabel={false}
        options={OPTIONS}
        selected={selectedStatus}
        setSelected={setSelectedStatus}
      />
      <ButtonWrapper>
        <CustomEditButton text="Save" onClick={handleSave} />
      </ButtonWrapper>
    </PopUp>
  );
};

export default MarkEntityAsUserDecision;
