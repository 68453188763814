import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";
import { findAllLanguages } from "@/api/query/language";
import Loading from "@/common/components/Loading";
import { searchStatsDataForLanguagesScore } from "@/api/query/stats";
import CustomTable from "@/common/components/custom/CustomTable";
import SearchBar from "./SearchBar";
import CustomBar from "@/common/charts/Bar";
import { Color } from "@/common/colors/colors";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  overflow-y: auto;
  height: calc(100vh - 80px);
`;

const Title = styled.h3`
  text-align: left;
  width: 100%;
`;

const Reliability = () => {
  const [options, setOptions] = useState([]);
  const [selectedCoreOptions, setSelectedCoreOption] = useState();
  const [selectedTranslationOptions, setSelectedTranslationOptions] =
    useState();
  const [tableData, setTableData] = useState();
  const [tableData2, setTableData2] = useState();

  const findLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setOptions(() => data.map((d) => ({ label: d.name, value: d._id })));
    },
  });

  const handleData = (data) => {
    return {
      labels: data.sentecneResult.map((d) => d.name),
      datasets: [
        {
          label: "Sentence",
          data: data.sentecneResult.map((d) => d.value),
          backgroundColor: Color.red,
        },
        {
          label: "Word",
          data: data.wordsResult.map((d) => d.value),
          backgroundColor: Color.green,
        },
      ],
    };
  };

  const handleData2 = (data) => {
    return {
      labels: data.minPair.map((d) => d.name),
      datasets: [
        {
          label: "Lang Pair Min Score",
          data: data.minPair.map((d) => d.value),
          backgroundColor: Color.red,
        },
      ],
    };
  };

  const searchStatsDataMutation = useMutation({
    mutationFn: (payload) => searchStatsDataForLanguagesScore(payload),
    onSuccess: ({ data }) => {
      setTableData(handleData(data));
      setTableData2(handleData2(data));
    },
  });

  const handleSearch = () => {
    if (!selectedCoreOptions || !selectedTranslationOptions) {
      return;
    }

    const payload = {};
    payload.langFrom = selectedCoreOptions.value;
    payload.langTo = selectedTranslationOptions.value;

    searchStatsDataMutation.mutate(payload);
  };

  useEffect(() => {
    findLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {(findLanguagesMutation.isLoading ||
        searchStatsDataMutation.isLoading) && <Loading />}
      <SearchBar
        selectedCoreOptions={selectedCoreOptions}
        setSelectedCoreOption={setSelectedCoreOption}
        options={options}
        setSelectedTranslationOptions={setSelectedTranslationOptions}
        selectedTranslationOptions={selectedTranslationOptions}
        handleSearch={handleSearch}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minWidth: "600px",
        }}
      >
        {tableData && <CustomBar data={tableData} />}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          minWidth: "600px",
        }}
      >
        {tableData2 && <CustomBar data={tableData2} />}
      </div>
    </>
  );
};

export default Reliability;
