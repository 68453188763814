import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { faAdd, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findAllLanguages } from "@/api/query/language";
import AdminLanguageEdit from "./components/AdminLanguageEdit";
import moment from "moment";
import Loading from "@/common/components/Loading";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HEADERS = ["No.", "Name", "Short name", "Created At", "Actions"];

const AdminLanguagePage = () => {
  const [editLanguage, setEditLanguage] = useState();
  const [tableData, setTableData] = useState();

  const findUsersMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data?.map((d, i) => [
          i + 1,
          d.name,
          d.shortName,
          moment(d.created_at).format("DD/MM/YYYY HH:mm"),
          <div style={{ display: "flex", gap: "10px" }}>
            <CustomEditButton
              onClick={(e) => setEditLanguage(d)}
              text={
                <FontAwesomeIcon icon={faEdit} style={{ color: "white" }} />
              }
            />
          </div>,
        ])
      );
    },
  });

  useEffect(() => {
    if (!editLanguage) {
      findUsersMutation.mutate();
    }
  }, [editLanguage]);

  return (
    <>
      {findUsersMutation.isLoading && <Loading />}
      <Wrapper>
        {editLanguage && (
          <AdminLanguageEdit edit={editLanguage} setEdit={setEditLanguage} />
        )}
        <TopContent>
          <CustomAddButton
            onClick={() => setEditLanguage({})}
            text={<FontAwesomeIcon icon={faAdd} style={{ color: "white" }} />}
          />
        </TopContent>
        <CustomTable headers={HEADERS} data={tableData} />
      </Wrapper>
    </>
  );
};

export default AdminLanguagePage;
