import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Reliability from "./components/realibility/Reliability";
import Translators from "./components/translators/Translators";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { Color } from "@/common/colors/colors";
import { useSearchParams } from "react-router-dom";
import LangPair from "./components/langPair/LangPair";
import LangLessonList from "./components/langLessonList/LangLessonList";
import TaskStats from "./components/taskStats/TaskStats";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin: 0 -20px;
  overflow-y: auto;
  height: calc(100vh - 80px);
`;

const StatsReportsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  gap: 20px;
`;

const OPTIONS = [
  {
    label: "Reliability",
    value: "reliability",
    component: <Reliability />,
  },
  {
    label: "Translators",
    value: "translators",
    component: <Translators />,
  },
  {
    label: "Lang Pair",
    value: "lang-pair",
    component: <LangPair />,
  },
  {
    label: "Lang lesson list",
    value: "lang-lesson-list",
    component: <LangLessonList />,
  },
  {
    label: 'Task stats',
    value: "task-stats",
    component: <TaskStats />,
  }
];

const AdminStatsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedComponent, setSelectedComponent] = useState();

  const handleChangeView = (view) => {
    setSelectedComponent(view);
    setSearchParams({ ["show"]: view.value });
  };

  useEffect(() => {
    if (!selectedComponent) {
      const statsShow = searchParams.get("show");
      const view = OPTIONS.find((o) => o.value === statsShow) || OPTIONS[0];

      setSelectedComponent(view);
    }
  }, [searchParams]);

  return (
    <Wrapper>
      {selectedComponent && (
        <StatsReportsWrapper>
          {OPTIONS.map((o) => (
            <CustomEditButton
              key={o.value}
              text={o.label}
              style={{
                backgroundColor:
                  selectedComponent?.value !== o?.value
                    ? Color.Grey
                    : "#212f46",
              }}
              onClick={() => handleChangeView(o)}
            />
          ))}
        </StatsReportsWrapper>
      )}
      {selectedComponent?.component}
    </Wrapper>
  );
};

export default AdminStatsPage;
