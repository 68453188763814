import React, { useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import api from "@/api/api";
import { useMutation } from "react-query";
import { login } from "@/api/query/auth";
import userManager from "@/api/userManager";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Icon,
  Input,
  InputField,
  LoginForm,
  ShowPasswordButton,
  SubmitButton,
  Title,
} from "./loginPage.styles";
import Loading from "@/common/components/Loading";

const { tokenManager } = api;

const Login = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const loginMutation = useMutation({
    mutationFn: (value) => login(value),
    onSuccess: ({ data }) => {
      const { token, refreshToken } = data;
      tokenManager.setToken(token);
      tokenManager.setRefreshToken(refreshToken);
      const payload = tokenManager.parseJwt(token);
      userManager.setUser(payload);
      const user = userManager.getUser();
      if (user.role === "Admin") {
        return navigate("/admin/home");
      }
      return navigate("/home");
    },
    onError: ({ data }) => {
      //   setError(data);
    },
  });

  const handleLogin = (e) => {
    e && e.preventDefault();
    const payload = {};
    payload.email = emailRef.current.value;
    payload.password = passwordRef.current.value;
    loginMutation.mutate(payload);
  };

  return (
    <>
      {loginMutation.isLoading && <Loading />}
      <Container>
        <LoginForm onSubmit={handleLogin}>
          <Title>Logowanie</Title>
          <InputField>
            <Icon>
              <FontAwesomeIcon icon={faEnvelope} style={{ color: "red" }} />
            </Icon>
            <Input ref={emailRef} type="email" placeholder="Email" required />
          </InputField>
          <InputField>
            <Icon>
              <FontAwesomeIcon icon={faLock} style={{ color: "red" }} />
            </Icon>
            <Input
              ref={passwordRef}
              type={showPassword ? "text" : "password"}
              placeholder="Hasło"
              required
            />
            <ShowPasswordButton type="button" onClick={toggleShowPassword}>
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </ShowPasswordButton>
          </InputField>
          <SubmitButton type="submit">Login</SubmitButton>
        </LoginForm>
      </Container>
    </>
  );
};

export default Login;
