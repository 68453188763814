import React from "react";
import styled from "styled-components";

const DeleteButton = styled.button`
  background-color: #f44336; /* Kolor tła przycisku Delete */
  color: white; /* Kolor tekstu */
  padding: 10px 20px; /* Wielkość przycisku */
  border: none;
  border-radius: 5px; /* Zaokrąglenie krawędzi */
  cursor: pointer;
`;

const CustomDeleteButton = ({ onClick, text = "Delete" }) => {
  return (
    <DeleteButton type="button" onClick={onClick}>
      {text}
    </DeleteButton>
  );
};

export default CustomDeleteButton;
