import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";
import { findChangeLog } from "@/api/query/changelog";
import { Color } from "../colors/colors";
import { CommonContext } from "../contexts/common.context";

const DateContainer = styled.div`
  min-width: 70px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
  color: ${Color.darkBlue};
`;

const Point = styled.div`
  width: 10px;
  height: 10px;
  position: relative;
  border: 1px solid red;
  top: 5px;
  left: 73px;
  border-radius: 50%;
`;

const Title = styled.h4`
  margin-bottom: 10px;
  color: ${Color.darkBlue};
`;
const ChangeWrapper = styled.div`
  font-style: italic;
  display: flex;
  gap: 5px;
`;

const ChangeContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const WrapperChanges = styled.div`
  font-size: 14px;
  text-align: left;
  padding: 10px 20px 10px 10px;
`;

const dumb = (change, currentKey = "", current = {}) => {
  if (Array.isArray(change)) {
    change.map((value, i) => {
      dumb(value, currentKey + "." + i, current);
    });

    return current;
  }

  if (typeof change === "object") {
    if (!change) {
      current[currentKey] = change;

      return current;
    }

    Object.keys(change).map((changeKey) => {
      dumb(change[changeKey], currentKey + "." + changeKey, current);
    });

    return current;
  }

  current[currentKey] = change;

  return current;
};

const getDumyData = (change) => {
  if (!change) {
    return [];
  }

  return Object.entries(change).map(([key, value]) => {
    return [dumb(value[0], key), dumb(value[1], key)];
  });
};

const ChangeLog = ({ entity, entityId, minWidth = 500, maxHeight = 500 }) => {
  const [changes, setChanges] = useState([]);
  const { users } = useContext(CommonContext);

  const getProperName = (key, value) => {
    if ([null, undefined].includes(value)) {
      return value;
    }

    if (
      key.toLowerCase().slice(key.length - 2) === "at" ||
      key.toLowerCase().slice(key.length - 4) === "date"
    ) {
      return (
        <p className="animation-scale" style={{ cursor: "pointer" }}>
          {moment(value).format("YYYY-MM-DD HH:mm")}
        </p>
      );
    }

    if (key.toLowerCase().slice(key.length - 2) === "by") {
      return (
        <p className="animation-scale" style={{ cursor: "pointer" }}>
          {users.find((user) => user._id === value)?.email}
        </p>
      );
    }

    // if (key.split(".").slice(-1).pop() === "product") {
    //   return (
    //     <p
    //       className="animation-scale"
    //       style={{ cursor: "pointer" }}
    //       onClick={() => {
    //         window.open(`/settings/product/show/${value}`, "_blank").focus();
    //       }}
    //     >
    //       {products.find((p) => p._id === value)?.name}
    //     </p>
    //   );
    // }

    if (["is", "to"].includes(key.toLowerCase().slice(key.length - 2))) {
      return value.toString();
    }

    // if (key.match("market")) {
    //   return (
    //     <p
    //       className="animation-scale"
    //       style={{ cursor: "pointer" }}
    //       onClick={() => {
    //         window.open(`/settings/market/show/${value}`, "_blank").focus();
    //       }}
    //     >
    //       {markets.find((market) => market._id === value)?.name}
    //     </p>
    //   );
    // }

    // if (key.split("_").slice(-1).pop() === "queue") {
    //   return (
    //     <p
    //       className="animation-scale"
    //       style={{ cursor: "pointer" }}
    //       onClick={() => {
    //         window.open(`/settings/queue/show/${value}`, "_blank").focus();
    //       }}
    //     >
    //       {queues.find((queue) => queue._id === value)?.name}
    //     </p>
    //   );
    // }

    return value;
  };

  const findChangeLogMutation = useMutation({
    mutationFn: (value) => findChangeLog(value),
    onSuccess: ({ data }) => {
      console.log(data);
      setChanges(data);
    },
  });

  useEffect(() => {
    const payload = {};
    payload.entity = entity;
    payload.entityId = entityId;

    findChangeLogMutation.mutate(payload);
  }, []);

  return (
    <>
      {
        <div
          style={{
            minWidth: `${minWidth}px`,
            maxHeight: `${minWidth}px`,
            overflowY: "auto",
          }}
        >
          <Title>
            <u>Change log:</u>
          </Title>
          {changes.map((chnage, index) => {
            const currentChangesToDisplay = getDumyData(chnage.changeset);
            return (
              <div key={chnage.created_at} style={{ display: "flex" }}>
                <DateContainer>
                  <Point />
                  {moment(chnage.created_at).format("HH:mm:ss")}
                </DateContainer>
                <div
                  style={{
                    borderLeft: "1px solid black",
                    fontSize: "14px",
                    paddingLeft: "10px",
                  }}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <p style={{ color: Color.darkBlue, margin: "0" }}>
                      {" "}
                      {moment(chnage.created_at).format("YYYY-MM-DD")}
                    </p>{" "}
                    by{" "}
                    <b
                      className="animation-scale"
                      style={{ color: Color.red, cursor: "pointer" }}
                    >
                      {users?.find((e) => e._id === chnage.user_id)?.email ||
                        "System"}
                    </b>
                  </div>
                  {chnage.log && <div>{chnage.log}</div>}
                  <WrapperChanges>
                    {currentChangesToDisplay?.map((change, i) => {
                      return (
                        <div key={i}>
                          {[
                            ...new Set([
                              ...Object.keys(change[0]),
                              ...Object.keys(change[1]),
                            ]),
                          ]
                            .filter((d) => d !== "shipping")
                            .map((key) => (
                              <ChangeContainer key={key}>
                                <b>{key}: </b>
                                <ChangeWrapper>
                                  {getProperName(key, change[0][key]) || "null"}{" "}
                                  {"=>"}{" "}
                                  {getProperName(key, change[1][key]) || "null"}
                                </ChangeWrapper>
                              </ChangeContainer>
                            ))}
                        </div>
                      );
                    })}
                  </WrapperChanges>
                </div>
              </div>
            );
          })}
        </div>
      }
    </>
  );
};

export default ChangeLog;
