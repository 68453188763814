import { findAllLanguages } from "@/api/query/language";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import SearchBar from "./components/SearchBar";
import styled from "styled-components";
import { findLanguagesPairTableView } from "@/api/query/word";
import DataTable from "./components/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Color } from "@/common/colors/colors";
import { Link } from "react-router-dom";
import Loading from "@/common/components/Loading";
import { getStatusColor } from "./helpers/getStatusColor";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 -30px;
  gap: 30px;
  overflow-y: auto;
  height: calc(100vh - 80px);
`;

export const TableWrapper = styled.div`
  max-width: 1000px;
  overflow-x: auto;

  @media (min-width: 1200px) {
    max-width: 1100px;
  }

  @media (min-width: 1400px) {
    max-width: 1300px;
  }

  @media (min-width: 1600px) {
    max-width: 1500px;
  }

  @media (min-width: 1800px) {
    max-width: 1700px;
  }
`;

const ColorWrapper = styled.span`
  color: ${(props) => props.color};
`;

const IMAGE_OPTIONS = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "With Image",
    value: "with",
  },
  {
    label: "Without Image",
    value: "without",
  },
];

const HEADERS = [
  {
    header: "No.",
    accessorKey: "no",
    size: 50,
  },
  {
    header: "Word Type",
    accessorKey: "wordType",
    size: 90,
  },
  {
    header: "Word from",
    accessorKey: "wordFrom",
    size: 120,
  },
  {
    header: "Edit",
    accessorKey: "wordFromEdit",
    size: 40,
    cell: (info) => (
      <Link
        to={`/admin/word/${info.getValue()?._id}`}
        style={{ color: Color.DarkBlue, textDecoration: "none" }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Link>
    ),
  },
  {
    header: "Word to",
    accessorKey: "wordTo",
    size: 120,
  },
  {
    header: "Edit",
    accessorKey: "wordToEdit",
    size: 40,
    cell: (info) => (
      <Link
        to={`/admin/word/${info.getValue()?._id}`}
        style={{ color: Color.DarkBlue, textDecoration: "none" }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </Link>
    ),
  },
  {
    header: "Word Score",
    accessorKey: "reliabilityWord",
    size: 120,
  },
  {
    header: "Status",
    accessorKey: "word_status",
    size: 20,
    cell: (info) => (
      <ColorWrapper color={getStatusColor(info.getValue())}>
        {info.getValue()}
      </ColorWrapper>
    ),
  },
  {
    header: "Sentence From",
    accessorKey: "sentenceFrom",
    size: 300,
  },
  {
    header: "Sentence to",
    accessorKey: "sentenceTo",
    size: 300,
  },
  {
    header: "Sentence Score",
    accessorKey: "reliabilitySentence",
    size: 120,
  },
  {
    header: "Status",
    accessorKey: "sentence_status",
    size: 20,
    cell: (info) => (
      <ColorWrapper color={getStatusColor(info.getValue())}>
        {info.getValue()}
      </ColorWrapper>
    ),
  },
  {
    header: "Errors",
    accessorKey: "errors",
    size: 200,
  },
  {
    header: "Min Score",
    accessorKey: "min_score",
    size: 80,
  },
  {
    header: "Note",
    accessorKey: "note",
    size: 80,
  },
];

const LanguagesPairListPage = () => {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);

  const [selectedCoreOptions, setSelectedCoreOption] = useState();
  const [selectedTranslationOptions, setSelectedTranslationOptions] =
    useState();
  const [selectedImageOptions, setSelectedImageOptions] = useState(
    IMAGE_OPTIONS[0]
  );

  const findLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setOptions(() => data.map((d) => ({ label: d.name, value: d._id })));
    },
  });

  const findLanguagesPairTableViewMutation = useMutation({
    mutationFn: (value) => findLanguagesPairTableView(value),
    onSuccess: ({ data }) => {
      setData(() =>
        data.map((d, i) => ({
          no: i + 1 + ".",
          wordType: d.wordTranslation?.fromWord?.type || "--------",
          wordFrom: d.wordTranslation?.fromWord?.content || "--------",
          wordTo: d.wordTranslation?.toWord?.content || "--------",
          wordFromEdit: d.wordTranslation?.fromWord || "--------",
          wordToEdit: d.wordTranslation?.toWord || "--------",
          word_status: d.wordTranslation?.wordTranslationVeryfication?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )[0]?.verificationStatus,
          sentence_status:
            d.sentenceTranslation?.sentenceTranslationVeryfication?.sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )[0]?.verificationStatus,
          reliabilityWord:
            (d.wordTranslation?.reliability &&
              Math.round(d.wordTranslation?.reliability * 100) / 100) ||
            "--------",
          sentenceFrom:
            d.sentenceTranslation?.fromSentence?.content || "--------",
          sentenceTo: d.sentenceTranslation?.toSentence?.content || "--------",
          reliabilitySentence:
            (d.sentenceTranslation?.reliability &&
              Math.round(d.sentenceTranslation?.reliability * 100) / 100) ||
            "--------",
          errors: d.translationErrors?.join(", ") || "------",
          last_user_status:
            d.veryfication?.sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )[0]?.verificationStatus || "------",
          min_score: Math.min(
            Math.round(d.sentenceTranslation?.reliability * 100) / 100,
            Math.round(d.wordTranslation?.reliability * 100) / 100
          ),
          note: d.wordTranslation?.fromWord?.note || "-----",
        }))
      );
    },
  });

  const handleSearch = () => {
    if (!selectedCoreOptions || !selectedTranslationOptions) {
      return;
    }

    const payload = {};
    payload.langFrom = selectedCoreOptions.value;
    payload.langTo = selectedTranslationOptions.value;
    payload.image = selectedImageOptions.value;

    findLanguagesPairTableViewMutation.mutate(payload);
  };

  useEffect(() => {
    findLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {(findLanguagesMutation.isLoading ||
        findLanguagesPairTableViewMutation.isLoading) && <Loading />}
      <Wrapper>
        <SearchBar
          selectedCoreOptions={selectedCoreOptions}
          setSelectedCoreOption={setSelectedCoreOption}
          options={options}
          setSelectedTranslationOptions={setSelectedTranslationOptions}
          selectedTranslationOptions={selectedTranslationOptions}
          handleSearch={handleSearch}
          imageOptions={IMAGE_OPTIONS}
          selectedImageOptions={selectedImageOptions}
          setSelectedImageOptions={setSelectedImageOptions}
        />
        <TableWrapper>
          <DataTable
            data={data}
            columns={HEADERS}
            rows={[15, 20, 100, 200, 300, 500]}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default LanguagesPairListPage;
