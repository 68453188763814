import {
  addWordToLesson,
  handleLoadAvaiableWordsForLesson,
} from "@/api/query/word";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import CustomTable from "@/common/components/custom/CustomTable";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import { Title } from "@/pages/common/login/loginPage.styles";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";

const TableWrapper = styled.div`
  height: 600px;
  width: 500px;
  overflow-y: scroll;
`;
const AdminAddWord = ({ setClose, edit }) => {
  const [avaiableWords, setAvaiableWords] = useState([]);

  const handleLoadAvaiableWordsForLessonMutation = useMutation({
    mutationFn: (value) => handleLoadAvaiableWordsForLesson(value),
    onSuccess: ({ data }) => {
      setAvaiableWords(data);
    },
  });

  const handleAddWordToLessonMutation = useMutation({
    mutationFn: (value) => addWordToLesson(value),
    onSuccess: ({ data }) => {
      setClose(null);
    },
  });

  const handleAddWordToLesson = (word) => {
    const payload = {};
    payload.wordId = word._id;
    payload.lessonId = edit.lesson._id;

    handleAddWordToLessonMutation.mutate(payload);
  };

  useEffect(() => {
    handleLoadAvaiableWordsForLessonMutation.mutate({ _id: edit.lesson._id });
  }, []);

  return (
    <>
      {handleLoadAvaiableWordsForLessonMutation.isLoading && <Loading />}
      <PopUp setClose={setClose}>
        <Title>Add word to lesson:</Title>
        <TableWrapper>
          <CustomTable
            headers={["No.", "Action", "Word", "Type"]}
            data={avaiableWords.map((w, i) => [
              `${i + 1}.`,
              <CustomAddButton
                text={<FontAwesomeIcon icon={faAdd} />}
                onClick={() => handleAddWordToLesson(w)}
                style={{ padding: "5px 15px" }}
              />,
              w.content,
              w.type,
            ])}
          />
        </TableWrapper>
      </PopUp>
    </>
  );
};

export default AdminAddWord;
