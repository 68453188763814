import PopUp from "@/common/components/PopUp";
import React, { useMemo, useRef, useState } from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import Input from "@/common/components/custom/Input";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import SelectInput from "@/common/components/custom/SelectInput";
import { createTranslator, updateTranslator } from "@/api/query/translator";
import MessageQueue, { useMessageQueue } from "@/common/message";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

const TranslatorForm = ({ setEdit, edit, languagesOptions, reloadFn }) => {
  const setLangs = () =>
    edit.languages_available?.map((lang) =>
      languagesOptions.find((option) => option.value._id === lang)
    ) || [];

  const [selectedLanguages, setSelectedLanguages] = useState(setLangs);
  console.log("update");
  const nameRef = useRef();
  const sourceRef = useRef();

  const { addMessage, removeMessage, messages } = useMessageQueue();

  const createTranslatorMutation = useMutation({
    mutationFn: (value) => createTranslator(value),
    onSuccess: () => {
      setEdit(null);
      reloadFn.mutate();
    },
    onError: () => addMessage("Something went wrong", "error"),
  });

  const updateTranslatorMutation = useMutation({
    mutationFn: (value) => updateTranslator(edit._id, value),
    onSuccess: () => {
      setEdit(null);
      reloadFn.mutate();
    },
    onError: () => addMessage("Something went wrong", "error"),
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    if (!selectedLanguages) {
      return addMessage("Select languages", "error");
    }

    const payload = edit;

    payload.name = nameRef.current.value;
    payload.source = sourceRef.current.value;
    payload.languages_available = selectedLanguages.map(
      (lang) => lang.value._id
    );

    return edit._id
      ? updateTranslatorMutation.mutate(payload)
      : createTranslatorMutation.mutate(payload);
  };

  return (
    <PopUp setClose={setEdit}>
      <MessageQueue messages={messages} removeMessage={removeMessage} />
      {(createTranslatorMutation.isLoading ||
        updateTranslatorMutation.isLoading) && <Loading />}
      <Title>{edit._id ? "Edit" : "Create"}</Title>
      <Wrapper onSubmit={(e) => handleSave(e)}>
        <Input
          inputRef={nameRef}
          name="Name"
          width={100}
          required
          value={edit.name}
        />
        <Input
          inputRef={sourceRef}
          name="Source"
          width={100}
          required
          value={edit.source}
        />
        <SelectInput
          name="Languages"
          width={100}
          required
          selectWidth={290}
          multiple
          options={languagesOptions}
          setSelected={setSelectedLanguages}
          selected={selectedLanguages}
        />
        <ButtonWrapper>
          <CustomSaveButton />
        </ButtonWrapper>
      </Wrapper>
    </PopUp>
  );
};

export default TranslatorForm;
