import { faAdd, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const TableWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: #f0f0f0;
`;

const TableBody = styled.tbody`
  max-height: 300px;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  padding: 8px;
  text-align: left;
`;

const TableHeadCell = styled.th`
  padding: 8px;
  background-color: lightgrey;
  text-align: left;
`;

const HelpTable = ({
  headers = [],
  data = [],
  setEditTranslation,
  languages,
}) => {
  return (
    <TableWrapper>
      <Table>
        <TableHeader>
          <TableRow>
            {headers.map((header, index) => (
              <TableHeadCell key={index}>{header}</TableHeadCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell>{rowIndex + 1}.</TableCell>
              <TableCell>{row.text}</TableCell>
              <TableCell>
                {languages?.find((l) => l.value._id === row.language)?.label}
              </TableCell>
              <TableCell>
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => setEditTranslation(row)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default HelpTable;
