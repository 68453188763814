import React from "react";
import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.margin};
  gap: ${(props) => props.gap};

  label {
    width: fit-content;
  }

  input {
    width: fit-content;
    padding: 5px;
    outline: none;
    border-radius: 10px;
    padding: 10px 15px;
    border: 1px solid lightgray;
  }
`;

const Input = ({
  inputRef,
  value,
  name = true,
  required,
  disabled = false,
  type = "text",
  width = 300,
  height = 0,
  onChange,
  inputWidth = 260,
  inputName,
  fontSize = "18px",
  min = -100,
  max = Infinity,
  step,
  checked,
  isChecked,
  requiredSign,
  placeholder = "type...",
  paddingLeft = "10px",
  showLabel = true,
  color = "black",
  controlled,
  gap = "30px",
  id,
  link,
  onKeyPress = () => {},
  margin = "10px",
  style = {},
  accept = null,
}) => {
  return (
    <Flex gap={gap} margin={margin}>
      {showLabel && (
        <label
          className={`${link && "animation-scale"}`}
          htmlFor={id}
          style={{
            width: `${width}px`,
            textAlign: "right",
            fontSize: fontSize,
            cursor: `${(type === "checkbox" && id) || link ? "pointer" : null}`,
            ...style,
          }}
          onClick={() => {
            if (link) {
              window.open(link, "_blank");
            }
          }}
        >
          <strong style={{ color: color }}>
            {name}:{" "}
            {requiredSign ? <span style={{ color: "red" }}>*</span> : ""}
          </strong>
        </label>
      )}
      <input
        accept={accept}
        name={inputName || id}
        min={min}
        max={max}
        id={id}
        type={type}
        ref={inputRef}
        required={required}
        autoComplete="new-password"
        disabled={disabled}
        {...(!controlled && { defaultValue: value })}
        {...(controlled && { value: value || "" })}
        onChange={onChange}
        placeholder={placeholder}
        step="any"
        defaultChecked={checked}
        checked={isChecked}
        style={{
          paddingLeft: "10px",
          width: `${inputWidth}px`,
          height: `${height ? height : null}px`,
        }}
        onKeyPress={onKeyPress}
      />
    </Flex>
  );
};

export default Input;
