import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import React, { useEffect, useState } from "react";
import FirstTranslationScript from "./components/firstTranslationScript/FirstTranslationScript";
import TextToSpeechScript from "./components/textToSpeechScript/TextToSpeechScript";
import { ScriptsStartButtonsWrapper, Title } from "./AdminCommandPage.styled";
import { useMutation } from "react-query";
import { stopJobById, getAllSortedJobProcesses } from "@/api/query/jobProcess";
import CustomTable from "@/common/components/custom/CustomTable";
import { HEADERS } from "./constants/headers";
import { getRaws } from "./helpers/getRaws";
import { formatDate } from "@/common/functions/formatDate";
import Loading from "@/common/components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import TranslationScriptNew from "./components/translationScriptNew/TranslationScriptNew";
import ExportDatabaseToCSV from "./components/exportDatabaseToCSV/ExportDatabaseToCSV";
import ShowAlgo from "./components/showAlgo/ShowAlgo";
import CheckWordSentence from "./components/checkWordSentence/CheckWordSentence";
import ExportTaskCSV from "./components/exportTaskCSV/ExportTaskCSV";
import ExportAditionalCSV from "./components/exportAditionalCSV/ExportAditionalCSV";

const AdminCommandsPage = () => {
  const [showFirstTranslationScript, setShowFirstTranslationScript] =
    useState();
  const [showTextToSpeechScript, setShowTextToSpeechScript] = useState();
  const [showTranslationScript, setShowTranslationScript] = useState();
  const [showExportDBScript, setShowExportDBScript] = useState();
  const [showAlgoStart, setShowAlgoStart] = useState();
  const [checkWordInSentence, setCheckWordInSentence] = useState();
  const [exportTaskCsv, setExportTaskCsv] = useState();
  const [exportAditionalCSV, setExportAditionalCSV] = useState();

  const [jobProcesses, setJobProcesses] = useState();
  const [lastUpdate, setLastUpdate] = useState();

  const loadJobProcesses = useMutation({
    mutationFn: () => getAllSortedJobProcesses(),
    onSuccess: ({ data }) => {
      setJobProcesses(() => data);
      setLastUpdate(new Date());
    },
  });

  const removeProcess = useMutation({
    mutationFn: (jobId) => stopJobById(jobId),
    onSuccess: () => {
      loadJobProcesses.mutate();
    },
  });

  useEffect(() => {
    loadJobProcesses.mutate();
  }, []);

  return (
    <>
      {(loadJobProcesses.isLoading || removeProcess.isLoading) && <Loading />}
      {showFirstTranslationScript && (
        <FirstTranslationScript setClose={setShowFirstTranslationScript} />
      )}
      {showTextToSpeechScript && (
        <TextToSpeechScript
          reloadMutation={loadJobProcesses}
          setClose={setShowTextToSpeechScript}
        />
      )}
      {showTranslationScript && (
        <TranslationScriptNew
          reloadMutation={loadJobProcesses}
          setClose={setShowTranslationScript}
        />
      )}
      {showExportDBScript && (
        <ExportDatabaseToCSV
          reloadMutation={loadJobProcesses}
          setClose={setShowExportDBScript}
        />
      )}
      {showAlgoStart && (
        <ShowAlgo
          reloadMutation={loadJobProcesses}
          setClose={setShowAlgoStart}
        />
      )}
      {checkWordInSentence && (
        <CheckWordSentence
          reloadMutation={loadJobProcesses}
          setClose={setCheckWordInSentence}
        />
      )}
      {exportTaskCsv && (
        <ExportTaskCSV
          reloadMutation={loadJobProcesses}
          setClose={setExportTaskCsv}
        />
      )}
      {exportAditionalCSV && (
        <ExportAditionalCSV
          reloadMutation={loadJobProcesses}
          setClose={setExportAditionalCSV}
        />
      )}
      <ScriptsStartButtonsWrapper>
        <CustomEditButton
          disabled
          text="RUN TRANSLATION SCRIPT FIRST"
          onClick={setShowTranslationScript}
        />
        <CustomEditButton
          text="RUN TEXT TO SPEECH SCRIPT"
          onClick={setShowTextToSpeechScript}
        />
        <CustomEditButton
          text="RUN TANSLATION SCRIPT"
          onClick={setShowTranslationScript}
        />
        <CustomEditButton
          text="EXPORT WORD LESSONS"
          onClick={setShowExportDBScript}
        />
        <CustomEditButton
          text="RUN ALGO DATABASE GENERATOR"
          onClick={setShowAlgoStart}
        />
        <CustomEditButton
          text="CHECK WORD SENTENCE"
          onClick={setCheckWordInSentence}
        />
        <CustomEditButton
          text="EXPORT TASK LESSONS"
          onClick={setExportTaskCsv}
        />
        <CustomEditButton
          text="EXPORT ADITIONAL PAGE"
          onClick={setExportAditionalCSV}
        />
      </ScriptsStartButtonsWrapper>
      <Title>
        Job processes - {formatDate(lastUpdate)}{" "}
        <FontAwesomeIcon
          onClick={() => loadJobProcesses.mutate()}
          cursor="pointer"
          icon={faArrowsRotate}
        />
      </Title>
      <CustomTable
        headers={HEADERS}
        data={getRaws(jobProcesses, removeProcess)}
      />
    </>
  );
};

export default AdminCommandsPage;
