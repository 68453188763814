import React from "react";
import { styled } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(11, 47, 69, 0.6);
  z-index: 10;
`;

const Box = styled.div`
  min-width: 320px;
  position: fixed;
`;

const Card = styled.div`
  border-radius: 10px;
  border: 1px solid grey;
  background: white;
  padding: 10px;
  max-height: 90vh;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const PopUp = ({ setClose, children }) => {
  return (
    <Wrapper>
      <div
        onClick={() => setClose((prev) => !prev)}
        style={{ width: "100%", height: "100%" }}
      ></div>
      <Box>
        <Card>
          <CloseIcon icon={faClose} onClick={() => setClose((prev) => !prev)} />
          {children}
        </Card>
      </Box>
    </Wrapper>
  );
};

export default PopUp;
