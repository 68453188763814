import React from "react";
import { styled } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Color } from "@/common/colors/colors";
import userManager from "@/api/userManager";
import api from "@/api/api";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  height: 40px;
`;

const Username = styled.div`
  color: ${Color.DarkBlue};
  font-style: italic;
  margin-right: 10px;
`;

const LogoutIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-right: 10px;
`;

const { tokenManager } = api;

const TopInfo = () => {
  const navigate = useNavigate();
  const username = userManager?.getUser()?.email;

  const handleLogout = () => {
    tokenManager.removeToken();
    userManager.removeUser();
    navigate("/login");
  };

  return (
    <Wrapper>
      <Username>{username}</Username>
      <LogoutIcon
        icon={faSignOutAlt}
        onClick={handleLogout}
        style={{ color: "red" }}
      />
    </Wrapper>
  );
};

export default TopInfo;
