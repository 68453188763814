import Api from "../api";
const { axios: api } = Api;

const PREFIX = "integration";

export const exportDB = async (payload) => {
  const data = await api.post(`/${PREFIX}/export-db`, payload);

  return { data: data.data };
};

export const getTaskStats = async () => {
  const data = await api.get(`/${PREFIX}/task-stats`);

  return { data: data.data };
}
