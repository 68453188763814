import { searchWordBySearchBar } from "@/api/query/word";
import Loading from "@/common/components/Loading";
import Input from "@/common/components/custom/Input";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import SearchResult from "./SearchResult";

const Wrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 20px 30px;
`;

const SearchInput = () => {
  const [result, setResult] = useState();
  const searchRef = useRef();

  const searchMutation = useMutation({
    mutationFn: (value) => searchWordBySearchBar(value),
    onSuccess: ({ data }) => {
      setResult(data);
    },
  });

  const handleSearch = (e) => {
    e?.preventDefault();

    const paylaod = {};
    paylaod.text = searchRef.current.value;

    searchMutation.mutate(paylaod);
  };

  return (
    <>
      {searchMutation.isLoading && <Loading />}
      {result?.length > 0 && <SearchResult data={result} setData={setResult} />}
      <Wrapper onSubmit={handleSearch}>
        <Input
          inputRef={searchRef}
          showLabel={false}
          placeholder="Type word to find..."
          required
        />
        <button
          type="submit"
          style={{
            marginLeft: "-40px",
            background: "transparent",
            border: "none",
          }}
        >
          <FontAwesomeIcon icon={faSearch} style={{ cursor: "pointer" }} />
        </button>
      </Wrapper>
    </>
  );
};

export default SearchInput;
