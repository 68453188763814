import { formatDate } from "@/common/functions/formatDate";
import { faFileCsv, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionsWrapper } from "../AdminCommandPage.styled";
import { Color } from "@/common/colors/colors";
import { JOB_STATUS } from "../constants/jobs";
import { downdloadCSVfromBase64 } from "@/common/functions/downdloadCSVfromBase64";
import moment from "moment";

export const getRaws = (jobProcesses, mutation) =>
  jobProcesses?.map((process, i) => [
    i + 1,
    process.job_name,
    process.status,
    process.arguments_passed,
    process.user.name + " " + process.user.surname,
    process.user_stop
      ? process.user_stop.name + " " + process.user_stop.surname
      : "----",
    formatDate(process.created_at),
    process.pending_date ? formatDate(process.pending_date) : "----",
    process.end_date ? formatDate(process.end_date) : "----",
    process.error_message || "----",
    process.success_amount || "----",
    process.issues_amount || "----",
    process.completed_amount || "----",
    process.uncompleted_amount || "----",
    <ActionsWrapper>
      {process.status !== JOB_STATUS.END ? (
        <FontAwesomeIcon
          onClick={() => mutation.mutate(process._id)}
          cursor="pointer"
          color={Color.red}
          icon={faX}
        />
      ) : (
        "----"
      )}
    </ActionsWrapper>,
    <>
      {process.file ? (
        <FontAwesomeIcon
          icon={faFileCsv}
          onClick={() =>
            downdloadCSVfromBase64(
              process.file.base64,
              `${process.job_name}-${moment(process.created_at).format(
                "DD/MM/YYYY"
              )}`
            )
          }
        />
      ) : (
        "-----"
      )}
    </>,
  ]);
