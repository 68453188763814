import React from "react";
import styled from "styled-components";

const TableWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: #f0f0f0;
`;

const TableBody = styled.tbody`
  max-height: 300px;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const TableCell = styled.td`
  padding: 8px;
  text-align: left;
`;

export const TableHeadCell = styled.th`
  padding: 8px;
  background-color: lightgrey;
  text-align: left;
`;

const CustomComplexTable = ({
  headers,
  headersArray,
  raws = [],
  noDataCols,
  stickyHeader = false,
}) => {
  return (
    <TableWrapper>
      <Table>
        <TableHeader style={stickyHeader ? { position: "sticky", top: 0 } : {}}>
          {headersArray?.map((element, i) => (
            <TableRow key={i}>
              {element.map((header, j) => (
                <React.Fragment key={j}>{header}</React.Fragment>
              ))}
            </TableRow>
          ))}
          {headers && (
            <TableRow>
              {headers?.map((header) => (
                <TableHeadCell key={header}>{header}</TableHeadCell>
              ))}
            </TableRow>
          )}
        </TableHeader>
        <TableBody>
          {raws?.length ? (
            raws.map((raw) => raw)
          ) : (
            <TableRow>
              <TableCell
                colSpan={
                  noDataCols ||
                  headers?.length ||
                  headersArray[1]?.length ||
                  headersArray[0]?.length
                }
              >
                There is no data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

export default CustomComplexTable;
