import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import { useMutation } from "react-query";
import {
  faAd,
  faAdd,
  faAngleDown,
  faAngleUp,
  faArrowTrendDown,
  faDeleteLeft,
  faEdit,
  faGripLinesVertical,
  faLanguage,
  faList,
  faRefresh,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "@/common/components/Loading";
import SearchBar from "./components/SearchBar";
import { findAllLanguages } from "@/api/query/language";
import { findAllLevelsByLanguage, findByLevel } from "@/api/query/level";
import { Color } from "@/common/colors/colors";
import AdminLevelEdit from "./components/AdminLevelEdit";
import AdminCateogryEdit from "./components/AdminCategoryEdit";
import SelectInput from "@/common/components/custom/SelectInput";
import AdminLessonEdit from "./components/AdminLessonEdit";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { VeryficationType } from "@/common/constants/verification.status.constants";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";
import {
  changeLessonPosition,
  deleteLesson,
  findByLessonsCategory,
} from "@/api/query/lesson";
import {
  changeCateogryPosition,
  deleteCategory,
  findCategoryById,
  generateAllTranslationsNames,
} from "@/api/query/category";
import { useSearchParams } from "react-router-dom";

const TopContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  height: calc(100vh - 80px);
`;

const LevelWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 20px;
  width: 100%;
`;

const Level = styled.div`
  background-color: ${Color.darkBlue};
  padding: 10px 20px;
  color: white;
  border-radius: 10px;
`;
const LevelElementWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const CategoriesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

const CategoryElementWrapper = styled.div`
  height: fit-content;
  padding: 10px 20px;
  background-color: ${Color.DarkBlue};
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const WrapperTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ElementWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LessonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LessonElement = styled.div`
  margin-left: 50px;
  background-color: ${Color.DarkGrey};
  padding: 10px 20px;
  border-radius: 15px;
  color: white;
  display: flex;
  gap: 10px;
`;

const AdminLessonPage = () => {
  const [editLevel, setEditLevel] = useState();
  const [editLesson, setEditLesson] = useState();
  const [data, setData] = useState([]);
  const [currentLevel, setCurrentLevel] = useState();
  const [selectedCurrentLevel, setSelectedCurrentLevel] = useState();
  const [options, setOptions] = useState([]);
  const [editCategory, setEditCategory] = useState();
  const [selectedCoreOptions, setSelectedCoreOption] = useState();
  const [currentLessonLanguage, setCurrentLessonLanguage] = useState();
  const [searchParam, setSearchParam] = useSearchParams();

  const findAllLevelsByLanguageMutation = useMutation({
    mutationFn: (value) => findAllLevelsByLanguage(value),
    onSuccess: ({ data }) => {
      setData(() => data);
    },
  });

  const handleLoadLessonsForCategoryMutation = useMutation({
    mutationFn: (value) => findByLessonsCategory(value),
    onSuccess: ({ data }) => {
      const fistElem = data[0];

      if (fistElem) {
        setCurrentLevel((prev) => {
          return prev.map((p) => {
            if (p._id === fistElem.category) {
              p.lessons = data;
            }

            return p;
          });
        });
      }
    },
  });

  const handleLoadLessonsForCategory = (c) => {
    const payload = {};
    payload._id = c._id;

    handleLoadLessonsForCategoryMutation.mutate(payload);
  };

  const findCurrentLevelMutation = useMutation({
    mutationFn: (value) => findByLevel(value),
    onSuccess: ({ data }) => {
      setCurrentLevel(() => data);

      const lesson = searchParam.get("lesson");

      if (lesson) {
        const category = data.find((c) => {
          if (c.lessons.map((l) => l._id).includes(lesson)) {
            return true;
          }

          return false;
        });

        if (category) {
          const lessonToSet = category.lessons.find((l) => l._id === lesson);

          setEditLesson(() => ({
            category: category,
            lesson: lessonToSet,
            options: calcByLang(lessonToSet.words) || {},
            allWords: lessonToSet.words || [],
          }));
        }

        searchParam.delete("lesson");
        searchParam.delete("level");
        searchParam.delete("language");
        setSearchParam(searchParam);
      }
    },
  });

  const findAllLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      const mapedData = data.map((d) => ({
        label: d.name,
        value: d,
      }));

      setOptions(() => mapedData);

      const lang = searchParam.get("language");

      if (lang) {
        setSelectedCoreOption(() =>
          mapedData.find((md) => md.value._id === lang)
        );
      }

      setCurrentLessonLanguage(
        mapedData.find((md) => md.value.shortName === "en")
      );
    },
  });

  const deleteLessonMutation = useMutation({
    mutationFn: (value) => deleteLesson(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload.levelId = selectedCurrentLevel._id;
      findCurrentLevelMutation.mutate(payload);
    },
  });

  const handleSearchCategoryMutation = useMutation({
    mutationFn: (value) => findCategoryById(value),
    onSuccess: ({ data }) => {
      const category = currentLevel.map((ec) => {
        if (ec._id === data[0]._id) {
          return data[0];
        }

        return ec;
      });

      setCurrentLevel(() => category);

      if (editLesson?.lesson?._id) {
        const newLesson = data[0].lessons.find(
          (l) => l._id === editLesson.lesson._id
        );

        setEditLesson(() => ({
          category: data[0],
          lesson: newLesson,
          options: calcByLang(newLesson.words) || {},
          allWords: newLesson.words || [],
        }));
      }
    },
  });

  const deleteCategoryMutation = useMutation({
    mutationFn: (value) => deleteCategory(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload.levelId = selectedCurrentLevel._id;
      findCurrentLevelMutation.mutate(payload);
    },
  });

  const changeLessonPositionMutation = useMutation({
    mutationFn: (value) => changeLessonPosition(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload.levelId = selectedCurrentLevel._id;
      findCurrentLevelMutation.mutate(payload);
    },
  });

  const changeCateogryPositionMutation = useMutation({
    mutationFn: (value) => changeCateogryPosition(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload.levelId = selectedCurrentLevel._id;
      findCurrentLevelMutation.mutate(payload);
    },
  });

  const handleGenerateAllNameMutation = useMutation({
    mutationFn: (value) => generateAllTranslationsNames(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload.levelId = selectedCurrentLevel._id;
      findCurrentLevelMutation.mutate(payload);
    },
  });

  const handleGenereteNames = (c) => {
    const payload = {};
    payload.categoryId = c._id;

    handleGenerateAllNameMutation.mutate(payload);
  };

  const handleDeleteCategory = (category) => {
    const payload = {};
    payload._id = category._id;

    deleteCategoryMutation.mutate(payload);
  };

  const handleDeleteLesson = (lesson) => {
    const payload = {};
    payload._id = lesson._id;

    deleteLessonMutation.mutate(payload);
  };

  const handleChangeCategoryNumber = (c, direction) => {
    const payload = {};
    payload.categoryId = c._id;
    payload.direction = direction;

    changeCateogryPositionMutation.mutate(payload);
  };
  const handleChangeLessonNumber = (l, direction) => {
    const payload = {};
    payload.lessonId = l._id;
    payload.direction = direction;

    changeLessonPositionMutation.mutate(payload);
  };

  const calcByLang = (list) => {
    const map = new Map();

    list?.forEach((l) => {
      l.wordTranslation?.filter((wt) => {
        const veryficationWord = wt.veryfications.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )[0];

        if (
          veryficationWord?.verificationStatus === VeryficationType.CORRECT ||
          wt.reliability > 70
        ) {
          wt.flascards.forEach((f) => {
            const sentenceTranslation = f.sentenceTranslation[0];

            if (!sentenceTranslation) return;

            const veryficationSentence = sentenceTranslation.veryfications.sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )[0];

            if (
              veryficationSentence?.verificationStatus ===
                VeryficationType.CORRECT ||
              (sentenceTranslation.reliability > 70 &&
                f.translationErrors.length === 0 &&
                wt.reliability > 70)
            ) {
              const language = wt.toWord[0]?.language[0]?.name;

              if (map.has(language)) {
                return map.get(language).push(l);
              }

              map.set(language, [l]);
            }
          });
        }
      });
    });

    return Array.from(map)
      .map(([key, value]) => ({ key, value }))
      .sort((a, b) => a.key.localeCompare(b.key));
  };

  const handleSearch = () => {
    const payload = {};
    payload.languageId = selectedCoreOptions?.value?._id;
    findAllLevelsByLanguageMutation.mutate(payload);
  };

  useEffect(() => {
    findAllLanguagesMutation.mutate();
  }, []);

  useEffect(() => {
    if (!editCategory && selectedCoreOptions?.value) {
      const payload = {};
      payload.levelId = selectedCurrentLevel._id;
      findCurrentLevelMutation.mutate(payload);
    }
  }, [editCategory]);

  useEffect(() => {
    if (selectedCurrentLevel) {
      const payload = {};
      payload.levelId = selectedCurrentLevel._id;
      findCurrentLevelMutation.mutate(payload);
    }
  }, [selectedCurrentLevel]);

  useEffect(() => {
    if (!data?.length) {
      const language = searchParam.get("language");
      const level = searchParam.get("level");
      const lesson = searchParam.get("lesson");

      if (language && level && lesson) {
        const payload2 = {};
        payload2.languageId = language;
        findAllLevelsByLanguageMutation.mutate(payload2);

        setSelectedCurrentLevel(() => ({ _id: level }));
      }
    }
  }, [searchParam]);

  return (
    <>
      {(findAllLanguagesMutation.isLoading ||
        findAllLevelsByLanguageMutation.isLoading ||
        findCurrentLevelMutation.isLoading ||
        handleSearchCategoryMutation.isLoading ||
        handleLoadLessonsForCategoryMutation.isLoading ||
        handleGenerateAllNameMutation.isLoading) && <Loading />}
      {editLevel && (
        <AdminLevelEdit
          setClose={setEditLevel}
          edit={editLevel}
          languages={options}
        />
      )}
      {editLesson && (
        <AdminLessonEdit
          setClose={setEditLesson}
          edit={editLesson}
          languages={options}
          reload={handleSearchCategoryMutation}
        />
      )}
      {editCategory && (
        <AdminCateogryEdit
          edit={editCategory}
          setClose={setEditCategory}
          levels={data?.map((d) => ({ label: d.defualtName, value: d }))}
          handleSearch={handleSearch}
          languages={options}
          currentSelectedLevel={selectedCurrentLevel}
        />
      )}
      <Wrapper>
        <SearchBar
          selectedCoreOptions={selectedCoreOptions}
          setSelectedCoreOption={setSelectedCoreOption}
          options={options}
          handleSearch={handleSearch}
          setEditLevel={setEditLevel}
        />
        {data?.length > 0 && (
          <>
            <WrapperTop>
              <LevelWrapper>
                {data.map((d) => (
                  <LevelElementWrapper>
                    <Level
                      onClick={() => setSelectedCurrentLevel(d)}
                      style={{
                        background:
                          d._id === selectedCurrentLevel?._id
                            ? Color.gold
                            : Color.DarkBlue,
                        cursor: "pointer",
                      }}
                    >
                      {d?.defualtName}
                    </Level>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={() => setEditLevel(d)}
                    />
                  </LevelElementWrapper>
                ))}
              </LevelWrapper>
              <TopContent>
                <SelectInput
                  name="Translation Lang"
                  options={options}
                  selectWidth={200}
                  selected={currentLessonLanguage}
                  setSelected={setCurrentLessonLanguage}
                />
                {selectedCurrentLevel && (
                  <CustomAddButton
                    onClick={() => setEditCategory({})}
                    text={
                      <FontAwesomeIcon
                        icon={faAdd}
                        style={{ color: "white" }}
                      />
                    }
                  />
                )}
              </TopContent>
            </WrapperTop>
            <CategoriesWrapper>
              {currentLevel
                ?.sort((a, b) => a.number - b.number)
                .map((c) => (
                  <>
                    <ElementWrapper>
                      <div
                        style={{
                          width: "100px",
                          overflow: "hidden",
                          borderRadius: "15px",
                        }}
                      >
                        <img
                          src={c?.image[0]?.base64}
                          style={{ width: "100%", height: "auto" }}
                        />
                      </div>
                      <CategoryElementWrapper
                        onClick={() => setEditCategory(c)}
                      >
                        {c.number}.{" "}
                        {
                          c.category_names?.find(
                            (cn) =>
                              cn.language === currentLessonLanguage?.value?._id
                          )?.text
                        }
                      </CategoryElementWrapper>
                      <CustomAddButton
                        text={<FontAwesomeIcon icon={faAdd} />}
                        onClick={() =>
                          setEditLesson(() => ({
                            category: c,
                            lesson: {},
                            options: {},
                            allWords: [],
                          }))
                        }
                      />
                      <CustomAddButton
                        text={<FontAwesomeIcon icon={faAngleDown} />}
                        onClick={() => handleChangeCategoryNumber(c, "up")}
                      />
                      <CustomAddButton
                        text={<FontAwesomeIcon icon={faAngleUp} />}
                        onClick={() => handleChangeCategoryNumber(c, "down")}
                      />
                      <CustomAddButton
                        text={<FontAwesomeIcon icon={faList} />}
                        onClick={() => handleLoadLessonsForCategory(c)}
                      />
                      <CustomAddButton
                        text={<FontAwesomeIcon icon={faLanguage} />}
                        onClick={() => handleGenereteNames(c)}
                      />
                      <CustomDeleteButton
                        text={<FontAwesomeIcon icon={faTrash} />}
                        onClick={() => handleDeleteCategory(c)}
                      />
                    </ElementWrapper>
                    <LessonsWrapper>
                      {c?.lessons
                        ?.sort((a, b) => a.number - b.number)
                        .map((l) => (
                          <ElementWrapper>
                            <LessonElement>
                              {c.number}.{l.number}.{" "}
                              {l.words
                                .sort(
                                  (a, b) => a.numberInLesson - b.numberInLesson
                                )
                                .map((w) => w.content)
                                .slice(0, 3)
                                .join(", ")}{" "}
                              ({l.type}, eng: {l.words?.length}){" "}
                              {calcByLang(l.words).map(({ key, value }) => (
                                <div>
                                  {key} {value.length}
                                </div>
                              ))}
                            </LessonElement>
                            <CustomEditButton
                              text={<FontAwesomeIcon icon={faEdit} />}
                              onClick={() =>
                                setEditLesson(() => ({
                                  category: c,
                                  lesson: l,
                                  options: calcByLang(l.words) || {},
                                  allWords: l.words || [],
                                }))
                              }
                            />
                            <CustomAddButton
                              text={<FontAwesomeIcon icon={faAngleDown} />}
                              onClick={() => handleChangeLessonNumber(l, "up")}
                            />
                            <CustomAddButton
                              text={<FontAwesomeIcon icon={faAngleUp} />}
                              onClick={() =>
                                handleChangeLessonNumber(l, "down")
                              }
                            />
                            <CustomDeleteButton
                              text={<FontAwesomeIcon icon={faTrash} />}
                              onClick={() => handleDeleteLesson(l)}
                            />
                          </ElementWrapper>
                        ))}
                    </LessonsWrapper>
                  </>
                ))}
            </CategoriesWrapper>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default AdminLessonPage;
