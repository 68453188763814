import ROUTES from "@/common/routes/routes";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MessageQueue, { useMessageQueue } from "./common/message";
import { MessageContext } from "./common/contexts/message.context";
import { CommonContext } from "./common/contexts/common.context";
import { useMutation } from "react-query";
import { getCommonData } from "./api/query/common";
import userManager from "./api/userManager";

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [commonData, setCommonData] = useState(
    (localStorage.getItem("common") &&
      JSON.parse(localStorage.getItem("common"))) ||
      {}
  );

  const { addMessage, removeMessage, messages } = useMessageQueue();

  const handleLoadCommonDataMutation = useMutation({
    mutationFn: () => getCommonData(),
    onSuccess: ({ data }) => {
      setCommonData(data);
      localStorage.setItem("common", JSON.stringify(data));
    },
  });

  useEffect(() => {
    // const paths = ROUTES.map((e) => e.path);
    // if (!paths.includes(pathname)) {
    //   navigate("/login");
    // }
  }, [pathname]);

  useEffect(() => {
    const user = userManager.getUser();

    if (user) {
      handleLoadCommonDataMutation.mutate();
    }
  }, []);

  return (
    <>
      <MessageQueue
        messages={messages}
        removeMessage={removeMessage}
        addMessage={addMessage}
      />
      <CommonContext.Provider
        value={{
          ...commonData,
        }}
      >
        <MessageContext.Provider
          value={{
            addMessage,
            removeMessage,
            messages,
          }}
        >
          <Routes>
            {ROUTES.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
                Component={route.component}
              />
            ))}
          </Routes>
        </MessageContext.Provider>
      </CommonContext.Provider>
    </>
  );
}

export default App;
