import { createJobProcess } from "@/api/query/jobProcess";
import { findAllLanguages } from "@/api/query/language";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { MessageContext } from "@/common/contexts/message.context";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ButtonWrapper, Title } from "../../AdminCommandPage.styled";
import { JOB_NAME } from "../../constants/jobs";
import { getAllTranslators } from "@/api/query/translator";

const CheckWordSentence = ({ setClose, reloadMutation }) => {
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [selectedLanguageFrom, setSelectedLanguageFrom] = useState();
  const [selectedLanguageTo, setSelectedLanguageTo] = useState();

  const { addMessage } = useContext(MessageContext);

  const loadLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setLanguagesOptions(() => data.map((d) => ({ label: d.name, value: d })));
    },
  });

  const loadData = () => {
    loadLanguagesMutation.mutate();
  };

  const createJobProcessMutation = useMutation({
    mutationFn: (values) => createJobProcess(values),
    onSuccess: () => {
      addMessage("Added job", "success");
      setClose((prev) => !prev);
      reloadMutation.mutate();
    },
    onError: () => {
      addMessage("Something went wrong", "error");
    },
  });

  const handleCreateJobProcess = () => {
    if (!selectedLanguageTo || !selectedLanguageFrom)
      return addMessage("Select languages", "error");

    const jobName = JOB_NAME.CHECK_WORD_IN_SENTENCE;
    const argumentsPassed = {
      languageFrom: selectedLanguageFrom.value.shortName,
      languageTo: selectedLanguageTo.value.shortName,
    };

    const payload = {};

    payload.job_name = jobName;
    payload.arguments_passed = JSON.stringify(argumentsPassed);

    createJobProcessMutation.mutate(payload);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {createJobProcessMutation.isLoading && <Loading />}
      <PopUp setClose={setClose}>
        <Title>Check Word Sentence</Title>
        <SelectInput
          width={150}
          name={"Language from"}
          options={languagesOptions}
          selected={selectedLanguageFrom}
          setSelected={setSelectedLanguageFrom}
        />
        <SelectInput
          width={150}
          name={"Language to"}
          options={languagesOptions}
          selected={selectedLanguageTo}
          setSelected={setSelectedLanguageTo}
        />
        <ButtonWrapper>
          <CustomEditButton
            text="Find"
            onClick={() => handleCreateJobProcess()}
          />
        </ButtonWrapper>
      </PopUp>
    </>
  );
};

export default CheckWordSentence;
