import { TableCell, TableHeadCell, TableRow } from "@/common/components/custom/CustomComplexTable";

export const generateTableData = (data) => {
  const langFromMap = new Map();
  const levelsSet = new Set();

  data.forEach(item => {
    const key = item.languagePairShort.split('-')[0].toUpperCase();
    const longTo = item.languagePair.split('-')[1];

    const currentData = langFromMap.get(key) || {};


    if (!currentData[longTo]) {
      currentData[longTo] = {};
    }

    levelsSet.add(item.level);
    currentData[longTo][item.level] = item.count;

    langFromMap.set(key, currentData);
  })

  const levels = Array.from(levelsSet).sort().map(level => `Level ${level}`);
  const rawsDataArray = [];

    langFromMap.forEach((value, key) => {
      const langRaws = Object.entries(value).map(([lang, levels]) => {
        const langRaws = [lang, ...Object.values(levels)];
        return langRaws;
      });

      rawsDataArray.push([{ value: key, span: langRaws.length}, langRaws]);
    });

    const headersData = [[<TableHeadCell />, <TableHeadCell colSpan={levels.length + 1}>Task count</TableHeadCell>], 
    [<TableHeadCell />,<TableHeadCell />, ...levels.map(level => <TableHeadCell>{level}</TableHeadCell>)]];

    const preRaws = [];
  
    rawsDataArray.forEach(([lang, langCols]) => {
      const newItem = [lang];
      newItem.push(...langCols[0].map((value) => ({ span: 1, value})));

      preRaws.push(newItem);
      
      langCols.slice(1).forEach((langCol) => {
        const nextItem = [{ value: langCol[0], span: 1}];
        nextItem.push(...langCol.slice(1).map((value) => ({ span: 1, value})));
        preRaws.push(nextItem);
      });
      preRaws.push([{ value: '', span: 1}, ...Array.from({ length: levels.length + 1 }).map(() => ({ span: 1, value: ''}))])
     });

    const rawsData = preRaws.map((raw, i) => (
      <TableRow key={i}>
        {raw.map((cell, i) => (
          <TableCell key={i} rowSpan={cell.span}>{cell.value}</TableCell>
        ))}
      </TableRow>
     ))

    return { rawsData, headersData };
} 