import {
  createAditionalPage,
  getAllAditionalPageByCoreAndType,
  updateAditionalPage,
} from "@/api/query/aditionalPage";
import { findAllLanguages } from "@/api/query/language";
import { Color } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import CustomTable from "@/common/components/custom/CustomTable";
import SelectInput from "@/common/components/custom/SelectInput";
import TextArea from "@/common/components/custom/TextArea";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { Title } from "../../commands/AdminCommandPage.styled";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { MessageContext } from "@/common/contexts/message.context";
import { formatDate } from "@/common/functions/formatDate";
import isHtml from "is-html";

const NavWrapper = styled.div`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  box-shadow: 0px 2px 10px ${Color.Grey};
  width: fit-content;
  margin: 10px auto;
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  background-color: ${Color.DarkBlue};
  color: ${Color.White};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const Wrapper = styled.div`
  height: calc(100vh - 120px);
  overflow-y: auto;
`;

const HEADERS = ["No.", "Lang", "Last exported date", "Actions"];

const AlphabetPage = () => {
  const [tableData, setTableData] = useState();
  const [editAditionalPage, setEditAditionalPage] = useState();
  const [selectedCoreLang, setSelectedCoreLang] = useState();
  const [selectedLangTo, setSelectedLangTo] = useState();
  const pageRef = useRef();
  const [showEditedView, setShowEditedView] = useState();
  const { addMessage } = useContext(MessageContext);

  const [options, setOptions] = useState([]);

  const findLanguagesMutation = useMutation({
    mutationFn: (payload) => findAllLanguages(payload),
    onSuccess: ({ data }) => {
      setOptions(() => data.map((d) => ({ label: d.name, value: d._id })));
    },
  });

  const createAditionalPageMutation = useMutation({
    mutationFn: (payload) => createAditionalPage(payload),
    onSuccess: ({ data }) => {
      setEditAditionalPage(() => null);
      setSelectedLangTo(() => null);
      addMessage("Added", "success");
    },
    onError: () => {
      addMessage("Language exists", "error");
    },
  });

  const updateAditionalPageMutation = useMutation({
    mutationFn: (payload) => updateAditionalPage(payload._id, payload),
    onSuccess: ({ data }) => {
      setEditAditionalPage(() => null);
      setSelectedLangTo(() => null);
      addMessage("Updated", "success");
    },
    onError: () => {
      addMessage("Language exists", "error");
    },
  });

  const searchStatsDataMutation = useMutation({
    mutationFn: (payload) =>
      getAllAditionalPageByCoreAndType(payload.langFrom, payload.type),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((a, i) => [
          i + 1,
          a.langTo.name,
          formatDate(a.exported_date),
          <div style={{ display: "flex", gap: "10px" }}>
            <CustomEditButton
              onClick={() => {
                const foundLang = options.find((o) => o.value === a.langTo._id);
                setSelectedLangTo(foundLang);
                setEditAditionalPage(a);
              }}
            />
            <CustomEditButton
              text="Show"
              onClick={() => {
                setShowEditedView(a);
              }}
            />
          </div>,
        ])
      );
    },
  });

  const handleSearch = () => {
    if (!selectedCoreLang) {
      return;
    }

    const payload = {};
    payload.langFrom = selectedCoreLang.value;
    payload.type = "alphabet";

    searchStatsDataMutation.mutate(payload);
  };

  const handleSave = (e) => {
    e?.preventDefault();
    if (!selectedLangTo) {
      return;
    }

    let isWrong = false;

    if (!isHtml(pageRef.current.value)) {
      addMessage("WRONG MAIN PAGE", "error");
      isWrong = true;
    }

    if (isWrong) {
      return;
    }

    const payload = {};
    payload.langFrom = selectedCoreLang.value;
    payload.langTo = selectedLangTo.value;
    payload.page = pageRef.current.value;
    payload.type = "alphabet";

    if (editAditionalPage._id) {
      payload._id = editAditionalPage._id;
      updateAditionalPageMutation.mutate(payload);
      return;
    }

    createAditionalPageMutation.mutate(payload);
  };

  useEffect(() => {
    findLanguagesMutation.mutate();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [editAditionalPage]);

  return (
    <>
      {showEditedView && (
        <PopUp setClose={setShowEditedView}>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: showEditedView.page }}
          ></div>
        </PopUp>
      )}
      {editAditionalPage && (
        <PopUp setClose={setEditAditionalPage}>
          <Title>
            {editAditionalPage?._id ? "Edit" : "Add"} Aditional Page
          </Title>
          <form onSubmit={handleSave}>
            <SelectInput
              width={120}
              name="Language to"
              color={Color.DarkBlue}
              selectWidth={200}
              options={options}
              selected={selectedLangTo}
              setSelected={setSelectedLangTo}
            />
            <TextArea
              label={"Page"}
              textAreaRef={pageRef}
              minHeight={"400px"}
              defaultValue={editAditionalPage.page}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                margin: "10px 20px",
              }}
            >
              <CustomSaveButton />
            </div>
          </form>
        </PopUp>
      )}
      <Wrapper>
        <NavWrapper>
          <SelectInput
            width={100}
            name="Language"
            color={Color.DarkBlue}
            selectWidth={200}
            options={options}
            selected={selectedCoreLang}
            setSelected={setSelectedCoreLang}
          />
          <SearchButton onClick={handleSearch}>Search</SearchButton>
          {selectedCoreLang && (
            <SearchButton onClick={() => setEditAditionalPage({})}>
              Add
            </SearchButton>
          )}
        </NavWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minWidth: "600px",
          }}
        >
          {tableData && <CustomTable headers={HEADERS} data={tableData} />}
        </div>
      </Wrapper>
    </>
  );
};

export default AlphabetPage;
