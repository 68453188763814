import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "flashcard";

export const deleteFlashCard = async (payload) => {
  const data = await api.delete(`/${PREFIX}/${payload.flashcardId}`);
  return { data: data.data };
};
