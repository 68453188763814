import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faTable,
  faHome,
  faLanguage,
  faCode,
  faChartArea,
  faFileWord,
  faGraduationCap,
  faEarthAmericas,
  faPager,
} from "@fortawesome/free-solid-svg-icons";
import { Color } from "@/common/colors/colors";
import { Link } from "react-router-dom";

const TooltipContainer = styled.div`
  position: absolute;
  background-color: ${Color.DarkBlue};
  color: ${Color.White};
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 999;
  font-size: 8px;
  top: 50px;
  transition: opacity 0.3s ease;
`;

const Tooltip = ({ text }) => {
  return <TooltipContainer>{text}</TooltipContainer>;
};

const Sidebar = styled.div`
  background-color: ${Color.DarkBlue};
  border-radius: 0 10px 10px 0;
  width: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
`;

const NavItem = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  color: ${Color.White};
  transition: background-color 0.3s ease-in-out;
`;

const IconWrapper = styled.div`
  background-color: ${Color.White};
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const NAVIGATION_CONFIG = [
  {
    label: "Home",
    icon: faHome,
    link: "/admin/home",
  },
  {
    label: "User",
    icon: faUser,
    link: "/admin/user",
  },
  {
    label: "Pair list",
    icon: faTable,
    link: "/admin/languages-pair-list",
  },
  {
    label: "Word List",
    icon: faFileWord,
    link: "/admin/word-list",
  },
  {
    label: "Lang List",
    icon: faLanguage,
    link: "/admin/language",
  },
  {
    label: "Lesson",
    icon: faGraduationCap,
    link: "/admin/lesson",
  },
  {
    label: "Aditional pages",
    icon: faPager,
    link: "/admin/aditional-pages",
  },
  {
    label: "Stats",
    icon: faChartArea,
    link: "/admin/stats",
  },
  {
    label: "Commands",
    icon: faCode,
    link: "/admin/commands",
  },
  {
    label: "Translators",
    icon: faEarthAmericas,
    link: "/admin/translators",
  },
];

const Navigation = () => {
  const [tooltipText, setTooltipText] = useState("");

  const handleMouseEnter = (label) => {
    setTooltipText(label);
  };

  const handleMouseLeave = () => {
    setTooltipText("");
  };

  return (
    <Sidebar>
      {NAVIGATION_CONFIG.map((nav) => (
        <NavItem
          to={nav.link}
          onMouseEnter={() => handleMouseEnter(nav.label)}
          onMouseLeave={handleMouseLeave}
        >
          <IconWrapper>
            <FontAwesomeIcon
              icon={nav.icon}
              style={{ color: Color.DarkBlue }}
            />
          </IconWrapper>
          {nav.label === tooltipText && (
            <Tooltip text={tooltipText} position={{ top: "300px" }} />
          )}
        </NavItem>
      ))}
    </Sidebar>
  );
};

export default Navigation;
