import React, { useEffect, useRef, useState } from "react";
import SearchBar from "./SearchBar";
import { findAllLanguages } from "@/api/query/language";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import { searchStatsDataForTranslationLangLessonList } from "@/api/query/stats";
import CustomTable from "@/common/components/custom/CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { Color } from "@/common/colors/colors";

const LangLessonList = () => {
  const [options, setOptions] = useState([]);
  const [selectedCoreOptions, setSelectedCoreOption] = useState();
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);

  const findLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setOptions(() => data.map((d) => ({ label: d.name, value: d })));
    },
  });

  const checkVeryfiaction = (ver) => {
    const veryfications =
      ver?.veryfications?.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      ) || [];

    if (
      veryfications?.length > 0 &&
      veryfications[0].verificationStatus === "correct"
    ) {
      return true;
    }

    const flascard = ver?.flascards[0];

    if (
      flascard?.sentenceTranslation[0]?.reliability >= 70 &&
      ver?.reliability >= 70
    ) {
      return true;
    }

    return false;
  };

  const searchStatsDataMutation = useMutation({
    mutationFn: (payload) =>
      searchStatsDataForTranslationLangLessonList(payload),
    onSuccess: ({ data }) => {
      const rows = [];

      data
        .sort((a, b) => a.level - b.level)
        .forEach((d) =>
          d.categories
            .sort((a, b) => a.number - b.number)
            .forEach((c) =>
              c.lessons
                .sort((a, b) => a.number - b.number)
                .forEach((lesson) => {
                  let wordsTranslation = [];

                  if (lesson.type === "game") {
                    options
                      .filter((l) => l.value.isLangTo)
                      .forEach((lang) => {
                        const wordsTranslationFlashCard = lesson?.words?.filter(
                          (currentWord) => {
                            const wordsTranslationToCheck =
                              currentWord.wordTranslation?.filter(
                                (wt) => wt.flascards.length > 0
                              );

                            const langFlashCard = wordsTranslationToCheck.find(
                              (lfc) =>
                                lfc.toWord[0].language[0]._id === lang.value._id
                            );

                            const isValid = checkVeryfiaction(langFlashCard);
                            return isValid;
                          }
                        );

                        wordsTranslation.push(
                          <div
                            style={{
                              color:
                                wordsTranslationFlashCard?.length <= 2
                                  ? Color.red
                                  : wordsTranslationFlashCard?.length <= 4
                                  ? Color.orange
                                  : Color.green,
                              fontWeight: "bold",
                            }}
                          >
                            {wordsTranslationFlashCard?.length}
                          </div>
                        );
                      });
                    wordsTranslation.push("|");

                    options
                      .filter((l) => l.value.isLangTo)
                      .forEach((lang) => {
                        const wordsTranslationFlashCard = lesson?.words?.filter(
                          (currentWord) => {
                            const wordsTranslationToCheck =
                              currentWord.wordTranslation?.filter(
                                (wt) => wt.flascards.length > 0
                              );

                            const langFlashCard = wordsTranslationToCheck.find(
                              (lfc) =>
                                lfc.toWord[0].language[0]._id === lang.value._id
                            );

                            if (!langFlashCard) {
                              return false;
                            }

                            const isValid = currentWord?.migration
                              ?.map((mg) => mg.language)
                              ?.includes(lang.value._id);

                            return isValid;
                          }
                        );

                        wordsTranslation.push(
                          <div
                            style={{
                              color:
                                wordsTranslationFlashCard?.length <= 2
                                  ? Color.red
                                  : wordsTranslationFlashCard?.length <= 4
                                  ? Color.orange
                                  : Color.green,
                              fontWeight: "bold",
                            }}
                          >
                            {wordsTranslationFlashCard?.length}
                          </div>
                        );
                      });
                  } else {
                    options
                      .filter((l) => l.value.isLangTo)
                      .forEach((lang) => {
                        const wordsTranslationFlashCard = lesson?.tasks?.find(
                          (currentTask) =>
                            currentTask.language === lang.value._id
                        );

                        wordsTranslation.push(
                          wordsTranslationFlashCard ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ color: Color.green }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faX}
                              style={{ color: Color.red }}
                            />
                          )
                        );
                      });

                    wordsTranslation.push("|");

                    options
                      .filter((l) => l.value.isLangTo)
                      .forEach((lang) => {
                        const wordsTranslationFlashCard = lesson?.tasks?.find(
                          (currentTask) =>
                            currentTask.language === lang.value._id
                        );

                        wordsTranslation.push(
                          wordsTranslationFlashCard?.migration_date ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ color: Color.green }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faX}
                              style={{ color: Color.red }}
                            />
                          )
                        );
                      });
                  }

                  rows.push([
                    `${d.level}.${c.number}.${lesson.number}`,
                    lesson.type,
                    lesson.type === "game" ? lesson.words.length : "-----",
                    ...wordsTranslation,
                  ]);
                })
            )
        );

      setData(rows);
      setAllData(data);
    },
  });

  const handleSearch = () => {
    if (!selectedCoreOptions) {
      return;
    }

    const payload = {};
    payload.langFrom = selectedCoreOptions.value._id;

    searchStatsDataMutation.mutate(payload);
  };

  useEffect(() => {
    findLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {(findLanguagesMutation.isLoading ||
        searchStatsDataMutation.isLoading) && <Loading />}
      <SearchBar
        selectedCoreOptions={selectedCoreOptions}
        setSelectedCoreOption={setSelectedCoreOption}
        options={options}
        handleSearch={handleSearch}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {data.length > 0 && (
          <CustomTable
            headers={[
              "LV",
              "Type",
              "Core Words",
              ...options
                .filter((l) => l.value.isLangTo)
                .map((o) => o.value?.shortName),
              "Migration",
              ...options
                .filter((l) => l.value.isLangTo)
                .map((o) => o.value?.shortName),
            ]}
            data={data}
          />
        )}
      </div>
    </>
  );
};

export default LangLessonList;
