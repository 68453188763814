import React, { useEffect, useState } from "react";
import GramarPage from "./components/GramarPage";
import AlphabetPage from "./components/AlphabetPage";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { Color } from "@/common/colors/colors";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";

const config = [
  {
    name: "gramar",
    page: <GramarPage />,
  },
  {
    name: "alphabet",
    page: <AlphabetPage />,
  },
];

const StatsReportsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  gap: 20px;
`;

const AditionalPages = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(config[0]);

  const handleChangeView = (view) => {
    setPage(view);
    setSearchParams({ ["show"]: view.name });
  };

  useEffect(() => {
    if (!page) {
      const statsShow = searchParams.get("show");
      const view = config.find((o) => o.value === statsShow) || config[0];

      setPage(view);
    }
  }, [searchParams]);

  return (
    <div>
      <StatsReportsWrapper>
        {config.map((o) => (
          <CustomEditButton
            key={o.name}
            text={o.name}
            style={{
              backgroundColor: page?.name !== o?.name ? Color.Grey : "#212f46",
            }}
            onClick={() => handleChangeView(o)}
          />
        ))}
      </StatsReportsWrapper>
      <div>{page?.page}</div>
    </div>
  );
};

export default AditionalPages;
