import { cleanObject } from "@/common/functions/cleanObject";
import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "sentence";

export const createSentence = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);

  return { data: data.data };
};

export const findAllSentenceForWord = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/find-all-sentence-for-word/${payload._id}`
  );

  return { data: data.data };
};

export const changeFlashCardSentenceTrnaslationTo = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/change-flash-card-sentence-trnaslation-to`,
    payload
  );

  return { data: data.data };
};

export const generateAlternatives = async (payload) => {
  const data = await api.post(`/${PREFIX}/generate-alternatives`, payload);

  return { data: data.data };
};

export const changeFlashCardSentenceTrnaslation = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/change-flash-card-sentence-trnaslation`,
    payload
  );

  return { data: data.data };
};

export const loadAllAlternativetoSentenceForWordInLang = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/load-all-alternative-sentence-to-for-word-in-lang?${queryString.stringify(
      cleanObject(payload),
      { arrayFormat: "bracket" }
    )}`
  );

  return { data: data.data };
};

export const loadAllAlternativeSentenceForWordInLang = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/load-all-alternative-sentence-for-word-in-lang?${queryString.stringify(
      cleanObject(payload),
      { arrayFormat: "bracket" }
    )}`
  );

  return { data: data.data };
};

export const updateSentence = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);

  return { data: data.data };
};
