import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import { findAllLanguages } from "@/api/query/language";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import { searchStatsDataForTranslationScore } from "@/api/query/stats";
import CustomTable from "@/common/components/custom/CustomTable";
import styled from "styled-components";
import CustomBar from "@/common/charts/Bar";
import { Color } from "@/common/colors/colors";

const colors = [
  Color.red,
  Color.blue,
  Color.green,
  Color.gold,
  Color.brightOrange,
];

const Title = styled.h3`
  text-align: left;
  width: 100%;
  margin-bottom: -20px;
`;

const Translators = () => {
  const [options, setOptions] = useState([]);
  const [selectedCoreOptions, setSelectedCoreOption] = useState();
  const [selectedTranslationOptions, setSelectedTranslationOptions] =
    useState();
  const [tableData, setTableData] = useState();
  const [sentenceData, setSentenceData] = useState();

  const findLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setOptions(() => data.map((d) => ({ label: d.name, value: d._id })));
    },
  });

  const handleData = (data) => {
    return {
      labels: data[0].slice(1).map((_, i) => `${i + 1} vote`),
      datasets: data.map((wr, j) => ({
        label: wr[0],
        data: wr.slice(1),
        backgroundColor: colors[j],
      })),
    };
  };

  const searchStatsDataMutation = useMutation({
    mutationFn: (payload) => searchStatsDataForTranslationScore(payload),
    onSuccess: ({ data }) => {
      console.log(data);
      setTableData(handleData(data.wordResult));
      setSentenceData(handleData(data.setneceResult));
    },
  });

  const handleSearch = () => {
    if (!selectedCoreOptions || !selectedTranslationOptions) {
      return;
    }

    const payload = {};
    payload.langFrom = selectedCoreOptions.value;
    payload.langTo = selectedTranslationOptions.value;

    searchStatsDataMutation.mutate(payload);
  };

  useEffect(() => {
    findLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {(findLanguagesMutation.isLoading ||
        searchStatsDataMutation.isLoading) && <Loading />}
      <SearchBar
        selectedCoreOptions={selectedCoreOptions}
        setSelectedCoreOption={setSelectedCoreOption}
        options={options}
        setSelectedTranslationOptions={setSelectedTranslationOptions}
        selectedTranslationOptions={selectedTranslationOptions}
        handleSearch={handleSearch}
      />
      <Title>Word</Title>
      {tableData && <CustomBar data={tableData} />}
      <Title>Sentence</Title>
      {sentenceData && <CustomBar data={sentenceData} />}
    </>
  );
};

export default Translators;
