import { cleanObject } from "@/common/functions/cleanObject";
import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "stats";

export const searchStatsDataForLanguagesScore = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/search-stats-data-for-languages-score?${queryString.stringify(
      cleanObject(payload),
      {
        arrayFormat: "bracket",
      }
    )}`
  );
  return { data: data.data };
};

export const searchStatsDataForTranslationLangLessonList = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/search-stats-data-for-translation-lang-lesson-list?${queryString.stringify(
      cleanObject(payload),
      {
        arrayFormat: "bracket",
      }
    )}`
  );
  return { data: data.data };
};

export const searchStatsDataForTranslationScore = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/search-stats-data-for-translation-score?${queryString.stringify(
      cleanObject(payload),
      {
        arrayFormat: "bracket",
      }
    )}`
  );
  return { data: data.data };
};

export const searchStatsDataForTranslationLangPair = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/search-stats-data-for-translation-lang-pair?${queryString.stringify(
      cleanObject(payload),
      {
        arrayFormat: "bracket",
      }
    )}`
  );
  return { data: data.data };
};

export const loadPairsStats = async () => {
  const data = await api.get(`/${PREFIX}/stats-for-language-pairs-commands`);
  return { data: data.data };
};
