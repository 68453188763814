import { createAdmin, updateAdmin } from "@/api/query/admin";
import ChangeLog from "@/common/components/ChangeLog";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useRef } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

const AdminEdit = ({ edit, setEdit }) => {
  const nameRef = useRef();
  const surnameRef = useRef();
  const passowrdRef = useRef();
  const emailRef = useRef();
  const activeRef = useRef();

  const createAdminMutation = useMutation({
    mutationFn: (value) => createAdmin(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateAdminMutation = useMutation({
    mutationFn: (value) => updateAdmin(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.surname = surnameRef.current.value;
    payload.password = passowrdRef.current.value;
    payload.email = emailRef.current.value;
    payload.active = activeRef.current.checked;

    if (edit._id) {
      return updateAdminMutation.mutate(payload);
    }

    createAdminMutation.mutate(payload);
  };

  return (
    <>
      {(updateAdminMutation.isLoading || createAdminMutation.isLoading) && (
        <Loading />
      )}
      <PopUp setClose={setEdit}>
        <Title>{edit._id ? "Edit" : "Create"} Admin</Title>
        <Wrapper onSubmit={(e) => handleSave(e)} autoComplete="new-password">
          <Input
            inputRef={nameRef}
            name="Name"
            width={100}
            required
            value={edit.name}
          />
          <Input
            inputRef={surnameRef}
            name="Surname"
            width={100}
            required
            value={edit.surname}
          />
          <Input
            inputRef={emailRef}
            name="Email"
            width={100}
            type="email"
            required
            value={edit.email}
          />
          <Input
            inputRef={passowrdRef}
            name="Password"
            width={100}
            type="password"
            value={null}
            required={!edit._id}
          />
          <Input
            inputRef={activeRef}
            name="Active"
            width={100}
            type="checkbox"
            checked={edit.active}
          />
          <ButtonWrapper>
            <CustomSaveButton />
          </ButtonWrapper>
        </Wrapper>
        <ChangeLog entity={"user"} entityId={edit._id} />
      </PopUp>
    </>
  );
};

export default AdminEdit;
