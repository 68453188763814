import { createSentence, updateSentence } from "@/api/query/sentence";
import { findAllTranslationFroWord, updateWord } from "@/api/query/word";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import TextArea from "@/common/components/custom/TextArea";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { Title } from "@/pages/common/login/loginPage.styles";
import React, { useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin: 30px;
`;

const CustomButtonSubmitWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 20px 0 -20px 0;
`;

const EditWord = ({ setClose, edit }) => {
  const texareaRef = useRef();

  const updateSentenceMutation = useMutation({
    mutationFn: (value) => updateWord(value),
    onSuccess: () => {
      setClose(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = {};
    payload.content = texareaRef.current.value;
    payload._id = edit._id;

    return updateSentenceMutation.mutate(payload);
  };

  return (
    <>
      {updateSentenceMutation.isLoading && <Loading />}
      <PopUp setClose={setClose}>
        <Wrapper onSubmit={handleSave}>
          <Title>Word Edit</Title>
          <TextArea
            textAreaRef={texareaRef}
            placeholder="Type here word"
            defaultValue={edit.content}
            required
          />
          <CustomButtonSubmitWrapper>
            <CustomSaveButton />
          </CustomButtonSubmitWrapper>
        </Wrapper>
      </PopUp>
    </>
  );
};

export default EditWord;
