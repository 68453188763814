export const JOB_NAME = {
  TRANSLATION_SCRIPT: "translation_script",
  TEXT_TO_SPEECH_SCRIPT: "text_to_speech_script",
  TRANSLATION_SCRIPT_NEW: "translation_script_new",
  CSV_EXPORT: "csv_export",
  CSV_EXPORT_TASK: "csv_export_task",
  ALGO_CREATION: "algo_creation",
  CHECK_WORD_IN_SENTENCE: "check_word_in_sentence",
  ADITIONAL_PAGE_EXPORT: "aditional_page_export",
};

export const JOB_STATUS = {
  TO_PROCESS: "to_process",
  PENDING: "pending",
  END: "end",
};
