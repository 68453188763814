import Api from "../api";
const { axios: api } = Api;

const PREFIX = "job-process";

export const createJobProcess = async (payload) => {
  const data = await api.post(`/${PREFIX}/create`, payload);

  return { data: data.data };
};

export const getAllSortedJobProcesses = async () => {
  const data = await api.get(`/${PREFIX}/all-sorted`);

  return { data: data.data };
};

export const stopJobById = async (jobId) => {
  const data = await api.patch(`/${PREFIX}/stop/${jobId}`);

  return { data: data.data };
};
