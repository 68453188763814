import { findAllLanguages } from "@/api/query/language";
import {
  changeWordForNewOne,
  createWordAndSwap,
  findAllTranslationFroWord,
  searchByExactContent,
  searchWordByContent,
} from "@/api/query/word";
import { Color } from "@/common/colors/colors";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { Title } from "@/pages/common/login/loginPage.styles";
import {
  faCheck,
  faQuestion,
  faSearch,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const ListWrapper = styled.div`
  height: 400px;
  overflow-y: auto;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ListElement = styled.div`
  background-color: ${Color.DarkGrey};
  padding: 5px 10px;
  margin: 0 10px;
  border-radius: 10px;
  color: white;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

const OtionalDataWrapper = styled.div``;

const AddFlashcardWord = ({ setClose, edit, handleAddTranlsationFrom }) => {
  const [list, setList] = useState();
  const [languages, setLanguages] = useState([]);
  const [optionalData, setOptionalData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();

  const searchRef = useRef();

  const searchWordByContentMutation = useMutation({
    mutationFn: (value) => searchWordByContent(value),
    onSuccess: ({ data }) => {
      setList(() => data);
    },
  });

  const searchWordByExactContentMutation = useMutation({
    mutationFn: (value) => searchByExactContent(value),
    onSuccess: ({ data }) => {
      setList(() => data);
    },
  });

  const handleSearch = (e) => {
    e && e.preventDefault();
    if (searchRef.current.value.length < 2) {
      return;
    }
    const payload = {};
    payload.content = searchRef.current.value;
    payload.lang = selectedLanguage.value._id;

    searchWordByContentMutation.mutate(payload);
  };

  const handleSearchExisiting = () => {
    const payload = {};
    payload.content = searchRef.current.value;
    payload.lang = selectedLanguage.value._id;

    searchWordByExactContentMutation.mutate(payload);
  };

  const findAllTranslationFroWordMutation = useMutation({
    mutationFn: (value) => findAllTranslationFroWord(value),
    onSuccess: ({ data }) => {
      setOptionalData(data);
    },
  });

  const findLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      const mapedData = data.map((d) => ({ value: d, label: d.name }));

      setLanguages(() => mapedData);
      setSelectedLanguage(() => mapedData.find((md) => md.label === "Polish"));
    },
  });

  useEffect(() => {
    findLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {(findAllTranslationFroWordMutation.isLoading ||
        searchWordByContentMutation.isLoading ||
        searchWordByExactContentMutation.isLoading) && <Loading />}
      <PopUp setClose={setClose}>
        <Title>Add flashcard From</Title>
        <SelectInput
          name="Language"
          options={languages}
          width={100}
          selectWidth={200}
          selected={selectedLanguage}
          setSelected={setSelectedLanguage}
        />
        <form
          style={{ display: "flex", alignItems: "center" }}
          onSubmit={handleSearch}
        >
          <Input
            inputRef={searchRef}
            placeholder="Search Word min 2 chars"
            showLabel={false}
            required
          />
          <CustomSaveButton text={<FontAwesomeIcon icon={faSearch} />} />
          <CustomEditButton
            onClick={() => handleSearchExisiting()}
            text={<FontAwesomeIcon icon={faSearch} />}
          />
        </form>
      </PopUp>
      {list && (
        <PopUp setClose={setList}>
          <Title>
            Pick one word or add
            <div
              style={{ cursor: "pointer", color: "red", fontStyle: "italic" }}
              onClick={() =>
                handleAddTranlsationFrom(
                  searchRef.current.value,
                  selectedLanguage
                )
              }
            >
              {" "}
              {searchRef.current.value}
            </div>
          </Title>
          <OtionalDataWrapper></OtionalDataWrapper>
          <ListWrapper>
            {list.map((d) => (
              <ListElement onClick={() => handleAddTranlsationFrom(d)}>
                {d?.content} {d?.level} {d?.type}{" "}
                {d?.flashcard ? (
                  <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                ) : (
                  <FontAwesomeIcon icon={faX} style={{ color: "red" }} />
                )}
              </ListElement>
            ))}
          </ListWrapper>
        </PopUp>
      )}
    </>
  );
};

export default AddFlashcardWord;
