import { cleanObject } from "@/common/functions/cleanObject";
import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "word";

export const findLanguagesPairTableView = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/find-pair-table-view?${queryString.stringify(
      cleanObject(payload),
      { arrayFormat: "bracket" }
    )}`
  );

  return { data: data.data };
};

export const findWordsForTable = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/find-words-for-table?${queryString.stringify(
      cleanObject(payload),
      { arrayFormat: "bracket" }
    )}`
  );

  return { data: data.data };
};

export const markWordStatusAsUserDecision = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/mark-word-status-as-user-decision/${payload.wordId}`,
    payload
  );

  return { data: data.data };
};

export const updateWordLevel = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/word-level/${payload.wordId}`,
    payload
  );

  return { data: data.data };
};

export const createNewSentenceForToWord = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/create-new-sentence-for-to-word`,
    payload
  );

  return { data: data.data };
};

export const updateWordType = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/word-type/${payload.wordId}`,
    payload
  );

  return { data: data.data };
};

export const updateWord = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);

  return { data: data.data };
};

export const updateWordNote = async (payload) => {
  const data = await api.patch(`/${PREFIX}/word-note`, payload);

  return { data: data.data };
};

export const createWordAndSwap = async (payload) => {
  const data = await api.post(`/${PREFIX}/swap`, payload);

  return { data: data.data };
};

export const createWord = async (payload) => {
  const data = await api.post(`/${PREFIX}/`, payload);

  return { data: data.data };
};

export const createFlashCardWithNewWord = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/create-flash-card-with-new-word`,
    payload
  );
  return { data: data.data };
};

export const createFlashCardWithExistingWord = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/create-flash-card-with-existing-word`,
    payload
  );
  return { data: data.data };
};

export const findAllTranslationFroWord = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/find-all-translation-from-word/${payload.word}/${payload.lang}`
  );

  return { data: data.data };
};

export const handleLoadAvaiableWordsForLesson = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/avaiable-words-for-lesson/${payload._id}`
  );
  return { data: data.data };
};
export const changeWordForNewOne = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/change-word-in-translation`,
    payload
  );

  return { data: data.data };
};

export const addWordToLesson = async (payload) => {
  const data = await api.patch(`/${PREFIX}/add-word-to-lesson`, payload);

  return { data: data.data };
};

export const searchWordByContent = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/search-word-by-content/${payload.content}/${payload.lang}`
  );
  return { data: data.data };
};
export const searchByExactContent = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/search-word-by-exact-content/${payload.content}/${payload.lang}`
  );
  return { data: data.data };
};

export const findAllDataPerWord = async (payload) => {
  const data = await api.get(`/${PREFIX}/all-data-per-word/${payload._id}`);

  return { data: data.data };
};

export const searchWordBySearchBar = async (payload) => {
  const data = await api.get(`/${PREFIX}/searchbar-search/${payload.text}`);

  return { data: data.data };
};
