export const HEADERS = [
  "No.",
  "Job name",
  "Status",
  "Arguments",
  "User",
  "Stop user",
  "Created at",
  "Pending at",
  "Ended at",
  "Error message",
  "Success amount",
  "Error amount",
  "Processed amount",
  "Unprocessed amount",
  "Remove process",
  "File",
];
