import { cleanObject } from "@/common/functions/cleanObject";
import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "category";

export const findAllCategoriesByLanguages = async (payload) => {
  const data = await api.get(
    `/${PREFIX}/?${queryString.stringify(cleanObject(payload), {
      arrayFormat: "bracket",
    })}`
  );

  return { data: data.data };
};

export const findAllCategories = async () => {
  const data = await api.get(`/${PREFIX}/all`);
  return { data: data.data };
};

export const findCategoryById = async (payload) => {
  const data = await api.get(`/${PREFIX}/${payload._id}`);
  return { data: data.data };
};

export const createCategory = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updateCategory = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const changeCateogryPosition = async (payload) => {
  const data = await api.patch(`/${PREFIX}/change-number-of-category`, payload);
  return { data: data.data };
};

export const genereteMultipleTranslations = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/generate-multiple-translations`,
    payload
  );
  return { data: data.data };
};

export const generateAllTranslationsNames = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/generate-all-translation-names`,
    payload
  );
  return { data: data.data };
};

export const deleteCategory = async (payload) => {
  const data = await api.delete(`/${PREFIX}/${payload._id}`);

  return { data: data.data };
};
