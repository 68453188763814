import { loadPairsStats } from "@/api/query/stats";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import DataTable from "../../languagesPairList/components/DataTable";
import styled from "styled-components";
import { Color } from "@/common/colors/colors";

const Title = styled.h2`
  color: ${Color.DarkBlue};
`;

const HEADERS = [
  {
    header: "no.",
    accessorKey: "no",
    size: 20,
  },
  {
    header: "from",
    accessorKey: "from",
    size: 100,
  },
  {
    header: "to",
    accessorKey: "to",
    size: 100,
  },
  {
    header: "word in sentence",
    accessorKey: "check_word_in_sentence",
    size: 100,
  },
  {
    header: "export word",
    accessorKey: "csv_export",
    size: 100,
  },
  {
    header: "export task",
    accessorKey: "csv_export_task",
    size: 100,
  },
  {
    header: "algo match ",
    accessorKey: "algo_creation",
    size: 100,
  },
  {
    header: "Gramar",
    accessorKey: "gramar",
    size: 100,
  },
  {
    header: "Alphabet",
    accessorKey: "alphabet",
    size: 100,
  },
];

const AdminCheckExport = () => {
  const [data, setData] = useState([]);

  const handleLoadDataMutation = useMutation({
    mutationFn: () => loadPairsStats(),
    onSuccess: ({ data }) => {
      setData(() => data);
    },
  });

  useEffect(() => {
    handleLoadDataMutation.mutate();
  }, []);

  return (
    <div>
      <Title>Databse Commands SUM UP</Title>
      <DataTable
        data={data}
        columns={HEADERS}
        rows={[15, 20, 100, 200, 300, 500]}
      />
    </div>
  );
};

export default AdminCheckExport;
