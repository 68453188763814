import PopUp from "@/common/components/PopUp";
import CustomTable from "@/common/components/custom/CustomTable";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import {
  getIconByColor,
  getIconByType,
} from "@/pages/admin/word/AdminWordPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const headers = ["No. ", "Language", " Content", "Sentences", "Actions"];

const Wrapper = styled.div`
  margin: 30px;
  width: 800px;
  max-height: 600px;
  overflow-y: auto;
`;

const SearchResult = ({ data, setData }) => {
  const navigate = useNavigate();

  console.log(data[0]);

  return (
    <PopUp setClose={setData}>
      <Wrapper>
        <CustomTable
          headers={headers}
          data={data.map((d, i) => [
            <div style={{ width: "50px" }}>
              {i + 1}{" "}
              <FontAwesomeIcon
                icon={getIconByType(d.veryfications)}
                style={{
                  color: getIconByColor(d.veryfications),
                }}
              />
            </div>,
            d?.language[0]?.name,
            d.content,
            d.sentences.map((s) => s.content).join(" ,") ||
              "Sorry there is no sentence",
            <div>
              <CustomEditButton
                onClick={() => {
                  navigate(`/admin/word/${d._id}`);
                  setData(null);
                }}
                style={{ width: "100px" }}
                text="To word"
              />
            </div>,
          ])}
        />
      </Wrapper>
    </PopUp>
  );
};

export default SearchResult;
