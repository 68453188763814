import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "user";

export const findUsers = async () => {
  const data = await api.get(`/${PREFIX}`);

  return { data: data.data };
};

export const findUserById = async (_id) => {
  const data = await api.get(`/${PREFIX}/${_id}`);

  return { data: data.data };
};
