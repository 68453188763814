import {
  changeFlashCardSentenceTrnaslationTo,
  findAllSentenceForWord,
  generateAlternatives,
  loadAllAlternativetoSentenceForWordInLang,
} from "@/api/query/sentence";
import { createNewSentenceForToWord, updateWord } from "@/api/query/word";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import { Title } from "@/pages/common/login/loginPage.styles";
import { faAdd, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin: 30px;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const OptionElement = styled.div`
  display: flex;
  gap: 10px;
`;

const CustomButtonAddSentenceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EditSentenceTranslationTo = ({ setClose, edit }) => {
  const [options, setOptions] = useState([]);
  const [allOptionsGenerator, setAllOptionsGenerator] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const sentenceRef = useRef();

  const handleAddAlternativesMutation = useMutation({
    mutationFn: (value) => generateAlternatives(value),
    onSuccess: () => {
      setClose(null);
    },
  });

  const handleAddAlternatives = () => {
    const payload = {};
    payload.flashcard = edit.sth._id;
    payload.translateFrom = edit?.base?._id;
    payload.word = edit?.base?.word;
    payload.currentWord = edit.currentWord._id;

    handleAddAlternativesMutation.mutate(payload);
  };

  const changeFlashCardSentenceTrnaslationMutation = useMutation({
    mutationFn: (value) => changeFlashCardSentenceTrnaslationTo(value),
    onSuccess: () => {
      setClose(null);
    },
  });

  const loadAllSentenceForWord = useMutation({
    mutationFn: (value) => findAllSentenceForWord(value),
    onSuccess: ({ data }) => {
      const filtred = data.filter(
        (d) => d._id !== edit?.sth.sentenceTranslation?.fromSentence?._id
      );

      setAllOptions(filtred);
    },
  });

  const loadAllAlternativeSentenceForWordInLangMutation = useMutation({
    mutationFn: (value) => loadAllAlternativetoSentenceForWordInLang(value),
    onSuccess: ({ data }) => {
      const filtredData = [...data].filter(
        (d) =>
          d.fromSentence._id !==
          edit?.sth?.sentenceTranslation?.fromSentence?._id
      );
      setAllOptionsGenerator(data);
      setOptions(filtredData);
    },
  });

  const createNewSentenceMutation = useMutation({
    mutationFn: (value) => createNewSentenceForToWord(value),
    onSuccess: ({ data }) => {
      setClose(null);
    },
  });

  const handleSave = (picked) => {
    const payload = {};
    payload.flashcard = edit.sth._id;
    payload.translateFrom = edit?.base?._id;
    payload.selected = picked;

    return changeFlashCardSentenceTrnaslationMutation.mutate(payload);
  };

  useEffect(() => {
    loadAllAlternativeSentenceForWordInLangMutation.mutate({
      sentence: edit?.base._id,
      lang: edit?.currentWord.language._id,
    });
    loadAllSentenceForWord.mutate({ _id: edit.currentWord._id });
  }, []);

  const handleAddSentence = () => {
    const value = sentenceRef.current.value;

    if (value?.length === 0) {
      return;
    }

    const payload = {
      flashcard: edit.sth._id,
      sentence: edit?.base._id,
      word: edit?.currentWord._id,
      text: value,
    };

    createNewSentenceMutation.mutate(payload);
  };

  return (
    <>
      {(loadAllAlternativeSentenceForWordInLangMutation.isLoading ||
        changeFlashCardSentenceTrnaslationMutation.isLoading ||
        handleAddAlternativesMutation.isLoading) && <Loading />}
      <PopUp setClose={setClose}>
        <Wrapper>
          <Title>Sentence Translation to Picker </Title>
          <Title style={{ fontSize: "20px", gap: "10px" }}>
            <div>Current sentence: </div>
            {(edit?.base?._id ===
              edit?.sth.sentenceTranslation?.toSentence?._id && (
              <>
                <div>
                  {edit?.sth.sentenceTranslation?.fromSentence?.content}
                </div>
                <div>
                  {edit?.sth.sentenceTranslation?.sources
                    ?.map((d) => d.sourceType)
                    ?.join(" ,")}
                </div>
                {Math.round(
                  (edit?.sth?.sentenceTranslation?.reliability || 0) * 100
                ) / 100}{" "}
                %
              </>
            )) ||
              "------"}
          </Title>
          <Title>
            Alternatives
            {allOptionsGenerator.length === 0 && (
              <FontAwesomeIcon
                icon={faAdd}
                style={{ cursor: "pointer" }}
                onClick={handleAddAlternatives}
              />
            )}
          </Title>
          <OptionsWrapper>
            {options.map((d, i) => (
              <OptionElement>
                {i + 1}. {d.fromSentence?.content}{" "}
                {Math.round(d.reliability * 100) / 100} %
                <div>{d.sources.map((d) => d.sourceType).join(" ,")}</div>
                <FontAwesomeIcon
                  icon={faCheck}
                  onClick={() => handleSave(d.fromSentence._id)}
                  style={{ cursor: "pointer" }}
                />
              </OptionElement>
            ))}
          </OptionsWrapper>
          <Title>All</Title>
          <OptionsWrapper>
            {allOptions.map((d, i) => (
              <OptionElement>
                {i + 1}. {d.content}{" "}
                <FontAwesomeIcon
                  icon={faCheck}
                  onClick={() => handleSave(d._id)}
                  style={{ cursor: "pointer" }}
                />
              </OptionElement>
            ))}
          </OptionsWrapper>
          <CustomButtonAddSentenceWrapper>
            <Input inputRef={sentenceRef} showLabel={false} />
            <CustomAddButton onClick={() => handleAddSentence()} />
          </CustomButtonAddSentenceWrapper>
        </Wrapper>
      </PopUp>
    </>
  );
};

export default EditSentenceTranslationTo;
