import Api from "../api";
const { axios: api } = Api;

const PREFIX = "aditional-page";

export const createAditionalPage = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);

  return { data: data.data };
};

export const getAllAditionalPageByCoreAndType = async (coreLang, type) => {
  const data = await api.get(`/${PREFIX}/by-language-from/${coreLang}/${type}`);

  return { data: data.data };
};

export const updateAditionalPage = async (aditionalPageId, payload) => {
  const data = await api.patch(`/${PREFIX}/${aditionalPageId}`, payload);

  return { data: data.data };
};
