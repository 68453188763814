import { Color } from "@/common/colors/colors";

export const getStatusColor = (status) => {
  switch (status) {
    case "correct":
      return Color.green;
    case "question":
      return Color.orange;
    case "wrong":
      return Color.red;
    default:
      return "";
  }
};
