import { createLevel, updateLevel } from "@/api/query/level";
import { Color } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import SelectInput from "@/common/components/custom/SelectInput";
import { LESSON_LEVEL } from "@/common/constants/level.constants";
import { getToSelectData } from "@/common/functions/getToSelectData";
import { SubmitButton, Title } from "@/pages/common/login/loginPage.styles";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
`;

const AdminLevelEdit = ({ setClose, edit, languages }) => {
  const defaultNameRef = useRef();

  const [selectedLanguage, setSelectedLanguage] = useState(
    languages?.find((l) => l.value._id === edit.language)
  );
  const [selectedLevel, setSelectedLevel] = useState(
    getToSelectData(LESSON_LEVEL).find((l) => l.value == edit.level)
  );

  const createLevelMutation = useMutation({
    mutationFn: (value) => createLevel(value),
    onSuccess: ({ data }) => {
      setClose(null);
    },
  });

  const updateLevelMutation = useMutation({
    mutationFn: (value) => updateLevel(value),
    onSuccess: ({ data }) => {
      setClose(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    if (!selectedLanguage?.value || !selectedLevel?.value) return;

    const payload = edit;

    payload.defualtName = defaultNameRef.current.value;
    payload.languageId = selectedLanguage.value._id;
    payload.level = selectedLevel.value;

    if (payload._id) {
      return updateLevelMutation.mutate(payload);
    }

    return createLevelMutation.mutate(payload);
  };

  return (
    <PopUp setClose={setClose}>
      <Title>{(edit?._id && "Edit") || "Create"} Level</Title>
      <form onSubmit={handleSave}>
        <SelectInput
          width={140}
          name="Language"
          color={Color.DarkBlue}
          selectWidth={225}
          options={languages}
          selected={selectedLanguage}
          setSelected={setSelectedLanguage}
          requiredSign
        />
        <SelectInput
          width={140}
          name="Level"
          color={Color.DarkBlue}
          selectWidth={225}
          options={getToSelectData(LESSON_LEVEL)}
          selected={selectedLevel}
          setSelected={setSelectedLevel}
          requiredSign
        />
        <Input
          inputRef={defaultNameRef}
          width={140}
          name="Default Name"
          color={Color.DarkBlue}
          inputWidth={200}
          required
          requiredSign
          value={edit?.defualtName}
        />
        <ButtonWrapper>
          <SubmitButton type="submit" style={{ width: "100px" }}>
            Save
          </SubmitButton>
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default AdminLevelEdit;
