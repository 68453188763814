import { findAllLanguages } from "@/api/query/language";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { MessageContext } from "@/common/contexts/message.context";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { ButtonWrapper, Title } from "../../AdminCommandPage.styled";
import { exportDB } from "@/api/query/integration";
import { exportToCsv } from "@/common/functions/exportToCSV";
import { createJobProcess } from "@/api/query/jobProcess";
import { JOB_NAME } from "../../constants/jobs";
import Input from "@/common/components/custom/Input";

const ExportDatabaseToCSV = ({ setClose, reloadMutation }) => {
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [selectedLanguageFrom, setSelectedLanguageFrom] = useState();
  const [selectedLanguageTo, setSelectedLanguageTo] = useState();

  const thresholdRef = useRef();

  const { addMessage } = useContext(MessageContext);

  const loadLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setLanguagesOptions(() => data.map((d) => ({ label: d.name, value: d })));
    },
  });

  const loadData = () => {
    loadLanguagesMutation.mutate();
  };

  const createJobProcessMutation = useMutation({
    mutationFn: (values) => createJobProcess(values),
    onSuccess: () => {
      addMessage("Added job", "success");
      setClose((prev) => !prev);
      reloadMutation.mutate();
    },
    onError: () => {
      addMessage("Something went wrong", "error");
    },
  });

  const handleExportDB = () => {
    if (!selectedLanguageTo || !selectedLanguageFrom)
      return addMessage("Select languages", "error");

    const jobName = JOB_NAME.CSV_EXPORT;
    const argumentsPassed = {
      languageFrom: selectedLanguageFrom.value.shortName,
      languageTo: selectedLanguageTo.value.shortName,
      threshold: thresholdRef.current.value,
    };

    const payload = {};

    payload.job_name = jobName;
    payload.arguments_passed = JSON.stringify(argumentsPassed);

    createJobProcessMutation.mutate(payload);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {createJobProcessMutation.isLoading && <Loading />}
      <PopUp setClose={setClose}>
        <Title>Export csv db</Title>
        <SelectInput
          width={150}
          name={"Language from"}
          options={languagesOptions}
          selected={selectedLanguageFrom}
          setSelected={setSelectedLanguageFrom}
        />
        <SelectInput
          width={150}
          name={"Language to"}
          options={languagesOptions}
          selected={selectedLanguageTo}
          setSelected={setSelectedLanguageTo}
        />
        <Input
          inputRef={thresholdRef}
          type="number"
          name="Threshold"
          min={1}
          max={100}
          width={150}
          inputWidth={230}
          value={75}
        />
        <ButtonWrapper>
          <CustomEditButton text="Export" onClick={() => handleExportDB()} />
        </ButtonWrapper>
      </PopUp>
    </>
  );
};

export default ExportDatabaseToCSV;
