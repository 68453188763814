import { findAllLanguages } from "@/api/query/language";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/custom/SelectInput";
import TextArea from "@/common/components/custom/TextArea";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { Title } from "@/pages/common/login/loginPage.styles";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 400px;
`;

const AdminAddTask = ({ setClose, edit, saveTasks, tasks }) => {
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState();

  const taskPlaceholderRef = useRef();
  const taskExampleRef = useRef();
  const taskContentRef = useRef();

  const handleLoadLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      const mapedLanguages = data.map((d) => ({ label: d.name, value: d }));
      setLanguages(() => mapedLanguages);

      if (edit.language) {
        const currentLang = mapedLanguages.find(
          (d) => d.value._id === edit.language
        );
        setLanguage(currentLang);
      }
    },
  });

  const handleSaveTask = (e) => {
    e?.preventDefault();

    if (!language?.value) {
      return;
    }

    const payload = {};
    payload.taskPlaceholder = taskPlaceholderRef.current.value;
    payload.taskExample = taskExampleRef.current.value;
    payload.taskContent = taskContentRef.current.value;
    payload.language = language.value._id;
    payload.id = edit.id;

    if (!edit.id) {
      payload.id = tasks.length + 1;
    }

    saveTasks((prev) => [...prev?.filter((p) => p.id !== payload.id), payload]);
    setClose(null);
  };

  useEffect(() => {
    handleLoadLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {handleLoadLanguagesMutation.isLoading && <Loading />}
      <PopUp setClose={setClose}>
        <Title>Add task to lesson:</Title>
        <form onSubmit={handleSaveTask}>
          <SelectInput
            options={languages}
            setSelected={setLanguage}
            selected={language}
            name={"Language"}
            width={140}
          />
          <TextArea
            textAreaRef={taskPlaceholderRef}
            label="Task Placeholder"
            width="180"
            defaultValue={edit?.taskPlaceholder}
            required
          />
          <TextArea
            textAreaRef={taskExampleRef}
            label="Task Example"
            width="180"
            defaultValue={edit?.taskExample}
            required
          />
          <TextArea
            textAreaRef={taskContentRef}
            label="Task Content"
            width="180"
            defaultValue={edit?.taskContent}
            required
          />
          <ButtonWrapper>
            <CustomSaveButton text="Save task" />
          </ButtonWrapper>
        </form>
      </PopUp>
    </>
  );
};

export default AdminAddTask;
