import React from "react";
import { styled } from "styled-components";
import { Color } from "@/common/colors/colors";
import SelectInput from "@/common/components/custom/SelectInput";

const SelectBarContainer = styled.div`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  box-shadow: 0px 2px 10px ${Color.Grey};
  width: fit-content;
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  background-color: ${Color.DarkBlue};
  color: ${Color.White};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const SearchBar = ({
  options,
  selectedCoreOption,
  setSelectedCoreOption,
  setSelectedTranslationOptions,
  selectedTranslationOptions,
  handleSearch,
}) => {
  return (
    <SelectBarContainer>
      <SelectInput
        width={100}
        name="Core lang"
        color={Color.DarkBlue}
        selectWidth={200}
        options={options}
        selected={selectedCoreOption}
        setSelected={setSelectedCoreOption}
      />
      <SelectInput
        width={160}
        name="Translation lang"
        color={Color.DarkBlue}
        selectWidth={200}
        options={options}
        selected={selectedTranslationOptions}
        setSelected={setSelectedTranslationOptions}
      />
      <SearchButton onClick={handleSearch}>Search</SearchButton>
    </SelectBarContainer>
  );
};

export default SearchBar;
