import {
  changeFlashCardSentenceTrnaslation,
  createSentence,
  findAllSentenceForWord,
  loadAllAlternativeSentenceForWordInLang,
  updateSentence,
} from "@/api/query/sentence";
import { createNewSentenceForToWord, updateWord } from "@/api/query/word";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import SelectInput from "@/common/components/custom/SelectInput";
import TextArea from "@/common/components/custom/TextArea";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { Title } from "@/pages/common/login/loginPage.styles";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
  padding: 30px;
`;

const CustomButtonSubmitWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 20px 0 -20px 0;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const OptionElement = styled.div`
  display: flex;
  gap: 10px;
`;

const CustomButtonAddSentenceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EditSentenceTranslation = ({ setClose, edit }) => {
  const [options, setOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const sentenceRef = useRef();

  const changeFlashCardSentenceTrnaslationMutation = useMutation({
    mutationFn: (value) => changeFlashCardSentenceTrnaslation(value),
    onSuccess: () => {
      setClose(null);
    },
  });

  const loadAllSentenceForWord = useMutation({
    mutationFn: (value) => findAllSentenceForWord(value),
    onSuccess: ({ data }) => {
      const filtred = data.filter(
        (d) => d._id !== edit?.sth.sentenceTranslation?.toSentence?._id
      );

      setAllOptions(filtred);
    },
  });

  const loadAllAlternativeSentenceForWordInLangMutation = useMutation({
    mutationFn: (value) => loadAllAlternativeSentenceForWordInLang(value),
    onSuccess: ({ data }) => {
      const filtredData = data.filter(
        (d) =>
          d.toSentence._id !== edit?.sth?.sentenceTranslation?.toSentence?._id
      );
      setOptions(filtredData);
    },
  });

  const createNewSentenceMutation = useMutation({
    mutationFn: (value) => createNewSentenceForToWord(value),
    onSuccess: ({ data }) => {
      setClose(null);
    },
  });

  const handleSave = (picked) => {
    const payload = {};
    payload.flashcard = edit.sth._id;
    payload.translateFrom = edit?.base?._id;
    payload.selected = picked;

    return changeFlashCardSentenceTrnaslationMutation.mutate(payload);
  };

  useEffect(() => {
    loadAllAlternativeSentenceForWordInLangMutation.mutate({
      sentence: edit?.base._id,
      lang: edit?.currentWord.language._id,
    });
    loadAllSentenceForWord.mutate({ _id: edit.currentWord._id });
  }, []);

  const handleAddSentence = () => {
    const value = sentenceRef.current.value;

    if (value?.length === 0) {
      return;
    }

    const payload = {
      flashcard: edit.sth._id,
      sentence: edit?.base._id,
      word: edit?.currentWord._id,
      text: value,
    };

    createNewSentenceMutation.mutate(payload);
  };

  return (
    <>
      {(loadAllAlternativeSentenceForWordInLangMutation.isLoading ||
        changeFlashCardSentenceTrnaslationMutation.isLoading) && <Loading />}
      <PopUp setClose={setClose}>
        <Wrapper>
          <Title>Sentence Translation Picker</Title>
          <Title style={{ fontSize: "20px", gap: "10px" }}>
            <div>
              <div>Core sentence: </div>
            </div>
            {edit?.base?.content}
            <br></br>
          </Title>
          <Title style={{ fontSize: "20px", gap: "10px" }}>
            {(edit?.base?._id ===
              edit?.sth.sentenceTranslation?.fromSentence?._id && (
              <div style={{ minWidth: "600px" }}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <p style={{ margin: 0, width: "200px" }}>Sentence:</p>
                  {edit?.sth.sentenceTranslation?.toSentence?.content}{" "}
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <p style={{ margin: 0, width: "200px" }}>Reliability:</p>
                  {Math.round(
                    (edit?.sth?.sentenceTranslation?.reliability || 0) * 100
                  ) / 100}{" "}
                  %
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <p style={{ margin: 0, width: "200px" }}>
                    Translator source:
                  </p>
                  {edit?.sth.sentenceTranslation?.sources
                    ?.map((d) => d.sourceType)
                    ?.join(" ,")}
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <p style={{ margin: 0, width: "200px" }}>
                    Linked lines source:
                  </p>
                  {(edit?.sth?.sentenceTranslation?.linkedLineReliability &&
                    `${
                      Math.round(
                        edit?.sth?.sentenceTranslation?.linkedLineReliability *
                          100
                      ) / 100
                    } %`) ||
                    "-----"}
                  <div style={{ maxWidth: "800px" }}>
                    {edit?.sth?.sentenceTranslation?.linkedLine?.join(" ")}
                  </div>
                </div>
              </div>
            )) ||
              "------"}
          </Title>
          <Title>
            Alternatives (translators max{" "}
            {Math.round(
              (edit?.sth?.sentenceTranslation?.translatorMaxRealiabilty || 95) *
                100
            ) / 100}
            {" %"})
          </Title>
          <OptionsWrapper>
            {options.map((d, i) => (
              <OptionElement>
                {i + 1}. {d?.toSentence?.content}{" "}
                {Math.round(d?.reliability * 100) / 100} %{" "}
                {d?.sources?.map((a) => a.sourceType).join(" ")}{" "}
                {(d?.linkedLineReliability &&
                  `${Math.round(d?.linkedLineReliability * 100) / 100} % `) ||
                  "-----"}
                <div style={{ maxWidth: "400px" }}>
                  {d?.linkedLine?.join(" ")}
                </div>
                <FontAwesomeIcon
                  icon={faCheck}
                  onClick={() => handleSave(d.toSentence._id)}
                  style={{ cursor: "pointer" }}
                />
              </OptionElement>
            ))}
          </OptionsWrapper>
          <Title>All</Title>
          <OptionsWrapper>
            {allOptions.map((d, i) => (
              <OptionElement>
                {i + 1}. {d.content}{" "}
                <FontAwesomeIcon
                  icon={faCheck}
                  onClick={() => handleSave(d._id)}
                  style={{ cursor: "pointer" }}
                />
              </OptionElement>
            ))}
          </OptionsWrapper>
          <CustomButtonAddSentenceWrapper>
            <Input inputRef={sentenceRef} showLabel={false} />
            <CustomAddButton onClick={() => handleAddSentence()} />
          </CustomButtonAddSentenceWrapper>
        </Wrapper>
      </PopUp>
    </>
  );
};

export default EditSentenceTranslation;
