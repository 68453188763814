import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import AdminEdit from "./components/AdminEdit";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import { findUsers } from "@/api/query/user";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import {
  faAdd,
  faCheck,
  faEdit,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "@/common/components/Loading";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HEADERS = [
  "No.",
  "Name",
  "Surname",
  "Email",
  "Role",
  "Active",
  "Actions",
];

const AdminUserPage = () => {
  const [editAdmin, setEditAdmin] = useState();
  const [tableData, setTableData] = useState();

  const findUsersMutation = useMutation({
    mutationFn: () => findUsers(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data?.map((d, i) => [
          i + 1,
          d.name,
          d.surname,
          d.email,
          d.__t,
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {d.active ? (
              <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
            ) : (
              <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
            )}
          </div>,
          <div style={{ display: "flex", gap: "10px" }}>
            <CustomEditButton
              onClick={(e) => setEditAdmin(d)}
              text={
                <FontAwesomeIcon icon={faEdit} style={{ color: "white" }} />
              }
            />
          </div>,
        ])
      );
    },
  });

  useEffect(() => {
    if (!editAdmin) {
      findUsersMutation.mutate();
    }
  }, [editAdmin]);

  return (
    <>
      {findUsersMutation.isLoading && <Loading />}
      <Wrapper>
        {editAdmin && <AdminEdit edit={editAdmin} setEdit={setEditAdmin} />}
        <TopContent>
          <CustomAddButton
            onClick={() => setEditAdmin({})}
            text={<FontAwesomeIcon icon={faAdd} style={{ color: "white" }} />}
          />
        </TopContent>
        <CustomTable headers={HEADERS} data={tableData} />
      </Wrapper>{" "}
    </>
  );
};

export default AdminUserPage;
