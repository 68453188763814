import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getRaws = (translators, languagesOptions, setEdit) =>
  translators.map((translator, i) => [
    i + 1,
    translator.name,
    translator.source,
    translator.languages_available
      .map(
        (lang) =>
          languagesOptions.find((option) => option.value._id === lang)?.value
            .shortName
      )
      .join(", "),
    <CustomEditButton
      onClick={() => setEdit(translator)}
      text={<FontAwesomeIcon icon={faEdit} style={{ color: "white" }} />}
    />,
  ]);
