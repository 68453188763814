import { findAllLanguages } from "@/api/query/language";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { MessageContext } from "@/common/contexts/message.context";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ButtonWrapper, Title } from "../../AdminCommandPage.styled";
import { createJobProcess } from "@/api/query/jobProcess";
import { JOB_NAME } from "../../constants/jobs";
import { getAllTranslators } from "@/api/query/translator";

const ShowAlgo = ({ setClose, reloadMutation }) => {
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [selectedLanguageFrom, setSelectedLanguageFrom] = useState();
  const [selectedLanguageTo, setSelectedLanguageTo] = useState();
  const [selectedByLanguageFrom, setSelectedByLanguageFrom] = useState();
  const [selectedByLanguageTo, setSelectedByLanguageTo] = useState();
  const [selectedTranslators, setSelectedTranslators] = useState();
  const [translatorsOptions, setTranslatorsOptions] = useState();

  const { addMessage } = useContext(MessageContext);

  const loadLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setLanguagesOptions(() => data.map((d) => ({ label: d.name, value: d })));
    },
  });

  const loadData = () => {
    loadLanguagesMutation.mutate();
    loadTranslators.mutate();
  };

  const createJobProcessMutation = useMutation({
    mutationFn: (values) => createJobProcess(values),
    onSuccess: () => {
      addMessage("Added job", "success");
      setClose((prev) => !prev);
      reloadMutation.mutate();
    },
    onError: () => {
      addMessage("Something went wrong", "error");
    },
  });

  const loadTranslators = useMutation({
    mutationFn: () => getAllTranslators(),
    onSuccess: ({ data }) => {
      setTranslatorsOptions(() =>
        data.map((d) => ({ label: d.name, value: d }))
      );
    },
  });

  const handleExportDB = () => {
    if (!selectedLanguageTo || !selectedLanguageFrom)
      return addMessage("Select languages", "error");

    const jobName = JOB_NAME.ALGO_CREATION;
    const argumentsPassed = {
      languageFrom: selectedLanguageFrom.value.shortName,
      languageTo: selectedLanguageTo.value.shortName,
      languageByFrom: selectedByLanguageFrom?.value?.shortName,
      languageByTo: selectedByLanguageTo?.value?.shortName,
      translators: selectedTranslators.map((t) => t.value.source),
    };

    const payload = {};
    payload.job_name = jobName;
    payload.arguments_passed = JSON.stringify(argumentsPassed);

    createJobProcessMutation.mutate(payload);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {createJobProcessMutation.isLoading && <Loading />}
      <PopUp setClose={setClose}>
        <Title>Show Algo {`( 1 -> 3 <- 4 -> 2 )`}</Title>
        <SelectInput
          width={250}
          name={"1. Language source ( core )"}
          options={languagesOptions}
          selected={selectedLanguageFrom}
          setSelected={setSelectedLanguageFrom}
        />
        <SelectInput
          width={250}
          name={"2. Language final ( to )"}
          options={languagesOptions}
          selected={selectedLanguageTo}
          setSelected={setSelectedLanguageTo}
        />
        <SelectInput
          width={250}
          name={"3. By Language from"}
          options={languagesOptions}
          selected={selectedByLanguageFrom}
          setSelected={setSelectedByLanguageFrom}
        />
        <SelectInput
          width={250}
          name={"4. By Language to"}
          options={languagesOptions}
          selected={selectedByLanguageTo}
          setSelected={setSelectedByLanguageTo}
        />
        <SelectInput
          multiple
          width={250}
          name={"Translators"}
          options={translatorsOptions}
          selected={selectedTranslators}
          setSelected={setSelectedTranslators}
        />
        <ButtonWrapper>
          <CustomEditButton text="Export" onClick={() => handleExportDB()} />
        </ButtonWrapper>
      </PopUp>
    </>
  );
};

export default ShowAlgo;
