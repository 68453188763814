import { findAllLanguages } from "@/api/query/language";
import { genereteMultipleTranslations } from "@/api/query/translation";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";

const AddTranslationsByTranslators = ({ setClose, data }) => {
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const handleAddTranslationsLanugages = useMutation({
    mutationFn: (payload) => genereteMultipleTranslations(payload),
    onSuccess: () => setClose(() => null),
  });

  const handleGetLanugages = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setLanguages(() =>
        data.map((d) => ({ label: d.name, value: d.shortName }))
      );
    },
  });

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (selectedLanguages.length === 0) {
      return;
    }

    const payload = {
      languages: selectedLanguages.map((l) => l.value),
      wordFrom: data.mainData._id,
    };

    handleAddTranslationsLanugages.mutate(payload);
  };

  useEffect(() => {
    handleGetLanugages.mutate();
  }, []);

  return (
    <>
      {(handleGetLanugages.isLoading ||
        handleAddTranslationsLanugages.isLoading) && <Loading />}
      <PopUp setClose={setClose}>
        <h3>Lang words generator (translators ) </h3>
        <form onSubmit={handleSubmit}>
          <SelectInput
            multiple={true}
            name={"Languages"}
            width={100}
            selectWidth={500}
            options={languages}
            selected={selectedLanguages}
            setSelected={setSelectedLanguages}
          />
          <CustomSaveButton text="Generate" />
        </form>
      </PopUp>
    </>
  );
};

export default AddTranslationsByTranslators;
