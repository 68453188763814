import { createLevel, updateLevel } from "@/api/query/level";
import { Color } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import CustomTable from "@/common/components/custom/CustomTable";
import Input from "@/common/components/custom/Input";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { LESSON_LEVEL } from "@/common/constants/level.constants";
import { getToSelectData } from "@/common/functions/getToSelectData";
import {
  TopWrapper,
  Wrapper,
} from "@/common/layout/adminLayout/adminLayout.styles";
import { SubmitButton, Title } from "@/pages/common/login/loginPage.styles";
import { faAd, faAdd, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import AdminEditCategoryTranslation from "./AdminEditCategoryTranslation";
import HelpTable from "./HelpTable";
import { createCategory, updateCategory } from "@/api/query/category";
import { getBase64 } from "@/common/functions/getBase64";
import Loading from "@/common/components/Loading";

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
`;

const ImageWrapper = styled.div`
  width: 200px;
`;

const TableWrapper = styled.div`
  height: 200px;
  overflow: auto;
`;

const DisplayFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const headers = ["No.", "Text", "Language", "Actions"];

const AdminCateogryEdit = ({
  setClose,
  edit,
  levels,
  languages,
  currentSelectedLevel,
}) => {
  const numberRef = useRef();
  const [editTranslation, setEditTranslation] = useState();
  const imageRef = useRef();

  const [selectedLevel, setSelectedLevel] = useState(
    levels?.find((l) => l.value._id === currentSelectedLevel._id)
  );
  const [tableData, setTableData] = useState(edit?.category_names || []);

  const createCategoryMutation = useMutation({
    mutationFn: (value) => createCategory(value),
    onSuccess: async ({ data }) => {
      if (imageRef.current?.files[0]) {
        edit.image = await getBase64(imageRef.current?.files[0]);
      }
      setClose(null);
    },
  });

  const updateCategoryMutation = useMutation({
    mutationFn: (value) => updateCategory(value),
    onSuccess: async ({ data }) => {
      if (imageRef.current?.files[0]) {
        edit.image = await getBase64(imageRef.current?.files[0]);
      }
      setClose(null);
    },
  });

  const handleSave = async (e) => {
    e && e.preventDefault();

    if (!selectedLevel?.value) return;

    const payload = {};

    payload.level = selectedLevel.value._id;
    payload.category_names = tableData.map((td) => ({
      text: td.text,
      language: td.language,
    }));

    if (imageRef.current?.files[0]) {
      payload.image = await getBase64(imageRef.current?.files[0]);
    }

    if (edit._id) {
      payload._id = edit._id;
      payload.number = numberRef.current.value;

      return updateCategoryMutation.mutate(payload);
    }

    return createCategoryMutation.mutate(payload);
  };

  const handleDownloadImage = (base64) => {
    var a = document.createElement("a");
    a.href = base64;
    a.download = "Image.png";
    a.click();
  };

  return (
    <>
      {(updateCategoryMutation.isLoading ||
        createCategoryMutation.isLoading) && <Loading />}
      <PopUp setClose={setClose}>
        <Title>{(edit?._id && "Edit") || "Create"} Category</Title>
        <form onSubmit={handleSave}>
          <DisplayFlex>
            <div>
              <SelectInput
                width={140}
                name="Level"
                color={Color.DarkBlue}
                selectWidth={225}
                options={levels}
                selected={selectedLevel}
                setSelected={setSelectedLevel}
                requiredSign
                disabled
              />
              <Input
                inputRef={numberRef}
                width={140}
                type="number"
                name="Number"
                color={Color.DarkBlue}
                inputWidth={200}
                required
                requiredSign
                value={edit?.number}
                disabled
              />
              <DisplayFlex>
                <Input
                  inputRef={imageRef}
                  type="file"
                  showLabel={false}
                  inputWidth={160}
                  accept={"image/png, image/gif, image/jpeg"}
                />
                {edit?.image?.length > 0 && (
                  <CustomEditButton
                    text="Download IMG"
                    onClick={() => handleDownloadImage(edit?.image[0]?.base64)}
                  />
                )}
              </DisplayFlex>
            </div>
            <div>
              <ImageWrapper>
                <img
                  src={edit?.image?.length > 0 && edit?.image[0]?.base64}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "10px",
                  }}
                />
              </ImageWrapper>
            </div>{" "}
          </DisplayFlex>
          <TopWrapper>
            <CustomEditButton
              text={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => setEditTranslation({})}
            />
          </TopWrapper>
          <TableWrapper>
            <HelpTable
              data={tableData}
              headers={headers}
              setEditTranslation={setEditTranslation}
              languages={languages}
            />
          </TableWrapper>

          <ButtonWrapper>
            <SubmitButton type="submit" style={{ width: "100px" }}>
              Save
            </SubmitButton>
          </ButtonWrapper>
        </form>
      </PopUp>
      {editTranslation && (
        <AdminEditCategoryTranslation
          languages={languages}
          edit={editTranslation}
          setClose={setEditTranslation}
          setTranslations={setTableData}
        />
      )}
    </>
  );
};

export default AdminCateogryEdit;
