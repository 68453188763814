import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #212f46; /* DarkBlue */
`;

export const LoginForm = styled.form`
  background-color: #f3f5f6; /* White */
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
`;

export const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #212f46; /* DarkBlue */
  display: flex;
  gap: 10px;
`;

export const InputField = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  position: relative;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  padding: 10px;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  flex: 1;
  padding: 10px;
  border-left: none;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  width: 200px;
  transition: border-color 0.3s ease-in-out; /* Dodaliśmy animację */

  &:focus {
    border-color: #212f46; /* DarkBlue */
  }
`;

export const ShowPasswordButton = styled.button`
  position: absolute;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
`;

export const SubmitButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #212f46; /* DarkBlue */
  color: #f3f5f6; /* White */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: 220px;
  font-weight: bold;

  &:hover {
    background-color: #182539;
  }
`;
