import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SearchBar from "./components/SearchBar";
import { useMutation } from "react-query";
import { findAllLanguages } from "@/api/query/language";
import { getToSelectData } from "@/common/functions/getToSelectData";
import { WORD_LEVEL } from "@/common/constants/level.constants";
import { WORD_TYPE } from "@/common/constants/wordType.constants";
import {
  findWordsForTable,
  markWordStatusAsUserDecision,
} from "@/api/query/word";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCheck,
  faEdit,
  faQuestion,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { Color } from "@/common/colors/colors";
import DataTable from "./components/DataTable";
import { TableWrapper } from "../languagesPairList/LanguagesPairListPage";
import Loading from "@/common/components/Loading";
import { VeryficationType } from "@/common/constants/verification.status.constants";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import AdminEdit from "./components/AdminEdit";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 -30px;
  gap: 30px;
`;

const IconsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const TopContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
`;

const AdminWordListPage = () => {
  const [addWord, setAddWord] = useState();
  const [options, setOptions] = useState([]);
  const [selectedLang, setSelectedLang] = useState();
  const [selectedType, setSelectedType] = useState(getToSelectData(WORD_TYPE));
  const [selectedLevel, setSelectedLevel] = useState(
    getToSelectData(WORD_LEVEL)
  );
  const [data, setData] = useState([]);
  const checkIfIncludeSentence = useRef();

  const findWordsForTableMutation = useMutation({
    mutationFn: (payload) => findWordsForTable(payload),
    onSuccess: ({ data }) => {
      const rows = data.map((r, i) => ({
        no: i + 1 + ".",
        content: r.content,
        level: r.level,
        type: r.type,
        comment: r.comment || "----",
        sentences: r.sentences?.map((s) => s.content).join(" ,") || "-------",
        actions: r._id,
        error: r.errors?.join(" ,") || "---",
        status: r?.veryfications
          ?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          ?.shift()?.verificationStatus,
      }));

      setData(rows);
    },
  });

  const markOrderWordWithDecisionMutation = useMutation({
    mutationFn: (value) => markWordStatusAsUserDecision(value),
    onSuccess: () => {
      handleSearch();
    },
  });

  const handleMarkWordWithDecision = (decision, wordId) => {
    const payload = {
      decision,
      wordId,
    };

    markOrderWordWithDecisionMutation.mutate(payload);
  };

  const getCurrentStatusIcons = (status) => {
    switch (status) {
      case VeryficationType.CORRECT:
        return <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />;
      case VeryficationType.QUESTION:
        return (
          <FontAwesomeIcon icon={faQuestion} style={{ color: "orange" }} />
        );
      case VeryficationType.WRONG:
        return <FontAwesomeIcon icon={faX} style={{ color: "red" }} />;
      default:
        return;
    }
  };

  const HEADERS = [
    {
      header: "No.",
      accessorKey: "no",
      size: 50,
    },
    {
      header: "Status",
      accessorKey: "status",
      size: 50,
      cell: (info) => (
        <IconsWrapper>{getCurrentStatusIcons(info.getValue())}</IconsWrapper>
      ),
    },
    {
      header: "Content",
      accessorKey: "content",
      size: 200,
    },
    {
      header: "Level",
      accessorKey: "level",
      size: 50,
    },
    {
      header: "Type",
      accessorKey: "type",
      size: 150,
    },
    {
      header: "Errors",
      accessorKey: "error",
      size: 150,
    },
    {
      header: "Comment",
      accessorKey: "comment",
      size: 150,
    },
    {
      header: "Sentences",
      accessorKey: "sentences",
      size: 300,
    },
    {
      header: "Actions",
      accessorKey: "actions",
      size: 100,
      cell: (info) => (
        <IconsWrapper>
          <Link to={`/admin/word/${info.getValue()}`}>
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: Color.DarkBlue, cursor: "pointer" }}
            />{" "}
          </Link>
          <FontAwesomeIcon
            icon={faCheck}
            style={{ color: "green", cursor: "pointer" }}
            onClick={() =>
              handleMarkWordWithDecision(
                VeryficationType.CORRECT,
                info.getValue()
              )
            }
          />{" "}
          <FontAwesomeIcon
            icon={faQuestion}
            style={{ color: "orange", cursor: "pointer" }}
            onClick={() =>
              handleMarkWordWithDecision(
                VeryficationType.QUESTION,
                info.getValue()
              )
            }
          />{" "}
          <FontAwesomeIcon
            icon={faX}
            style={{ color: "red", cursor: "pointer" }}
            onClick={() =>
              handleMarkWordWithDecision(
                VeryficationType.WRONG,
                info.getValue()
              )
            }
          />{" "}
        </IconsWrapper>
      ),
    },
  ];

  const findLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      const mapedData = data.map((d) => ({ label: d.name, value: d._id }));

      setOptions(() => mapedData);

      const english = mapedData.find((d) => d.label === "English");
      setSelectedLang(() => english);
    },
  });

  const handleSearch = () => {
    const payload = {};
    payload.language = selectedLang.value;
    payload.levels = selectedLevel.map((sl) => sl.value);
    payload.types = selectedType.map((sl) => sl.value);
    payload.checkIfIncludeSentence = !!checkIfIncludeSentence.current.checked;

    findWordsForTableMutation.mutate(payload);
  };

  useEffect(() => {
    findLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {(markOrderWordWithDecisionMutation.isLoading ||
        findLanguagesMutation.isLoading ||
        findWordsForTableMutation.isLoading) && <Loading />}
      {addWord && (
        <AdminEdit edit={addWord} setEdit={setAddWord} languages={options} />
      )}
      <Wrapper>
        <SearchBar
          options={options}
          selectedLang={selectedLang}
          setSelectedLang={setSelectedLang}
          setSelectedType={setSelectedType}
          selectedType={selectedType}
          selectedLevel={selectedLevel}
          setSelectedLevel={setSelectedLevel}
          handleSearch={handleSearch}
          checkIfIncludeSentence={checkIfIncludeSentence}
        />
        <TopContent>
          <CustomAddButton
            onClick={() => setAddWord({})}
            text={<FontAwesomeIcon icon={faAdd} style={{ color: "white" }} />}
          />
        </TopContent>
        <TableWrapper>
          <DataTable
            data={data}
            columns={HEADERS}
            rows={[15, 20, 100, 200, 300, 500]}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminWordListPage;
