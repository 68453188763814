import CustomTable from "@/common/components/custom/CustomTable";
import React, { useEffect, useMemo, useState } from "react";
import { HEADERS } from "./constants/headers";
import { useMutation } from "react-query";
import { getAllTranslators } from "@/api/query/translator";
import { findAllLanguages } from "@/api/query/language";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { styled } from "styled-components";
import Loading from "@/common/components/Loading";
import TranslatorForm from "./components/translatorForm/TranslatorForm";
import { getRaws } from "./helpers/getRaws";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const AdminTranslatorsPage = () => {
  const [translators, setTranslators] = useState();
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [editTranslator, setEditTranslator] = useState();

  const loadTranslators = useMutation({
    mutationFn: () => getAllTranslators(),
    onSuccess: ({ data }) => {
      setTranslators(data);
    },
  });

  const loadLanguages = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setLanguagesOptions(() => data.map((d) => ({ label: d.name, value: d })));
    },
  });

  const loadData = () => {
    loadTranslators.mutate();
    loadLanguages.mutate();
  };

  const raws = useMemo(() => {
    if (translators && languagesOptions) {
      return getRaws(translators, languagesOptions, setEditTranslator);
    }
  }, [translators, languagesOptions]);

  useEffect(() => loadData(), []);

  return (
    <Wrapper>
      {(loadLanguages.isLoading || loadTranslators.isLoading) && <Loading />}
      <TopContent>
        {editTranslator && (
          <TranslatorForm
            setEdit={setEditTranslator}
            edit={editTranslator}
            languagesOptions={languagesOptions}
            reloadFn={loadTranslators}
          />
        )}
        <CustomAddButton
          onClick={() => setEditTranslator({})}
          text={<FontAwesomeIcon icon={faAdd} style={{ color: "white" }} />}
        />
      </TopContent>
      <CustomTable headers={HEADERS} data={raws} />
    </Wrapper>
  );
};

export default AdminTranslatorsPage;
