import { createAdmin, updateAdmin } from "@/api/query/admin";
import { createCategory, updateCategory } from "@/api/query/category";
import { createLesson, updateLesson } from "@/api/query/lesson";
import { createWord } from "@/api/query/word";
import { Color } from "@/common/colors/colors";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { LESSON_TYPE } from "@/common/constants/lesson.constants";
import { WORD_LEVEL } from "@/common/constants/level.constants";
import { WORD_TYPE } from "@/common/constants/wordType.constants";
import { MessageContext } from "@/common/contexts/message.context";
import { getToSelectData } from "@/common/functions/getToSelectData";
import React, { useContext, useRef, useState } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

const AdminEdit = ({ edit, setEdit, languages }) => {
  const nameRef = useRef();

  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedType, setSelectedType] = useState();
  const [selectedLevel, setSelectedLevel] = useState();
  const { addMessage } = useContext(MessageContext);

  const createWordMutation = useMutation({
    mutationFn: (value) => createWord(value),
    onSuccess: ({ data }) => {
      window.open(`/admin/word/${data._id}`, "_blank");
      setEdit(null);
    },
    onError: ({ response }) => {
      addMessage(response?.data?.message, "error");
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    if (!selectedLanguage.value) {
      return;
    }

    const payload = edit;
    payload.content = nameRef.current.value;
    payload.language = selectedLanguage.value;
    payload.level = selectedLevel?.value;
    payload.type = selectedType?.value;

    createWordMutation.mutate(payload);
  };

  return (
    <>
      {createWordMutation.isLoading && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>{edit._id ? "Edit" : "Create"} Word</Title>
        <Wrapper onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={nameRef}
            color={Color.DarkBlue}
            name="Name"
            width={120}
            required
            value={edit.name}
            requiredSign
          />
          <SelectInput
            color={Color.DarkBlue}
            width={120}
            name={"Language"}
            options={languages}
            selected={selectedLanguage}
            setSelected={setSelectedLanguage}
            required
            requiredSign
          />
          <SelectInput
            color={Color.DarkBlue}
            width={120}
            name={"Level"}
            options={getToSelectData(WORD_LEVEL)}
            selected={selectedLevel}
            setSelected={setSelectedLevel}
          />
          <SelectInput
            color={Color.DarkBlue}
            width={120}
            name={"Type"}
            options={getToSelectData(WORD_TYPE)}
            selected={selectedType}
            setSelected={setSelectedType}
          />
          <ButtonWrapper>
            <CustomSaveButton />
          </ButtonWrapper>
        </Wrapper>
      </PopUp>
    </>
  );
};

export default AdminEdit;
