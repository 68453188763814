import React from "react";
import { styled } from "styled-components";
import { Color } from "@/common/colors/colors";
import SelectInput from "@/common/components/custom/SelectInput";
import { WORD_TYPE } from "@/common/constants/wordType.constants";
import { WORD_LEVEL } from "@/common/constants/level.constants";
import { getToSelectData } from "@/common/functions/getToSelectData";
import Input from "@/common/components/custom/Input";

const SelectBarContainer = styled.div`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  box-shadow: 0px 2px 10px ${Color.Grey};
  width: fit-content;
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  background-color: ${Color.DarkBlue};
  color: ${Color.White};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const SearchBar = ({
  options,
  selectedLang,
  setSelectedLang,
  setSelectedType,
  selectedType,
  selectedLevel,
  setSelectedLevel,
  handleSearch,
  checkIfIncludeSentence,
}) => {
  return (
    <SelectBarContainer>
      <div>
        <SelectInput
          width={50}
          name="Lang"
          color={Color.DarkBlue}
          selectWidth={250}
          options={options}
          selected={selectedLang}
          setSelected={setSelectedLang}
        />
        <SelectInput
          width={50}
          name="Level"
          multiple={true}
          color={Color.DarkBlue}
          selectWidth={250}
          options={getToSelectData(WORD_LEVEL)}
          selected={selectedLevel}
          setSelected={setSelectedLevel}
        />
        <Input
          inputRef={checkIfIncludeSentence}
          name="Allow without sentence"
          color={Color.DarkBlue}
          type="checkbox"
          checked={true}
          width={208}
          inputWidth={20}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <SelectInput
          width={60}
          name="Type"
          multiple
          color={Color.DarkBlue}
          selectWidth={600}
          options={getToSelectData(WORD_TYPE)}
          selected={selectedType}
          setSelected={setSelectedType}
        />
        <SearchButton onClick={handleSearch}>Search</SearchButton>
      </div>
    </SelectBarContainer>
  );
};

export default SearchBar;
