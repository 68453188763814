import { cleanObject } from "@/common/functions/cleanObject";
import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "change-log";

export const findChangeLog = async (payload) => {
  console.log(payload);
  const data = await api.get(
    `/${PREFIX}?${queryString.stringify(cleanObject(payload), {
      arrayFormat: "bracket",
    })}`
  );

  return { data: data.data };
};
