import { createJobProcess } from "@/api/query/jobProcess";
import { findAllLanguages } from "@/api/query/language";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { MessageContext } from "@/common/contexts/message.context";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ButtonWrapper, Title } from "../../AdminCommandPage.styled";
import { JOB_NAME } from "../../constants/jobs";

const FirstTranslationScript = ({ setClose }) => {
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [selectedLanguageFrom, setSelectedLanguageFrom] = useState();
  const [selectedLanguageTo, setSelectedLanguageTo] = useState();
  const { addMessage } = useContext(MessageContext);

  const loadLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setLanguagesOptions(() =>
        data
          .filter((d) => d.shortName !== "en")
          .map((d) => ({ label: d.name, value: d }))
      );

      const english = data.find((d) => d.shortName === "en");

      setSelectedLanguageFrom(() => ({ label: english?.name, value: english }));
    },
  });

  const createJobProcessMutation = useMutation({
    mutationFn: (values) => createJobProcess(values),
    onSuccess: () => {
      addMessage("Added job", "success");
    },
    onError: () => {
      addMessage("Something went wrong", "error");
    },
  });

  const handleCreateJobProcess = () => {
    if (!selectedLanguageTo) return;

    const jobName = JOB_NAME.TRANSLATION_SCRIPT;
    const argumentsPassed = {
      languageFrom: selectedLanguageFrom.value.shortName,
      languageTo: selectedLanguageTo.value.shortName,
    };

    const payload = {};

    payload.job_name = jobName;
    payload.arguments_passed = JSON.stringify(argumentsPassed);

    createJobProcessMutation.mutate(payload);
  };

  useEffect(() => {
    loadLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {createJobProcessMutation.isLoading && <Loading />}
      <PopUp setClose={setClose}>
        <Title>First Translation (Only from english)</Title>
        <SelectInput
          width={150}
          name={"Language from"}
          options={languagesOptions}
          selected={selectedLanguageFrom}
          setSelected={setSelectedLanguageFrom}
          disabled
        />
        <SelectInput
          width={150}
          name={"Language to"}
          options={languagesOptions}
          selected={selectedLanguageTo}
          setSelected={setSelectedLanguageTo}
        />
        <ButtonWrapper>
          <CustomEditButton
            text="Translate"
            onClick={() => handleCreateJobProcess()}
          />
        </ButtonWrapper>
      </PopUp>
    </>
  );
};

export default FirstTranslationScript;
