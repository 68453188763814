export const WORD_LEVEL = {
  A1: "A1",
  A2: "A2",
};

export const LESSON_LEVEL = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
};
