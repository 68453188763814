import { Color } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import SelectInput from "@/common/components/custom/SelectInput";
import { SubmitButton, Title } from "@/pages/common/login/loginPage.styles";
import React, { useRef, useState } from "react";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;
const AdminEditCategoryTranslation = ({
  setClose,
  edit,
  languages,
  setTranslations,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    languages.find((l) => l.value._id === edit.language)
  );
  const textRef = useRef();

  const handleSave = (e) => {
    e && e.preventDefault();

    if (!selectedLanguage?.value) return;
    const payload = edit;

    payload.language = selectedLanguage.value._id;
    payload.text = textRef.current.value;

    setTranslations((prev) => {
      return [...prev.filter((f) => f.language !== payload.language), payload];
    });

    setClose(null);
  };

  return (
    <PopUp setClose={setClose}>
      <form onSubmit={handleSave}>
        <Title>
          {(edit?._id && "Create") || "Edit"} Category Translation Name
        </Title>
        <SelectInput
          width={140}
          name="Language"
          color={Color.DarkBlue}
          selectWidth={225}
          options={languages}
          selected={selectedLanguage}
          setSelected={setSelectedLanguage}
          requiredSign
        />
        <Input
          inputRef={textRef}
          width={140}
          name="Text"
          color={Color.DarkBlue}
          inputWidth={200}
          required
          requiredSign
          value={edit?.text}
        />
        <ButtonWrapper>
          <SubmitButton type="submit" style={{ width: "100px" }}>
            Save
          </SubmitButton>
        </ButtonWrapper>
      </form>
    </PopUp>
  );
};

export default AdminEditCategoryTranslation;
