import { createSentence, updateSentence } from "@/api/query/sentence";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import TextArea from "@/common/components/custom/TextArea";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { Title } from "@/pages/common/login/loginPage.styles";
import React, { useRef } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin: 30px;
`;

const CustomButtonSubmitWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 20px 0 -20px 0;
`;

const EditSentence = ({ setClose, edit }) => {
  const texareaRef = useRef();
  const { id } = useParams();

  const updateSentenceMutation = useMutation({
    mutationFn: (value) => updateSentence(value),
    onSuccess: () => {
      setClose(null);
    },
  });

  const createSentenceMutation = useMutation({
    mutationFn: (value) => createSentence(value),
    onSuccess: () => {
      setClose(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = {};
    payload.content = texareaRef.current.value;

    if (edit._id) {
      payload._id = edit._id;
      return updateSentenceMutation.mutate(payload);
    }

    payload.wordId = id;

    createSentenceMutation.mutate(payload);
  };

  return (
    <>
      {(updateSentenceMutation.isLoading ||
        createSentenceMutation.isLoading) && <Loading />}
      <PopUp setClose={setClose}>
        <Wrapper onSubmit={handleSave}>
          <Title>Sentence {(edit._id && "Edit") || "Create"}</Title>
          <TextArea
            textAreaRef={texareaRef}
            placeholder="Type here sentece"
            defaultValue={edit.content}
            required
          />
          <CustomButtonSubmitWrapper>
            <CustomSaveButton />
          </CustomButtonSubmitWrapper>
        </Wrapper>
      </PopUp>
    </>
  );
};

export default EditSentence;
