import { cleanObject } from "@/common/functions/cleanObject";
import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "level";

export const findAllLevelsByLanguage = async (payload) => {
  const data = await api.get(`/${PREFIX}/by-language/${payload.languageId}`);

  return { data: data.data };
};

export const findByLevel = async (payload) => {
  const data = await api.get(`/${PREFIX}/by-level/${payload.levelId}`);

  return { data: data.data };
};

export const createLevel = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);

  return { data: data.data };
};

export const updateLevel = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);

  return { data: data.data };
};
