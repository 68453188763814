import React, { useEffect, useRef, useState } from "react";
import SearchBar from "./SearchBar";
import { findAllLanguages } from "@/api/query/language";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import {
  searchStatsDataForTranslationLangPair,
  searchStatsDataForTranslationScore,
} from "@/api/query/stats";
import CustomTable from "@/common/components/custom/CustomTable";
import styled from "styled-components";
import CustomBar from "@/common/charts/Bar";
import { Color } from "@/common/colors/colors";
import CustomDoughnut from "@/common/charts/Doughnut";

const colors = [
  Color.red,
  Color.blue,
  Color.green,
  Color.gold,
  Color.brightOrange,
];

const Title = styled.h3`
  text-align: left;
  width: 100%;
  margin-bottom: -20px;
`;

const LangPair = () => {
  const [options, setOptions] = useState([]);
  const [selectedCoreOptions, setSelectedCoreOption] = useState();
  const [selectedTranslationOptions, setSelectedTranslationOptions] =
    useState();
  const tresholdRef = useRef();
  const [tableData, setTableData] = useState();

  const findLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setOptions(() => data.map((d) => ({ label: d.name, value: d._id })));
    },
  });

  const handleData = (data) => {
    return {
      labels: ["Cannot Be push", "Can be push", "Pushed"],
      downloaded: data.cannotBePush + data.canBePush,
      datasets: [
        {
          label: "",
          data: [data.cannotBePush, data.canBePush, data.pushed],
          backgroundColor: [Color.red, Color.blue, Color.brightOrange],
          borderColor: [Color.red, Color.blue, Color.brightOrange],
        },
      ],
    };
  };

  const searchStatsDataMutation = useMutation({
    mutationFn: (payload) => searchStatsDataForTranslationLangPair(payload),
    onSuccess: ({ data }) => {
      setTableData(handleData(data));
    },
  });

  const handleSearch = () => {
    if (!selectedCoreOptions || !selectedTranslationOptions) {
      return;
    }

    const payload = {};
    payload.langFrom = selectedCoreOptions.value;
    payload.langTo = selectedTranslationOptions.value;
    payload.threshold = tresholdRef.current.value;

    searchStatsDataMutation.mutate(payload);
  };

  useEffect(() => {
    findLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {(findLanguagesMutation.isLoading ||
        searchStatsDataMutation.isLoading) && <Loading />}
      <SearchBar
        selectedCoreOptions={selectedCoreOptions}
        setSelectedCoreOption={setSelectedCoreOption}
        options={options}
        setSelectedTranslationOptions={setSelectedTranslationOptions}
        selectedTranslationOptions={selectedTranslationOptions}
        handleSearch={handleSearch}
        treshold={tresholdRef}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {tableData && <CustomDoughnut data={tableData} />}
      </div>
    </>
  );
};

export default LangPair;
