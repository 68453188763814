import React from "react";
import { styled } from "styled-components";

const AddButton = styled.button`
  background-color: #4caf50; /* Kolor tła przycisku dodawania */
  color: white; /* Kolor tekstu */
  padding: 10px 20px; /* Wielkość przycisku */
  border: none;
  border-radius: 5px; /* Zaokrąglenie krawędzi */
  cursor: pointer;
  margin-right: 10px; /* Miejsce między przyciskami */
`;

const CustomAddButton = ({ text = "add", onClick = () => {}, style = {} }) => {
  return (
    <AddButton type="button" onClick={onClick} style={{ ...style }}>
      {text}
    </AddButton>
  );
};

export default CustomAddButton;
