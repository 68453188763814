import { Color } from "@/common/colors/colors";
import styled from "styled-components";

export const Title = styled.h3`
  color: ${Color.DarkBlue};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const ScriptsStartButtonsWrapper = styled.div`
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;
