import React from "react";
import styled from "styled-components";
import AdminCheckExport from "./components/AdminCheckExport";

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  margin: 30px;
  gap: 30px;
  overflow-y: auto;
  height: calc(100vh - 80px);
`;

const AdminHomePage = () => {
  return (
    <Wrapper>
      <AdminCheckExport />
    </Wrapper>
  );
};

export default AdminHomePage;
