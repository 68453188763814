import {
  changeWordForNewOne,
  createWordAndSwap,
  findAllTranslationFroWord,
  searchByExactContent,
  searchWordByContent,
} from "@/api/query/word";
import { Color } from "@/common/colors/colors";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { MessageContext } from "@/common/contexts/message.context";
import { Title } from "@/pages/common/login/loginPage.styles";
import { faCheck, faSearch, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const ListWrapper = styled.div`
  height: 400px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ListElement = styled.div`
  background-color: ${Color.DarkGrey};
  padding: 5px 10px;
  margin: 0 10px;
  border-radius: 10px;
  color: white;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

const OtionalDataWrapper = styled.div``;
const OptionalDataElement = styled.div``;

const Text = styled.h4`
  min-width: 600px;
`;

const EditWordTranslation = ({ setClose, edit }) => {
  const [list, setList] = useState();
  const [optionalData, setOptionalData] = useState([]);
  const { id } = useParams();

  const searchRef = useRef();

  const changeWordForNewOneMutation = useMutation({
    mutationFn: (value) => changeWordForNewOne(value),
    onSuccess: () => {
      setClose(null);
    },
  });

  const searchWordByContentMutation = useMutation({
    mutationFn: (value) => searchWordByContent(value),
    onSuccess: ({ data }) => {
      setList(() => data);
    },
  });

  const handleSearch = (e) => {
    e && e.preventDefault();

    if (searchRef.current.value.length < 2) {
      return;
    }

    const payload = {};
    payload.content = searchRef.current.value;
    payload.lang = edit.currentWord.language._id;

    searchWordByContentMutation.mutate(payload);
  };

  const handleChangeWord = (selectedWord) => {
    const payload = {};

    payload.fromWord = id;
    payload.flashcard = edit.fp._id;
    payload.changeWord = selectedWord._id;
    payload.language = edit?.currentWord?.language._id;

    changeWordForNewOneMutation.mutate(payload);
  };

  const findAllTranslationFroWordMutation = useMutation({
    mutationFn: (value) => findAllTranslationFroWord(value),
    onSuccess: ({ data }) => {
      setOptionalData(
        data.filter((d) => d.toWord._id !== edit.currentWord._id)
      );
    },
  });

  const { addMessage } = useContext(MessageContext);

  const searchWordByExactContentMutation = useMutation({
    mutationFn: (value) => searchByExactContent(value),
    onSuccess: ({ data }) => {
      setList(() => data);
    },
  });

  const createWordAndSwapdMutation = useMutation({
    mutationFn: (value) => createWordAndSwap(value),
    onSuccess: ({ data }) => {
      setClose(null);
    },
    onError: ({ response }) => {
      addMessage(response?.data?.message, "error");
    },
  });

  const handleAddWord = () => {
    const payload = {};
    payload.word = id;
    payload.text = searchRef.current.value;
    payload.flashcard = edit.fp._id;
    payload.language = edit?.currentWord?.language._id;

    createWordAndSwapdMutation.mutate(payload);
  };

  useEffect(() => {
    const payload = {};

    payload.word = edit?.fp?.wordTranslation?.fromWord._id;
    payload.lang = edit?.currentWord?.language._id;

    findAllTranslationFroWordMutation.mutate(payload);
  }, []);

  const handleSearchExisiting = () => {
    const payload = {};
    payload.content = searchRef.current.value;
    payload.lang = edit.currentWord.language._id;

    searchWordByExactContentMutation.mutate(payload);
  };

  return (
    <>
      {(findAllTranslationFroWordMutation.isLoading ||
        changeWordForNewOneMutation.isLoading ||
        searchWordByContentMutation.isLoading ||
        searchWordByExactContentMutation.isLoading) && <Loading />}
      <PopUp setClose={setClose}>
        <Title>Word Translation Edit</Title>
        <Text>
          <div style={{ display: "flex", gap: "10px" }}>
            <p style={{ margin: 0, width: "230px" }}>Current word:</p>
            {edit.currentWord.content}{" "}
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <p style={{ margin: 0, width: "230px" }}>Current reliability:</p>
            {Math.round(edit.fp.wordTranslation.reliability * 100) / 100} %{" "}
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <p style={{ margin: 0, width: "230px" }}>
              Current translator source:
            </p>
            {edit.fp.wordTranslation.sources
              ?.map((a) => a.sourceType)
              .join(" ")}
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <p style={{ margin: 0, width: "230px" }}>
              Current linked lines source:
            </p>
            {(edit.fp.wordTranslation.linkedLineReliability &&
              `${
                Math.round(
                  edit.fp.wordTranslation.linkedLineReliability * 100
                ) / 100
              } %`) ||
              "-----"}
            {edit.fp.wordTranslation.linkedLine?.join(" ")}
          </div>
        </Text>
        <div>
          Words (translators max{" "}
          {Math.round(
            (edit.fp.wordTranslation.translatorMaxRealiabilty || 95) * 100
          ) / 100}
          {" %"})
        </div>
        {optionalData?.map((d, i) => (
          <OptionalDataElement>
            {i + 1}. {d.toWord.content} {Math.round(d.reliability * 100) / 100}{" "}
            % {d.sources?.map((a) => a.sourceType).join(" ")}{" "}
            {(d.linkedLineReliability &&
              `${Math.round(d.linkedLineReliability * 100) / 100} % `) ||
              "-----"}
            {d.linkedLine?.join(" ")}
            <FontAwesomeIcon
              icon={faCheck}
              style={{ cursor: "pointer" }}
              onClick={() => handleChangeWord(d.toWord)}
            />
          </OptionalDataElement>
        ))}
        <form
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
          onSubmit={handleSearch}
        >
          <Input
            inputRef={searchRef}
            placeholder="Search Word min 2 chars"
            showLabel={false}
            required
          />
          <CustomSaveButton text={"Search by regex"} />
          <CustomEditButton
            onClick={() => handleSearchExisiting()}
            text={"Search by exec word"}
          />
        </form>
      </PopUp>
      {list && (
        <PopUp setClose={setList}>
          <Title>
            Pick one word or add
            <div
              style={{ cursor: "pointer", color: "red", fontStyle: "italic" }}
              onClick={() => handleAddWord()}
            >
              {" "}
              {searchRef.current.value}
            </div>
          </Title>
          <OtionalDataWrapper></OtionalDataWrapper>
          <ListWrapper>
            {list
              .filter((e) => e._id !== edit._id)
              .map((d) => (
                <ListElement onClick={() => handleChangeWord(d)}>
                  {d.content} {d.level} {d.type}{" "}
                  {d?.flashcard ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "green" }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faX} style={{ color: "red" }} />
                  )}
                </ListElement>
              ))}
          </ListWrapper>
        </PopUp>
      )}
    </>
  );
};

export default EditWordTranslation;
