import api from "@/api/api";
import userManager from "@/api/userManager";
import React from "react";
import { Navigate } from "react-router-dom";
import {
  Left,
  RestWrapper,
  Right,
  TopWrapper,
  Wrapper,
} from "./adminLayout.styles";
import Navigation from "@/common/components/Navigation";
import TopInfo from "@/common/components/TopInfo";
import SearchInput from "../components/SearchInput";

const { tokenManager } = api;

const AdminLayout = ({ children }) => {
  const user = userManager?.getUser();

  if (!user || "Admin" !== user.role) {
    userManager.removeUser();
    tokenManager.removeToken();
    return <Navigate to="/login" replace />;
  }

  return (
    <Wrapper>
      <Left>
        <Navigation />
      </Left>
      <Right>
        <TopWrapper>
          <div style={{ width: "100px" }} />
          <SearchInput />
          <TopInfo />
        </TopWrapper>
        <RestWrapper>{children}</RestWrapper>
      </Right>
    </Wrapper>
  );
};

export default AdminLayout;
