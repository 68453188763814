import {
  createFlashCardWithExistingWord,
  createFlashCardWithNewWord,
  findAllDataPerWord,
  markWordStatusAsUserDecision,
  updateWord,
  updateWordNote,
} from "@/api/query/word";
import { Color } from "@/common/colors/colors";
import CustomTable from "@/common/components/custom/CustomTable";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import EditSentence from "./components/EditSentence";
import EditWord from "./components/EditWord";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faEdit,
  faQuestion,
  faRocket,
  faVolumeHigh,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import EditSentenceTranslation from "./components/EditSentenceTranslation";
import EditWordTranslation from "./components/EditWordTranslation";
import Input from "@/common/components/custom/Input";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { getBase64 } from "@/common/functions/getBase64";
import MarkEntityAsUserDecision from "./components/MarkEntityAsUserDecision";
import { markTranslationAsUser } from "@/api/query/translation";
import Loading from "@/common/components/Loading";
import EditLevel from "./components/EditLevel";
import EditCategory from "./components/EditCategory";
import { findLastStatusEditor, playAudioFromBase64 } from "./helpers";
import AddFlashcardWord from "./components/AddFlashcardWord";
import { deleteFlashCard } from "@/api/query/flashcard";
import EditSentenceTranslationTo from "./components/EditSentenceTranslationTo";
import { MessageContext } from "@/common/contexts/message.context";
import moment from "moment";
import TextArea from "@/common/components/custom/TextArea";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";
import AddTranslationsByTranslators from "./components/AddTranslationsByTranslators";

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImageWrapper = styled.div`
  width: 300px;
`;

const Title = styled.h1`
  color: ${Color.DarkBlue};
`;

const Subtitle = styled.h3`
  color: ${Color.DarkBlue};
  margin: 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
`;

const SentenceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ImageUploadWrapper = styled.form`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const HeaderWithVolume = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Hover = styled.div`
  position: relative;

  &:hover {
    &::after {
      content: "${(props) => props.label}";
      white-space: nowrap;
      display: ${(props) => (props.label ? "block" : "none")};
      font-size: 14px;
      position: absolute;
      background-color: rgba(139, 139, 139, 0.9);
      color: ${Color.lightGray};
      font-weight: bold;
      padding: 8px;
      top: -40px;
      right: 0;
      transform: translateX(50%);
      border-radius: 10px;
      box-shadow: rgb(220, 220, 220, 0.8) 0px 7px 29px 0px;
    }
  }
`;

const TitleContentWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const getIconByType = (veryficationType) => {
  if (!veryficationType) return;

  const sorted = veryficationType.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0];

  const verificationStatus = sorted?.verificationStatus;

  if (verificationStatus === "correct") {
    return faCheck;
  }
  if (verificationStatus === "question") {
    return faQuestion;
  }
  if (verificationStatus === "wrong") {
    return faClose;
  }

  return faRocket;
};

export const getIconByColor = (veryficationType) => {
  if (!veryficationType) return;

  const sorted = veryficationType.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )[0];

  const verificationStatus = sorted?.verificationStatus;

  if (verificationStatus === "correct") {
    return "green";
  }
  if (verificationStatus === "question") {
    return "orange";
  }
  if (verificationStatus === "wrong") {
    return "red";
  }

  return "black";
};

const getSentence = (
  sth,
  cols,
  setShowEditSentenceTranslation,
  currentWord,
  setShowEditUserDecisionAboutWord,
  showEdit = true
) => {
  const sentenceTranslation = sth?.sentenceTranslation;

  return cols.map((c) => {
    if (c.content === sentenceTranslation?.fromSentence?.content) {
      return (
        <SentenceWrapper>
          <p style={{ fontWeight: "bold" }}>
            {sentenceTranslation.toSentence.content}
          </p>
          <p style={{ fontStyle: "italic" }}>
            {Math.round((sentenceTranslation?.reliability || 0) * 100) / 100} %
          </p>
          {showEdit && (
            <>
              <FontAwesomeIcon
                icon={faEdit}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  setShowEditSentenceTranslation({ currentWord, sth, base: c })
                }
              />
              <Hover
                label={findLastStatusEditor(sentenceTranslation.veryfication)}
              >
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={getIconByType(sentenceTranslation.veryfication)}
                  onClick={() =>
                    setShowEditUserDecisionAboutWord(sentenceTranslation)
                  }
                />
              </Hover>
              {sentenceTranslation.toSentence.sound?.content && (
                <FontAwesomeIcon
                  icon={faVolumeHigh}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    playAudioFromBase64(
                      sentenceTranslation.toSentence.sound?.content
                    )
                  }
                />
              )}
            </>
          )}
        </SentenceWrapper>
      );
    }

    if (c.content === sentenceTranslation?.toSentence?.content) {
      return (
        <SentenceWrapper>
          <p style={{ fontWeight: "bold" }}>
            {sentenceTranslation?.fromSentence?.content}
          </p>
          <p style={{ fontStyle: "italic" }}>
            {Math.round((sentenceTranslation?.reliability || 0) * 100) / 100} %
          </p>
          {showEdit && (
            <>
              <FontAwesomeIcon
                icon={faEdit}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  setShowEditSentenceTranslation({ currentWord, sth, base: c })
                }
              />
              <Hover
                label={findLastStatusEditor(sentenceTranslation.veryfication)}
              >
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={getIconByType(sentenceTranslation.veryfication)}
                  onClick={() =>
                    setShowEditUserDecisionAboutWord(sentenceTranslation)
                  }
                />
              </Hover>
            </>
          )}
        </SentenceWrapper>
      );
    }

    return (
      <SentenceWrapper>
        ---
        {showEdit && (
          <>
            <FontAwesomeIcon
              icon={faEdit}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setShowEditSentenceTranslation({ currentWord, sth, base: c })
              }
            />
          </>
        )}
      </SentenceWrapper>
    );
  });
};

const getWord = (sth, mainWord) => {
  if (sth.fromWord.content !== mainWord) {
    return sth.toWord;
  }

  return sth.fromWord;
};

const AdminWordPage = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [colmnsTo, setColumnsTo] = useState([]);
  const [colmnsFrom, setColumnsFrom] = useState([]);

  const [bodyDataFrom, setBodyDataFrom] = useState([]);
  const [bodyDataTo, setBodyDataTo] = useState([]);
  const [showEditUserDecisionAboutWord, setShowEditUserDecisionAboutWord] =
    useState();
  const [showEditUserDecision, setShowEditUserDecision] = useState();
  const [editSentence, setEditSentence] = useState();
  const [editWord, setEditWord] = useState();
  const [showEditWordTranslation, setShowEditWordTranslation] = useState();
  const [showEditSentenceTranslation, setShowEditSentenceTranslation] =
    useState();
  const [showEditSentenceTranslationTo, setShowEditSentenceTranslationTo] =
    useState();
  const [showLevelEdit, setShowLevelEdit] = useState();
  const [showCategoryEdit, setShowCategoryEdit] = useState();
  const [editTranslationFrom, setEditTranslationFrom] = useState();
  const [flashcardSentencesOnly, setFlashcardSentencesOnly] = useState();
  const [
    showAddTranslationsByTranslators,
    setShowAddTranslationsByTranslators,
  ] = useState();

  const textAreaRef = useRef();
  const imageRef = useRef();

  const updateWordImageMutation = useMutation({
    mutationFn: (value) => updateWord(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload._id = id;

      findWordDataMutation.mutate(payload);
    },
  });

  const updateWordNoteMutation = useMutation({
    mutationFn: (value) => updateWordNote(value),
    onSuccess: ({ data }) => {
      addMessage("Saved", "success");
    },
    onError: ({ data }) => {
      addMessage("errror", "error");
    },
  });

  const handleSaveNote = () => {
    const payload = {};
    payload.word = id;
    payload.note = textAreaRef?.current?.value || "";

    updateWordNoteMutation.mutate(payload);
  };

  const handleSubmitImage = async (e) => {
    e && e.preventDefault();

    const base64 = await getBase64(imageRef.current?.files[0]);

    const payload = {};

    payload._id = id;
    payload.base64 = base64;

    updateWordImageMutation.mutate(payload);
  };

  const markTranslationAsUserDecision = useMutation({
    mutationFn: (value) => markTranslationAsUser(value),
    onSuccess: ({ data }) => {
      setShowEditUserDecisionAboutWord(null);
    },
  });

  const markWordAsUserDecision = useMutation({
    mutationFn: (value) => markWordStatusAsUserDecision(value),
    onSuccess: () => {
      setShowEditUserDecision(null);
    },
  });

  const handleDeleteFlashCardMutation = useMutation({
    mutationFn: (value) => deleteFlashCard(value),
    onSuccess: () => {
      const payload = {};
      payload._id = id;

      findWordDataMutation.mutate(payload);
    },
  });

  const handleSaveTranslationDecistion = (decision) => {
    const payload = {};
    payload.decision = decision;
    payload.translation = showEditUserDecisionAboutWord._id;

    markTranslationAsUserDecision.mutate(payload);
  };

  const handleSaveWordDecistion = (decision) => {
    const payload = {};
    payload.decision = decision;
    payload.wordId = showEditUserDecision._id;

    markWordAsUserDecision.mutate(payload);
  };

  const handleDeleteFlashCard = (flashcardId) => {
    const payload = {};
    payload.flashcardId = flashcardId;

    handleDeleteFlashCardMutation.mutate(payload);
  };

  const findWordDataMutation = useMutation({
    mutationFn: (value) => findAllDataPerWord(value),
    onSuccess: ({ data }) => {
      const sentencesTo = [...data.sentences].filter((s) => {
        if (flashcardSentencesOnly) {
          const flascardsFrom = data.flashcardToPopulated.find((ftp) => {
            return (
              ftp.sentenceTranslation.fromSentence._id === s._id ||
              ftp.sentenceTranslation.toSentence._id === s._id
            );
          });

          if (flascardsFrom) {
            return true;
          }

          return false;
        }

        return true;
      });

      const sentencesFrom = [...data.sentences].filter((s) => {
        if (flashcardSentencesOnly) {
          const flascardsFrom = data.flashcardFromPopulated.find((ftp) => {
            return (
              ftp.sentenceTranslation.fromSentence._id === s._id ||
              ftp.sentenceTranslation.toSentence._id === s._id
            );
          });

          if (flascardsFrom) {
            return true;
          }

          return false;
        }

        return true;
      });

      const columnsTo = [
        <div>{data.mainData.language.name}</div>,
        <HeaderWithVolume>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setEditWord(data.mainData)}
          >
            {data.mainData.content}
          </div>
          {data.mainData.sound?.content && (
            <FontAwesomeIcon
              onClick={() => playAudioFromBase64(data.mainData.sound?.content)}
              icon={faVolumeHigh}
              style={{ cursor: "pointer" }}
            />
          )}
        </HeaderWithVolume>,
        ...sentencesTo.map((d) => (
          <HeaderWithVolume>
            <div
              onClick={() => setEditSentence(d)}
              style={{ cursor: "pointer" }}
            >
              {d.content}
            </div>
            {d.sound?.content && (
              <FontAwesomeIcon
                onClick={() => playAudioFromBase64(d.sound.content)}
                icon={faVolumeHigh}
                style={{ cursor: "pointer" }}
              />
            )}
          </HeaderWithVolume>
        )),
      ];

      const columnsFrom = [
        <div>{data.mainData.language.name}</div>,
        <HeaderWithVolume>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setEditWord(data.mainData)}
          >
            {data.mainData.content}
          </div>
          {data.mainData.sound?.content && (
            <FontAwesomeIcon
              onClick={() => playAudioFromBase64(data.mainData.sound?.content)}
              icon={faVolumeHigh}
              style={{ cursor: "pointer" }}
            />
          )}
        </HeaderWithVolume>,
        ...sentencesFrom.map((d) => (
          <HeaderWithVolume>
            <div
              onClick={() => setEditSentence(d)}
              style={{ cursor: "pointer" }}
            >
              {d.content}
            </div>
            {d.sound?.content && (
              <FontAwesomeIcon
                onClick={() => playAudioFromBase64(d.sound.content)}
                icon={faVolumeHigh}
                style={{ cursor: "pointer" }}
              />
            )}
          </HeaderWithVolume>
        )),
      ];

      const rowsFrom = data.flashcardFromPopulated.map((fp) => {
        const currentWord = fp.wordTranslation.toWord;
        const langTo = fp.wordTranslation.toWord.language._id;
        const migration = data.mainData.migration?.find(
          (d) => langTo.toString() === d.language.toString()
        );

        return [
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {currentWord?.language?.name}
            <Hover label={"Delete flashcard from system"}>
              <FontAwesomeIcon
                icon={faX}
                style={{
                  color: "red",
                  fontWeight: "bold",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={() => handleDeleteFlashCard(fp._id)}
              />{" "}
            </Hover>
            {migration && (
              <Hover
                label={`Migration date: ${moment(
                  migration.migrationDate
                ).format("DD/MM/YYYY")}`}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "green",
                    fontWeight: "bold",
                    marginLeft: "-20px",
                    marginRight: "20px",
                  }}
                />
              </Hover>
            )}
          </div>,
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <StyledLink to={`/admin/word/${currentWord?._id}`}>
              {currentWord?.content}
            </StyledLink>
            <p style={{ fontStyle: "italic" }}>
              {Math.round(fp.wordTranslation?.reliability * 100) / 100} %
            </p>
            <FontAwesomeIcon
              icon={faEdit}
              style={{ cursor: "pointer" }}
              onClick={() => setShowEditWordTranslation({ currentWord, fp })}
            />
            <Hover
              label={findLastStatusEditor(fp.wordTranslation?.veryfication)}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={getIconByType(fp.wordTranslation?.veryfication)}
                onClick={() =>
                  setShowEditUserDecisionAboutWord(fp.wordTranslation)
                }
              />
            </Hover>
            {fp.wordTranslation?.toWord?.sound?.content && (
              <FontAwesomeIcon
                icon={faVolumeHigh}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  playAudioFromBase64(fp.wordTranslation.toWord.sound.content)
                }
              />
            )}
          </div>,
          ...getSentence(
            fp,
            sentencesFrom,
            setShowEditSentenceTranslation,
            currentWord,
            setShowEditUserDecisionAboutWord
          ),
        ];
      });

      const rowsTo = data.flashcardToPopulated.map((fp) => {
        const currentWord = fp.wordTranslation.fromWord;

        return [
          <div>{currentWord?.language?.name}</div>,
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <StyledLink to={`/admin/word/${currentWord?._id}`}>
              {currentWord?.content}
            </StyledLink>
            <p style={{ fontStyle: "italic" }}>
              {Math.round(fp.wordTranslation?.reliability * 100) / 100} %
            </p>
          </div>,
          ...getSentence(
            fp,
            sentencesTo,
            setShowEditSentenceTranslationTo,
            currentWord,
            setShowEditUserDecisionAboutWord,
            true
          ),
        ];
      });

      setColumnsFrom(() => columnsFrom);
      setColumnsTo(() => columnsTo);

      setData(data);
      setBodyDataFrom(() => rowsFrom);
      setBodyDataTo(() => rowsTo);
    },
  });

  const { addMessage } = useContext(MessageContext);

  const handleCreateFlashCardWithNewWordMutation = useMutation({
    mutationFn: (value) => createFlashCardWithNewWord(value),
    onSuccess: () => {
      setEditTranslationFrom(null);
    },
    onError: ({ response }) => {
      addMessage(response?.data?.message, "error");
    },
  });

  const handleCreateFlashCardWithExistingWordMutation = useMutation({
    mutationFn: (value) => createFlashCardWithExistingWord(value),
    onSuccess: () => {
      setEditTranslationFrom(null);
    },
  });

  const handleAddTranlsationFrom = (pickedWord, language) => {
    const payload = {};
    payload.currentWord = id;

    if (language) {
      payload.wordContent = pickedWord;
      payload.language = language.value._id;

      return handleCreateFlashCardWithNewWordMutation.mutate(payload);
    }

    payload.wordId = pickedWord._id;

    return handleCreateFlashCardWithExistingWordMutation.mutate(payload);
  };

  useEffect(() => {
    const payload = {};
    payload._id = id;

    if (
      !editSentence &&
      !editWord &&
      !showEditSentenceTranslation &&
      !showEditSentenceTranslationTo &&
      !showEditWordTranslation &&
      !showEditUserDecisionAboutWord &&
      !showEditUserDecision &&
      !showLevelEdit &&
      !showCategoryEdit &&
      !editTranslationFrom &&
      !showAddTranslationsByTranslators
    ) {
      findWordDataMutation.mutate(payload);
    }
  }, [
    id,
    editSentence,
    editWord,
    showEditSentenceTranslationTo,
    showEditSentenceTranslation,
    showEditWordTranslation,
    showEditUserDecisionAboutWord,
    showEditUserDecision,
    showLevelEdit,
    showCategoryEdit,
    editTranslationFrom,
    showAddTranslationsByTranslators,
  ]);

  return (
    <>
      {(findWordDataMutation.isLoading ||
        markTranslationAsUserDecision.isLoading ||
        updateWordImageMutation.isLoading ||
        handleDeleteFlashCardMutation.isLoading ||
        updateWordNoteMutation.isLoading) && <Loading />}
      {showEditUserDecisionAboutWord && (
        <MarkEntityAsUserDecision
          setShow={setShowEditUserDecisionAboutWord}
          data={showEditUserDecisionAboutWord}
          handleSaveDecistion={handleSaveTranslationDecistion}
        />
      )}
      {editTranslationFrom && (
        <AddFlashcardWord
          setClose={setEditTranslationFrom}
          data={editTranslationFrom}
          handleAddTranlsationFrom={handleAddTranlsationFrom}
        />
      )}
      {showEditUserDecision && (
        <MarkEntityAsUserDecision
          setShow={setShowEditUserDecision}
          data={showEditUserDecision}
          handleSaveDecistion={handleSaveWordDecistion}
        />
      )}
      {editSentence && (
        <EditSentence setClose={setEditSentence} edit={editSentence} />
      )}
      {editWord && <EditWord setClose={setEditWord} edit={editWord} />}
      {showEditSentenceTranslationTo && (
        <EditSentenceTranslationTo
          setClose={setShowEditSentenceTranslationTo}
          edit={showEditSentenceTranslationTo}
        />
      )}
      {showEditSentenceTranslation && (
        <EditSentenceTranslation
          setClose={setShowEditSentenceTranslation}
          edit={showEditSentenceTranslation}
        />
      )}
      {showEditWordTranslation && (
        <EditWordTranslation
          setClose={setShowEditWordTranslation}
          edit={showEditWordTranslation}
        />
      )}
      {showLevelEdit && (
        <EditLevel setClose={setShowLevelEdit} edit={showLevelEdit} />
      )}
      {showCategoryEdit && (
        <EditCategory setClose={setShowCategoryEdit} edit={showCategoryEdit} />
      )}
      {showAddTranslationsByTranslators && (
        <AddTranslationsByTranslators
          setClose={setShowAddTranslationsByTranslators}
          data={data}
        />
      )}
      <div>
        <TopContent>
          <div>
            <Title>
              <TitleContentWrapper>
                Word:
                <span style={{ color: "red", fontStyle: "italic" }}>
                  {" "}
                  {data.mainData?.content}{" "}
                </span>
                <Hover
                  label={findLastStatusEditor(data.mainData?.veryfications)}
                >
                  <FontAwesomeIcon
                    color={getIconByColor(data.mainData?.veryfications)}
                    onClick={() => setShowEditUserDecision(data.mainData)}
                    style={{ cursor: "pointer" }}
                    icon={getIconByType(data.mainData?.veryfications)}
                  />
                </Hover>
              </TitleContentWrapper>
            </Title>
            <Subtitle>
              Level:{" "}
              <span
                style={{ color: "red", fontStyle: "italic", cursor: "pointer" }}
                onClick={() => setShowLevelEdit(data.mainData)}
              >
                {" "}
                {data.mainData?.level}
              </span>
            </Subtitle>
            <Subtitle>
              Type:{" "}
              <span
                style={{ color: "red", fontStyle: "italic", cursor: "pointer" }}
                onClick={() => setShowCategoryEdit(data.mainData)}
              >
                {" "}
                {data.mainData?.type}
              </span>
            </Subtitle>
            <Subtitle>
              Lesson:{" "}
              <Link
                to={`/admin/lesson?language=${data?.mainData?.language?._id}&level=${data.mainData?.lesson?.category?.level?._id}&lesson=${data.mainData?.lesson?._id}`}
              >
                {data.mainData?.lesson?.category?.level?.defualtName}
                {" / "}
                {
                  data.mainData?.lesson?.category?.category_names?.find(
                    (cn) => cn.language === data.mainData?.language?._id
                  )?.text
                }{" "}
                {" / "}
                {data.mainData?.lesson?.number}
              </Link>
            </Subtitle>
            <TextArea
              placeholder="type note"
              textAreaRef={textAreaRef}
              defaultValue={data?.mainData?.note}
              width="500px"
            />
            <div style={{ height: "10px" }}></div>
            <CustomDeleteButton text="Save note" onClick={handleSaveNote} />
          </div>
          <ImageWrapper>
            <img
              src={data.mainData?.image?.base64}
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
            <ImageUploadWrapper onSubmit={handleSubmitImage}>
              <Input
                inputRef={imageRef}
                type="file"
                showLabel={false}
                required
                inputWidth={160}
                accept={"image/png, image/gif, image/jpeg"}
              />
              <CustomSaveButton text="Upload " />
            </ImageUploadWrapper>
          </ImageWrapper>
        </TopContent>
        <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
          <CustomEditButton
            text="Add sentence"
            onClick={() => setEditSentence({})}
          />
          <CustomEditButton
            text="Add translation from"
            onClick={() => setEditTranslationFrom({})}
          />
          <CustomEditButton
            text={
              !flashcardSentencesOnly
                ? "Show only flashcard sentences"
                : "show all sentences"
            }
            onClick={() => {
              setFlashcardSentencesOnly((prev) => !prev);
              setTimeout(() => {
                findWordDataMutation.mutate({ _id: id });
              }, 100);
            }}
          />
          <CustomEditButton
            text={"Add translations by lang ( translators )"}
            onClick={() => {
              setShowAddTranslationsByTranslators(() => true);
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "80px" }}>
          <div style={{ marginTop: "20px" }}>
            <Title>TRANSLATION FROM: </Title>
            <CustomTable headers={colmnsFrom} data={bodyDataFrom} />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Title>TRANSLATION TO: </Title>
            <CustomTable headers={colmnsTo} data={bodyDataTo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminWordPage;
