import AdminHomePage from "@/pages/admin/home/AdminHomePage";
import AdminLayout from "../layout/adminLayout/AdminLayout";
import LoginPage from "@/pages/common/login/LoginPage";
import AdminUserPage from "@/pages/admin/user/AdminUserPage";
import LanguagesPairListPage from "@/pages/admin/languagesPairList/LanguagesPairListPage";
import AdminWordPage from "@/pages/admin/word/AdminWordPage";
import AdminLanguagePage from "@/pages/admin/languages/AdminLanguagePage";
import AdminCommandsPage from "@/pages/admin/commands/AdminCommandsPage";
import AdminStatsPage from "@/pages/admin/stats/AdminStatsPage";
import AdminWordListPage from "@/pages/admin/wordList/AdminWordListPage";
import AdminLessonPage from "@/pages/admin/lesson/AdminLessonPage";
import AdminTranslatorsPage from "@/pages/admin/translators/AdminTranslatorsPage";
import AditionalPages from "@/pages/admin/aditional-pages/AditionalPages";

const ROUTES = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/admin/home",
    element: (
      <AdminLayout>
        <AdminHomePage />
      </AdminLayout>
    ),
  },
  {
    path: "/admin/user",
    element: (
      <AdminLayout>
        <AdminUserPage />
      </AdminLayout>
    ),
  },
  {
    path: "/admin/word/:id",
    element: (
      <AdminLayout>
        <AdminWordPage />
      </AdminLayout>
    ),
  },
  {
    path: "/admin/stats",
    element: (
      <AdminLayout>
        <AdminStatsPage />
      </AdminLayout>
    ),
  },
  {
    path: "/admin/languages-pair-list",
    element: (
      <AdminLayout>
        <LanguagesPairListPage />
      </AdminLayout>
    ),
  },
  {
    path: "/admin/language",
    element: (
      <AdminLayout>
        <AdminLanguagePage />
      </AdminLayout>
    ),
  },
  {
    path: "/admin/commands",
    element: (
      <AdminLayout>
        <AdminCommandsPage />
      </AdminLayout>
    ),
  },
  {
    path: "/admin/translators",
    element: (
      <AdminLayout>
        <AdminTranslatorsPage />
      </AdminLayout>
    ),
  },
  {
    path: "/admin/word-list",
    element: (
      <AdminLayout>
        <AdminWordListPage />
      </AdminLayout>
    ),
  },
  {
    path: "/admin/lesson",
    element: (
      <AdminLayout>
        <AdminLessonPage />
      </AdminLayout>
    ),
  },
  {
    path: "/admin/aditional-pages",
    element: (
      <AdminLayout>
        <AditionalPages />
      </AdminLayout>
    ),
  },
];

export default ROUTES;
