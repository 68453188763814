import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "translation";

export const markTranslationAsUser = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/mark-translation-as-user-decision`,
    payload
  );

  return { data: data.data };
};

export const genereteMultipleTranslations = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/generate-multiple-translations`,
    payload
  );

  return { data: data.data };
};
