import Api from "../api";
const { axios: api } = Api;

const PREFIX = "translator";

export const createTranslator = async (payload) => {
  const data = await api.post(`/${PREFIX}/create`, payload);

  return { data: data.data };
};

export const getAllTranslators = async () => {
  const data = await api.get(`/${PREFIX}/get-all`);

  return { data: data.data };
};

export const updateTranslator = async (translatorId, payload) => {
  const data = await api.patch(`/${PREFIX}/update/${translatorId}`, payload);

  return { data: data.data };
};
