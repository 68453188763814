import React from "react";
import { styled } from "styled-components";

const EditButton = styled.button`
  background-color: #212f46; /* Kolor tła przycisku edycji */
  color: white;
  padding: 10px 20px; /* Wielkość przycisku */
  border: none;
  border-radius: 5px; /* Zaokrąglenie krawędzi */
  cursor: pointer;

  &:disabled {
    cursor: auto;
    opacity: 0.7;
  }
`;

const CustomEditButton = ({
  text = "Edit",
  onClick = () => {},
  style = {},
  disabled = false,
}) => {
  return (
    <EditButton
      disabled={disabled}
      type="button"
      onClick={onClick}
      style={{ ...style }}
    >
      {text}
    </EditButton>
  );
};

export default CustomEditButton;
