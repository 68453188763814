import { createLanguage, updateLanguage } from "@/api/query/language";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useRef } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

const AdminLanguageEdit = ({ edit, setEdit }) => {
  const nameRef = useRef();
  const shortNameRef = useRef();
  const isLangToRef = useRef();

  const createLanguageMutation = useMutation({
    mutationFn: (value) => createLanguage(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateLanguageMutation = useMutation({
    mutationFn: (value) => updateLanguage(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.shortName = shortNameRef.current.value;
    payload.isLangTo = isLangToRef.current.checked;

    console.log(payload);

    if (edit._id) {
      return updateLanguageMutation.mutate(payload);
    }

    createLanguageMutation.mutate(payload);
  };

  return (
    <>
      {(createLanguageMutation.isLoading ||
        updateLanguageMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>{edit._id ? "Edit" : "Create"} Language</Title>
        <Wrapper onSubmit={(e) => handleSave(e)} autoComplete="new-password">
          <Input
            inputRef={nameRef}
            name="Name"
            width={100}
            required
            value={edit.name}
          />
          <Input
            inputRef={shortNameRef}
            name="Short Name"
            width={100}
            required
            value={edit.shortName}
          />
          <Input
            checked={edit.isLangTo}
            inputRef={isLangToRef}
            name="Is Lang to"
            width={90}
            type="checkbox"
          />
          <ButtonWrapper>
            <CustomSaveButton />
          </ButtonWrapper>
        </Wrapper>
      </PopUp>
    </>
  );
};

export default AdminLanguageEdit;
