export const WORD_TYPE = {
  DETERMINER: "determiner",
  PRONOUN: "pronoun",
  VERB: "verb",
  NOUN: "noun",
  ADVERB: "adverb",
  ADJECTIVE: "adjective",
  PROPOSITION: "preposition",
  NUMERAL: "numeral",
  CONJUNCTION: "conjunction",
  DEFINE_ARTICLE: "define_article",
  INDEFINITE_ARTICLE: "indefinite_article",
  EXCLAMATION: "exclamation",
  MODAL_VERB: "modal_verb",
  AUXILIARY_VERB: "auxiliary_verb",
  INFINITIVE_MARKET: "infinitive_marker",
  ADJECTIVE_ADVERB: "adjective_adverb",
  DETERMINER_PRONOUN: "determiner_pronoun",
  DETERMINER_PRONOUN_ADVERB: "determiner_pronoun_adverb",
  DETERMINER_ADJECTIVE: "determiner_adjective",
  DETERMINER_NUMERAL: "determiner_numeral",
  EXCLAMATION_NOUN: "exclamation_noun",
  NUMERAL_DETERMINER: "numeral_determiner",
  ADJECTIVE_PRONOUN: "adjective_pronoun",
  ADVERB_PREPOSITION: "adverb_preposition",
  CONJUNCTION_PREPOSITION: "conjunction_preposition",
  PRONOUN_DETERMINER: "pronoun_determiner",
};
