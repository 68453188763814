import { createLevel, updateLevel } from "@/api/query/level";
import { Color } from "@/common/colors/colors";
import PopUp from "@/common/components/PopUp";
import CustomTable from "@/common/components/custom/CustomTable";
import Input from "@/common/components/custom/Input";
import SelectInput from "@/common/components/custom/SelectInput";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import { LESSON_LEVEL } from "@/common/constants/level.constants";
import { getToSelectData } from "@/common/functions/getToSelectData";
import { TopWrapper } from "@/common/layout/adminLayout/adminLayout.styles";
import { SubmitButton, Title } from "@/pages/common/login/loginPage.styles";
import {
  faAd,
  faAdd,
  faAngleDown,
  faAngleUp,
  faEdit,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import AdminEditCategoryTranslation from "./AdminEditCategoryTranslation";
import HelpTable from "./HelpTable";
import { createCategory, updateCategory } from "@/api/query/category";
import { LESSON_TYPE } from "@/common/constants/lesson.constants";
import {
  changeWordPositionInLesson,
  createLesson,
  deleteWordFromLesson,
  updateLesson,
} from "@/api/query/lesson";
import { Link } from "react-router-dom";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";
import { icon } from "@fortawesome/fontawesome-svg-core";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import AdminAddWord from "./AdminAddWord";
import TextArea from "@/common/components/custom/TextArea";
import AdminAddTask from "./AdminAddTask";
import { findAllLanguages } from "@/api/query/language";

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: right;
`;

const headers = ["No.", "Text", "Language", "Actions"];

const AdminLessonEdit = ({ setClose, edit, reload }) => {
  const numberRef = useRef();

  const [addWord, setAddWord] = useState();
  const [addTask, setAddTask] = useState();
  const [tasks, setTasks] = useState(edit.lesson.tasks || []);
  const [languages, setLanguages] = useState([]);

  const [selectedType, setSelectedType] = useState(
    getToSelectData(LESSON_TYPE).find((f) => f.value === edit?.lesson?.type) ||
      getToSelectData(LESSON_TYPE)[0]
  );

  const createCategoryMutation = useMutation({
    mutationFn: (value) => createLesson(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload._id = edit.category._id;

      reload.mutate(payload);
      setClose(null);
    },
  });

  const updateCategoryMutation = useMutation({
    mutationFn: (value) => updateLesson(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload._id = edit.category._id;

      reload.mutate(payload);
      console.log("XDDDD");
      setClose(() => null);
    },
  });

  const deleteWordFromLessonMutation = useMutation({
    mutationFn: (value) => deleteWordFromLesson(value),
    onSuccess: async ({ data }) => {
      const payload = {};
      payload._id = edit.category._id;

      await reload.mutate(payload);
    },
  });

  const changeWordPosiontionInLessonMutation = useMutation({
    mutationFn: (value) => changeWordPositionInLesson(value),
    onSuccess: async ({ data }) => {
      const payload = {};
      payload._id = edit.category._id;

      await reload.mutate(payload);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = {};

    payload.category = edit?.category?._id;
    payload._id = edit?.lesson?._id;
    payload.type = selectedType.value;
    payload.tasks = tasks;

    if (payload._id) {
      payload.number = numberRef.current.value;

      return updateCategoryMutation.mutate(payload);
    }

    return createCategoryMutation.mutate(payload);
  };

  const handleChangeWordNumber = (word, direction) => {
    const payload = {};
    payload.wordId = word._id;
    payload.direction = direction;

    changeWordPosiontionInLessonMutation.mutate(payload);
  };

  const handleRemoveWordFromLesson = (word) => {
    const payload = {};
    payload.wordId = word._id;

    deleteWordFromLessonMutation.mutate(payload);
  };

  const genHeaders = () => {
    const table = ["No.", "English Word"];

    if (edit?.options.length > 0) {
      table.push(...edit?.options.map((langoToWord) => langoToWord.key));
    }

    table.push("Actions");

    return table;
  };

  const isWordMigration = (word, langoToWord) => {
    const lookingWord = langoToWord.value
      .find((l) => l._id === word._id)
      ?.wordTranslation?.find(
        (wt) =>
          wt?.flascards?.length > 0 &&
          wt?.toWord[0]?.language[0]?.name === langoToWord.key
      )?.toWord[0];

    if (lookingWord) {
      const wordMigration = word?.migration
        ?.map((d) => d.language)
        .includes(lookingWord?.language[0]?._id);

      if (wordMigration) {
        return "^";
      }
    }
    return "";
  };

  const handleLoadLanguagesMutation = useMutation({
    mutationFn: () => findAllLanguages(),
    onSuccess: ({ data }) => {
      setLanguages(() => data.map((d) => ({ label: d.name, value: d })));
    },
  });

  useEffect(() => {
    handleLoadLanguagesMutation.mutate();
  }, []);

  useEffect(() => {
    if (!addWord) {
      const payload = {};
      payload._id = edit.category._id;

      reload.mutate(payload);
    }
  }, [addWord]);

  return (
    <>
      <PopUp setClose={setClose}>
        <Title>{(edit?.lesson?._id && "Edit") || "Create"} Lesson</Title>
        <Title style={{ fontSize: "16px", marginTop: "-20px" }}>
          Category {edit?.category?.number}{" "}
        </Title>
        <form onSubmit={handleSave}>
          <SelectInput
            width={180}
            name="Type"
            color={Color.DarkBlue}
            selectWidth={225}
            selected={selectedType}
            setSelected={setSelectedType}
            options={getToSelectData(LESSON_TYPE)}
            required
            requiredSign
          />
          <Input
            inputRef={numberRef}
            width={180}
            type="number"
            name="Number"
            color={Color.DarkBlue}
            inputWidth={200}
            required
            requiredSign
            disabled
            value={edit?.lesson?.number || edit}
          />
          {selectedType.value === LESSON_TYPE.TASK && (
            <>
              <div style={{ maxWidth: "1000px" }}>
                <CustomTable
                  headers={[
                    "No",
                    "Lang",
                    "Placeholder",
                    "Example",
                    "Content",
                    "Actions",
                  ]}
                  data={tasks
                    ?.sort(
                      (a, b) =>
                        parseInt(a?.id.toString()) - parseInt(b?.id.toString())
                    )
                    ?.map((task) => [
                      task.id,
                      languages?.find((l) => l.value._id === task.language)
                        ?.label,
                      task.taskPlaceholder,
                      task.taskExample,
                      task.taskContent,
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ color: Color.blue }}
                          onClick={() => setAddTask(task)}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{ color: "red" }}
                          onClick={() => {
                            setTasks((prev) =>
                              [...prev.filter((p) => p.id !== task.id)].map(
                                (d, i) => ({ ...d, id: i + 1 })
                              )
                            );
                          }}
                        />
                      </div>,
                    ])}
                />
              </div>
            </>
          )}
          <ButtonWrapper>
            {selectedType.value === LESSON_TYPE.TASK && (
              <CustomAddButton
                onClick={() => setAddTask({})}
                style={{
                  width: "100px",
                  marginTop: "20px",
                }}
                text="Add Task"
              />
            )}
            <SubmitButton type="submit" style={{ width: "100px" }}>
              Save
            </SubmitButton>
          </ButtonWrapper>
        </form>
        {edit?.lesson?._id && selectedType.value !== LESSON_TYPE.TASK && (
          <>
            <CustomTable
              headers={genHeaders()}
              data={edit?.allWords
                ?.sort((a, b) => a.numberInLesson - b.numberInLesson)
                .map((word, i) => [
                  `${word.numberInLesson}.`,
                  <Link
                    to={`/admin/word/${word._id}`}
                    style={{ color: Color.DarkBlue, textDecoration: "none" }}
                  >
                    <b>
                      <em>{word.content}</em>
                    </b>{" "}
                    ({word.type})
                  </Link>,
                  ...edit?.options?.map((langoToWord) => (
                    <div style={{ display: "flex" }}>
                      {langoToWord.value
                        .find((l) => l._id === word._id)
                        ?.wordTranslation?.find(
                          (wt) =>
                            wt?.flascards?.length > 0 &&
                            wt?.toWord[0]?.language[0]?.name === langoToWord.key
                        )?.toWord[0]?.content || "--------"}
                      <div style={{ color: "orange" }}>
                        {isWordMigration(word, langoToWord)}
                      </div>
                    </div>
                  )),
                  <div style={{ display: "flex", gap: "10px" }}>
                    <CustomAddButton
                      text={<FontAwesomeIcon icon={faEye} />}
                      onClick={() =>
                        window.open(`/admin/word/${word._id}`, "_blank")
                      }
                    />
                    <CustomAddButton
                      text={<FontAwesomeIcon icon={faAngleDown} />}
                      onClick={() => handleChangeWordNumber(word, "up")}
                    />
                    <CustomAddButton
                      text={<FontAwesomeIcon icon={faAngleUp} />}
                      onClick={() => handleChangeWordNumber(word, "down")}
                    />
                    <CustomDeleteButton
                      text={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() => handleRemoveWordFromLesson(word)}
                    />
                  </div>,
                ])}
            />
            <CustomAddButton text="Add Word" onClick={() => setAddWord(edit)} />
          </>
        )}
      </PopUp>
      {addWord && <AdminAddWord edit={edit} setClose={setAddWord} />}
      {addTask && (
        <AdminAddTask
          edit={addTask}
          setClose={setAddTask}
          saveTasks={setTasks}
          tasks={tasks}
        />
      )}
    </>
  );
};

export default AdminLessonEdit;
